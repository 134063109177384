import { Component, OnInit } from '@angular/core';
import { NavService } from '../nav.service';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders, HttpRequest } from '@angular/common/http';
import { Http, RequestOptions, Headers } from '@angular/http';
import { Type } from '@angular/core';
import 'rxjs/add/operator/map';//调用映射的响应可观察到得到解析的人对象。
import { Router, NavigationEnd, ActivatedRoute, RouterState , Params, ParamMap  } from '@angular/router';
declare var $: any;
declare var jQuery: any;  //定义jquery
declare var qrcode: any;
@Component({
    selector: 'app-order',
    templateUrl: './just-order.component.html',
    styleUrls: ['./just-order.component.css']
})
export class JustOrderComponent {
    constructor(
        private http: Http,
        private NavService: NavService,
        private route: ActivatedRoute,
    ) { }
    // 初始化数据
    justId;
    // 立即购买在当前的页面进行信息确认的弹窗,并完成相关的支付工作
    classStatus = true;
    checkStaus = false;
    orderCouName = ''; //过渡页的课程的名字
    orderChap = '';  //过渡页的课程的章节
    orderTime = ''; // 过渡页课程的时长
    orderTea = '';  //过渡页课程讲师
    orderOri = '';  // 过渡页面的原价
    orderNow: any = -1;  //过渡页面的现价
    orderPic = '';  //过渡页面的封面
    orderCoup = []; //优惠券
    orderCoupId = ''; //优惠券
    orderAndun: any;  //安盾
    orderSale: any = 0;  // 优惠的金额
    saleTemp;
    orderBuy: any;  //支付的金额
    orderOwn; // 余额
    payType = 0; // 0 pc网页版支付宝 1 微信  2 余额
    andunFlag = 1; //说明未选择
    buyTempcou = 0;
    buyTempan = 0;
    orderUseAndun; //可用的安盾
    paycon = "";
    ngOnInit() {
        this.route.paramMap.subscribe((params: ParamMap) => {
            console.log(params.get("id"));
            this.justId = params.get("id");
        });
        console.log(this.justId);
        this.http.post(this.NavService.domain + '/user/getUserInfo', {
            token: window.localStorage.getItem("token"),
            jsessionid: window.localStorage.getItem("jsessionid")
          }).map(res => res.json())
            .subscribe(
              res => {
                ////console.log(res);
                if (res.code == 0) {
                  //这是已经登录的状态
                  this.http.post(this.NavService.domain + '/pay/buyCourseInit',
                  {
                    "courseId": this.justId,
                    token: window.localStorage.getItem("token"),
                    jsessionid: window.localStorage.getItem("jsessionid")
                  })
                  .map(res => res.json())
                  .subscribe(
                    res => {
                      console.log(res);
                      if (res.code == 0) {
                        this.orderCouName = res.data.course.couName; //过渡页的课程的名字
                        this.orderPic = res.data.course.picture;
                        this.orderChap = res.data.course.chapCount;  //过渡页的课程的章节
                        this.orderTime = res.data.course.course_time; // 过渡页课程的时长
                        this.orderTea = res.data.course.tname;  //过渡页课程讲师
                        this.orderOri = res.data.course.oriPrice;  // 过渡页面的原价
                        this.orderNow = res.data.course.amount + 0;  //过渡页面的现价
                        this.orderBuy = res.data.course.amount + 0;  //过渡页面的现价
                        this.orderCoup = res.data.couponList; //优惠券
                        this.orderAndun = res.data.andun.andunUse;  //安盾
                        this.orderOwn = res.data.user.account;
                      };
                    },
                    err => {
                        this.paycon = "系统繁忙,请稍后再试"
                        setTimeout(()=>{
                          this.paycon = "";
                          window.location.href = "/courses/"+this.justId;
                        },1500);
                    }
                  );
                  }else{
                    this.paycon = "请稍后"
                    setTimeout(()=>{
                      this.paycon = "";
                      window.location.href = "/courses/"+this.justId;
                    },1500);
                  }
                },
                err=>{
                    this.paycon = "请稍后"
                    setTimeout(()=>{
                      this.paycon = "";
                      window.location.href = "/courses/"+this.justId;
                    },1500);
                }
            );
    }
     // 选择支付方式
  payClick(val) {
    this.payType = val;
    console.log(this.payType)
  }
  // 去支付
  toPay() {
    this.http.post(this.NavService.domain + '/pay/tocreateOrder', {
      token: window.localStorage.getItem("token"),
      jsessionid: window.localStorage.getItem("jsessionid"),
      goodsId: this.justId,
      payType: this.payType,
      andunCount: this.orderUseAndun,
      couponRcId: this.orderCoupId
    }).map(res => res.json())
      .subscribe(
        res => {
          console.log(res)
          // return false;
          if(this.orderBuy < 0){
            this.orderBuy = 0;
          }
          if (res.code == 0) {
            // 说明生成订单成功
            if (this.orderBuy == 0) {
              // 零元购
              console.log("0元购")
              this.http.post(this.NavService.domain + '/pay/buyCourse',{
                orderNo: res.data.orderNo,
                token:window.localStorage.getItem("token"),
                jsessionid:window.localStorage.getItem("jsessionid")
              }).map(res=>res.json())
                .subscribe(
                  res=>{
                  console.log(res);
                  if(res.code == 0){
                    // return false;
                    this.paycon = res.data+"正在跳转......";
                    setTimeout(()=>{
                      this.paycon = ""
                    },2000);
                    window.location.href = this.NavService.skiplink_order;
                  }else{
                    this.paycon = "请稍后"
                    setTimeout(()=>{
                      this.paycon = ""
                    },1500);
                  }
                },
                  error=>{
                    this.paycon = "请稍后"
                    setTimeout(()=>{
                      this.paycon = ""
                    },1500);
                }
              );

            } else if (this.orderBuy > 0) {
              // 非零元购
              if (this.payType == 0) {
                //支付宝就是调支付宝的接口
                this.http.post(this.NavService.domain + '/pub/aliPay/createOrder', {
                  orderNo: res.data.orderNo,
                  payType: this.payType,
                  orderType: 1,
                  payAmount: this.orderBuy,
                  token: window.localStorage.getItem("token"),
                  jsessionid: window.localStorage.getItem("jsessionid")
                })
                  .map(res => res.json())
                  .subscribe(
                    res => {
                      //console.log(res.data);
                      document.write(res.data);
                    },
                    error => {
                      
                      this.paycon = "请稍后"
                      setTimeout(() => {
                      
                        this.paycon = ""
                      }, 1500);
                    }
                  );
              };
              //余额支付
              if (this.payType == 2) {
                if(this.orderOwn < this.orderBuy){
                  this.paycon ="余额不足,请您进行充值或者更换其他的支付方式!";
                  setTimeout(() => {
                    this.paycon = ""
                  }, 2000);
                  return false;
                }
                //2是余额支付
                this.http.post(this.NavService.domain + '/pay/balance', {
                  orderNo: res.data.orderNo,
                  payType: this.payType,
                  orderType: 1,
                  payAmount: this.orderBuy,
                  token: window.localStorage.getItem("token"),
                  jsessionid: window.localStorage.getItem("jsessionid")
                })
                  .map(res => res.json())
                  .subscribe(
                    res => {
                      //console.log(res);
                      if (res.code == 0) { 
                        this.paycon = res.data + "正在为您跳转......";
                        setTimeout(() => {
                          this.paycon = ""
                        }, 1500);
                        window.location.href = this.NavService.skiplink_order;
                        //alert( this.NavService.skiplink_order);
                        // window.location.reload();
                      } else {
                        this.paycon = res.msg;
                        setTimeout(() => {  
                          this.paycon = ""
                        }, 1500);
                      }
                    },
                    error => {
                      this.paycon = "请稍后"
                      setTimeout(() => {
                        this.paycon = ""
                      }, 1500);
                    }
                  );
              };
              //微信支付
              if (this.payType == 1) {
                this.http.post(this.NavService.domain + '/weixPay/createOrder', {
                  orderNo: res.data.orderNo,
                  payType: this.payType,
                  orderType: 1,
                  payAmount: this.orderBuy,
                  token: window.localStorage.getItem("token"),
                  jsessionid: window.localStorage.getItem("jsessionid")
                })
                  .map(res => res.json())
                  .subscribe(
                    res => {
                      //console.log(res);
                      if (res.code == 0) {
                        $(".qrcode-model").show();
                        jQuery(".qrcodepay").html("");
                        jQuery(".qrcodepay").qrcode({ width: 250, height: 250, text: res.data.codeUrl });
                      } else {
                        this.paycon = "请稍后"
                        setTimeout(() => {
                        
                          this.paycon = ""
                        }, 1500);
                      }
                    },
                    error => {
                     
                      this.paycon = "请稍后"
                      setTimeout(() => {
                      
                        this.paycon = ""
                      }, 1500);
                    }
                  );
              };
            };
          } else {
            this.paycon = "请稍后"
            setTimeout(() => {
            
              this.paycon = ""
            }, 1500);
          }

        },
        err => {
          this.paycon = "请稍后"
          setTimeout(() => {
          
            this.paycon = ""
          }, 1500);
        }
      )

  }
  closeq(){
    $(".qrcode-box").hide();
    $(".qrcode-model").hide();
    window.location.href = this.NavService.skiplink_order;
  }
  //优惠券
  cupClick(couval) {
    // console.log(couval);
    var newArr = couval.split("|")
    this.orderCoupId = newArr[1];
    this.buyTempcou = Number(newArr[0]);
    this.orderBuy = (Number(this.orderNow) - this.buyTempcou - this.buyTempan).toFixed(2);
    this.orderSale = (this.buyTempcou + this.buyTempan).toFixed(2);
    if (this.andunFlag == 1) {
      this.orderBuy = (Number(this.orderNow) - this.buyTempcou - this.buyTempan + this.buyTempan).toFixed(2);
      this.orderSale = (this.buyTempcou + this.buyTempan - this.buyTempan).toFixed(2);
    }
    // console.log(this.orderBuy);
    if(this.orderBuy < 0){
      this.orderBuy = 0;
    }
  }
  // 安盾代金
  anDclick(anval, flagNum) {
    console.log(anval, flagNum);
    if (flagNum == 1) {
      // 选中
      this.orderUseAndun = anval;
      this.buyTempan = Number(anval / 10);
      this.orderBuy = (Number(this.orderNow) - this.buyTempcou - this.buyTempan).toFixed(2);
      this.andunFlag = 2;
      this.orderSale = (this.buyTempcou + this.buyTempan).toFixed(2);
      // console.log("选中", this.buyTempcou, this.buyTempan)
      // console.log(this.orderBuy);
    } else {
      // 未选中
      this.orderUseAndun = 0;
      console.log("未选中", this.buyTempcou, this.buyTempan, this.orderNow)
      this.buyTempan = Number(anval / 10);
      this.orderBuy = (Number(this.orderNow) - this.buyTempcou - this.buyTempan + this.buyTempan).toFixed(2);
      this.orderSale = (this.buyTempcou + this.buyTempan - this.buyTempan).toFixed(2);
      this.andunFlag = 1;
      // console.log(this.orderBuy);
    };
    if(this.orderBuy < 0){
      this.orderBuy = 0;
    }
  }
}
