import { Component,OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute,RouterState ,Params,ParamMap  } from '@angular/router';

@Component({
  selector: 'app-controls',
  templateUrl: './zjean.component.html',
  styleUrls: ['./zjean.component.css']
})
export class HeroControlsComponent {
  constructor(
    private route:ActivatedRoute
  ){}
  zjid;
  onezj:Boolean = true;
  twojr:Boolean = false;
  threelx:Boolean = false;
  fourzc:Boolean = false;
  fivehd:Boolean = false;
  sixhd:Boolean = false;
  sevencw:Boolean = false;
  eighthz:Boolean = false;
  nineys:Boolean = false;
  abroad:Boolean = true;
  abroad1:Boolean = false;
  on_tabhero:Boolean = true;
  on_tabs:Boolean = false;
  ngOnInit(){
    this.route.paramMap.subscribe((params: ParamMap) => {
      console.log(params);
      this.zjid = Number(params.get("id"));
      console.log(this.zjid);
      switch(this.zjid){
        case 1:
          this.onezj = true;
          this.twojr = false;
          this.threelx = false;
          this.fourzc = false;
          this.fivehd = false;
          this.sixhd = false;
          this.sevencw = false;
          this.eighthz = false;
          this.nineys = false;
          break;
        case 2:
          this.onezj = false;
          this.twojr = true;
          this.threelx = false;
          this.fourzc = false;
          this.fivehd = false;
          this.sixhd = false;
          this.sevencw = false;
          this.eighthz = false;
          this.nineys = false;
          break;
        case 3:
          this.onezj = false;
          this.twojr = false;
          this.threelx = true;
          this.fourzc = false;
          this.fivehd = false;
          this.sixhd = false;
          this.sevencw = false;
          this.eighthz = false;
          this.nineys = false;
          break;
        case 4:
          this.onezj = false;
          this.twojr = false;
          this.threelx = false;
          this.fourzc = true;
          this.fivehd = false;
          this.sixhd = false;
          this.sevencw = false;
          this.eighthz = false;
          this.nineys = false;
          break;
        case 5:
          this.onezj = false;
          this.twojr = false;
          this.threelx = false;
          this.fourzc = false;
          this.fivehd = true;
          this.sixhd = false;
          this.sevencw = false;
          this.eighthz = false;
          this.nineys = false;
          break;
        case 6:
          this.onezj = false;
          this.twojr = false;
          this.threelx = false;
          this.fourzc = false;
          this.fivehd = false;
          this.sixhd = true;
          this.sevencw = false;
          this.eighthz = false;
          this.nineys = false;
          break;
        case 9:
          this.onezj = false;
          this.twojr = false;
          this.threelx = false;
          this.fourzc = false;
          this.fivehd = false;
          this.sixhd = false;
          this.sevencw = false;
          this.eighthz = false;
          this.nineys = true;
          break;
      }
    });
  }
  one() : void {
    this.onezj = true;
    this.twojr = false;
    this.threelx = false;
    this.fourzc = false;
    this.fivehd = false;
    this.sixhd = false;
    this.sevencw = false;
    this.eighthz = false;
    this.nineys = false;
  }
  two() : void {
    this.onezj = false;
    this.twojr = true;
    this.threelx = false;
    this.fourzc = false;
    this.fivehd = false;
    this.sixhd = false;
    this.sevencw = false;
    this.eighthz = false;
    this.nineys = false;
  }
  three() : void {
    this.onezj = false;
    this.twojr = false;
    this.threelx = true;
    this.fourzc = false;
    this.fivehd = false;
    this.sixhd = false;
    this.sevencw = false;
    this.eighthz = false;
    this.nineys = false;
  }
  four() : void {
    this.onezj = false;
    this.twojr = false;
    this.threelx = false;
    this.fourzc = true;
    this.fivehd = false;
    this.sixhd = false;
    this.sevencw = false;
    this.eighthz = false;
    this.nineys = false;
  }
  five() : void {
    this.onezj = false;
    this.twojr = false;
    this.threelx = false;
    this.fourzc = false;
    this.fivehd = true;
    this.sixhd = false;
    this.sevencw = false;
    this.eighthz = false;
    this.nineys = false;
  }
  six() : void {
    this.onezj = false;
    this.twojr = false;
    this.threelx = false;
    this.fourzc = false;
    this.fivehd = false;
    this.sixhd = true;
    this.sevencw = false;
    this.eighthz = false;
    this.nineys = false;
  }
  seven() : void {
    this.onezj = false;
    this.twojr = false;
    this.threelx = false;
    this.fourzc = false;
    this.fivehd = false;
    this.sixhd = false;
    this.sevencw = true;
    this.eighthz = false;
    this.nineys = false;
  }
  eight() : void {
    this.onezj = false;
    this.twojr = false;
    this.threelx = false;
    this.fourzc = false;
    this.fivehd = false;
    this.sixhd = false;
    this.sevencw = false;
    this.eighthz = true;
    this.nineys = false;
  }
  nine() :void {
    this.onezj = false;
    this.twojr = false;
    this.threelx = false;
    this.fourzc = false;
    this.fivehd = false;
    this.sixhd = false;
    this.sevencw = false;
    this.eighthz = false;
    this.nineys = true;
  }
  on_left() : void{
    this.abroad = true;
    this.abroad1 = false;
    this.on_tabhero = true;
    this.on_tabs = false;
  }
  on_right() : void{
    this.abroad = false;
    this.abroad1 = true;
    this.on_tabhero = false;
    this.on_tabs = true;
  }
}
