import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { NavService } from '../../nav.service';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Http } from '@angular/http';
import 'rxjs/add/operator/map';//调用映射的响应可观察到得到解析的人对象。

declare var jQuery: any;  //定义jquery
declare var $: any;  //定义jquery
@Component({
  selector: 'app-remoindex',
  templateUrl: './remoindex.component.html',
  styleUrls: ['./remoindex.component.css']
})
export class RemoindexComponent implements OnInit {

  constructor(
    private router: Router,
    private http: Http,
    private NavService: NavService
  ) { }
  title = "我的远程教室";
  firm_name = "益安在线"; //用户name
  firm_id = "益安在线"; //用户身份
  firm_code = "00000000";  //中心code
  firm_room = "益安在线";  //教室
  firm_add = "益安在线"; //地址

  ngOnInit() {
    //  this.router.navigate(['./remocenter/remohave']);  //设置父子路由中的默认子路由显示
    //console.log(this.router.url);
    // 判断用户是否已经登录
    this.http.post(this.NavService.domain_xl + '/user/getUserInfo', {
      token: localStorage.getItem("token"),
      jsessionid: localStorage.getItem("jsessionid")
    })
      .map(res => res.json())
      .subscribe(
        res => {
          //console.log(res)
          if (res.code == 0) {
            // 说明已经登陆了
            // 数据初始化 获取远程管理用户的信息
            this.http.post(this.NavService.domain + '/rc/rcusers/selectOneUser', {
              token: localStorage.getItem("token"),
              jsessionid: localStorage.getItem("jsessionid")
            }).map(res => (res.json())).subscribe(
              res => {
                //console.log(res)
                if (res.code == 200) {
                  if (res.data.userRole == 0) {
                    // 普通用户 返回普通用户的个人中心
                    location.href = "../personal-center/mcourse";
                  } else if (res.data.userRole == 1) {
                    // 企业用户
                    this.firm_name = res.data.rcUserMobile; //用户name
                    this.firm_id = "远程教室用户"; //用户身份
                    this.firm_code = res.data.rcMacCode;  //中心code
                    this.firm_room = res.data.rcEnterPriseName;  //教室
                    this.firm_add = res.data.rcEnterPriseAddr; //地址
                  } else {
                    // 企业用户管理员
                    this.firm_name = res.data.rcUserMobile; //用户name
                    this.firm_id = "远程教室负责人"; //用户身份
                    this.firm_code = res.data.rcMacCode;  //中心code
                    this.firm_room = res.data.rcEnterPriseName;  //教室
                    this.firm_add = res.data.rcEnterPriseAddr; //地址
                  }
                } else {
                  location.href = "../personal-center/mcourse";
                }
              },
              err => {
                //console.log(err);
                location.href = "../personal-center/mcourse";
              }
            );
          } else {
            // 未登录 返回普通的用户中心
            location.href = "../personal-center/mcourse";
          }
        },
        err => {
          //console.log(err)
          location.href = "../personal-center/mcourse";
        }
      )

  }
}