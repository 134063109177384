import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NavService } from "../../nav.service";
import { HttpClient } from "@angular/common/http";
import { HttpHeaders, HttpRequest } from "@angular/common/http";
import { Http, RequestOptions, Headers } from "@angular/http";
import "rxjs/add/operator/map"; //调用映射的响应可观察到得到解析的人对象。
declare var jQuery: any; //定义jquery
declare var $: any; //定义jquery
@Component({
  selector: "app-remoindexcenter",
  templateUrl: "./remoindexcenter.component.html",
  styleUrls: ["./remoindexcenter.component.css"]
})
export class RemoindexcenterComponent implements OnInit {
  constructor(
    private router: Router,
    private http: Http,
    private NavService: NavService
  ) {}
 
  ngOnInit() {
  }
}
