import { BrowserModule } from "@angular/platform-browser";

import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { RemoteRoutingModule } from "./remote-routing.module";

// import { CommonModule } from '@angular/common';
import { FormsModule } from "@angular/forms";

import { RemoaboutComponent } from "../remoabout/remoabout.component";
import { RemoindexcenterComponent } from "../remoindexcenter/remoindexcenter.component";
import { RemoteenterComponent } from "../remoteenter/remoteenter.component";
// import { }
import { RemoteapplyComponent } from "../remoteapply/remoteapply.component";
import { RemotedscheduleComponent } from "../remotedschedule/remotedschedule.component";
import { RemoteindexComponent } from "../remotecenter/remoteindex.component";
import { RemotedetailComponent } from "../remotedetail/remotedetail.component";
import { ClassdetailComponent } from "../classdetail/classdetail.component";
import { RemotesdetailComponent } from "../remotesdetail/remotedetail.component";
import { remPipe } from "../remotesdetail/remote.pipie";
import { listPipe } from "../remotedetail/detail.pipie";
@NgModule({
  declarations: [
    // RemoteComponent,
    RemoindexcenterComponent,
    RemoteenterComponent,
    RemoteapplyComponent,
    RemotedscheduleComponent,
    RemotedetailComponent,
    ClassdetailComponent,
    RemotesdetailComponent,
    RemoaboutComponent,
    remPipe,
    RemoteindexComponent,
    listPipe,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    FormsModule,
    // CommonModule,
    RemoteRoutingModule,
  ],
  providers: [],
  bootstrap: [],
})
export class RemoteModule {}
