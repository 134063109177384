import { Component, OnInit } from '@angular/core';
import {  Router } from '@angular/router';
declare var jQuery:any;  //定义jquery
declare var $:any;  //定义jquery
@Component({
  selector: 'app-reindex',
  templateUrl: './remoindex.component.html',
  styleUrls: ['./remoindex.component.css']
})
export class ReindexComponent implements OnInit {

  constructor(private router : Router) { }

  ngOnInit() {
    //console.log(this.router.url);
    //console.log( $(".model-box"))
    $(".model-box").niceScroll({cursorborder:"",cursorcolor:"#ccc",cursoropacitymin:1});
  }
}