import {
  Component,
  Directive,
  ViewChild,
  AfterViewInit,
  ElementRef,
  Renderer,
} from "@angular/core";
//import { GuideComponent } from './guide/guide.component';
import { NavService } from "./nav.service";
import { loadingComponent } from "./load.component";
import {
  Router,
  NavigationEnd,
  ActivatedRoute,
  RouterState,
  Params,
  ParamMap,
} from "@angular/router";
import { Title } from "@angular/platform-browser";
import { HttpClient } from "@angular/common/http";
import { HttpHeaders } from "@angular/common/http";
import { Http, RequestOptions, Headers } from "@angular/http";
//import { loadComponent } from './load.component';
import "rxjs/add/operator/map"; //调用映射的响应可观察到得到解析的人对象。
import { Data } from "./personal-center/personal-info/shuju";
//import { Response } from '@angular/http';
//@Directive({selector: 'report'})
//export  class ReportDirective {
//  constructor(el: ElementRef, renderer: Renderer){
//
//  }
//ngAfterViewInit(){
//  console.log(el.nativeElement);
//  renderer.setElementStyle(el.nativeElement, 'backgroundColor', 'red');
//}
//}
declare var jQuery: any;
declare var $: any;
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./common.component.css", "./app.component.css"],
  providers: [NavService, loadingComponent],
})
export class AppComponent implements AfterViewInit {
  //@ViewChild(ReportDirective) child: ReportDirective;
  //操作DOM元素
  @ViewChild("report")
  greetDiv: ElementRef;
  constructor(
    private http: Http,
    private NavService: NavService,
    private route: ActivatedRoute,
    private renderer: Renderer,
    private loadingComponent: loadingComponent // route.data includes both `data` and `resolve`
  ) {
    NavService.missionConfirmed$.subscribe((res) => {});
  }
  ngAfterViewInit() {
    var l = 1;
    //renderer.setElementStyle("background","red")
    //console.log(this.greetDiv.nativeElement);
    var _this = this;
    this.scrollTimer = setInterval(function () {
      //console.log(this);
      //console.log(_this);
      l = l + 1;
      _this.renderer.setElementStyle(
        _this.greetDiv.nativeElement,
        "left",
        -l + "px"
      );
      if (l >= 400) {
        l = 1;
      }
    }, 100);

    //this.renderer.animate(this.greetDiv.nativeElement, startingStyles: any, keyframes: any[],0, 0, "ease-in", previousPlayers?: true);
  }
  //路由参数
  searchval;
  routeFlag = false;
  userName; //用户名
  usersid; //用户id
  pass; //密码
  isremember; //是否记住密码
  //判断是否存在token的状态码
  personFlag; //控制个人中心的显示
  carted; //购物车的显示
  newsed; //控制消息
  unlogin; //未登录时显示登录入口
  signFlag; //未登录时显示注册的按钮
  title = "app";
  str: string[];
  isShow: boolean;
  is: boolean;
  isNav: boolean;
  isNavOther;
  num: number;
  isHome: boolean;
  //key:string[];
  MenuData: Object;
  NavItem: Object;
  isFind; //显示找回密码的登录窗
  //定时器
  scrollTimer;
  moveleft: number;
  //是否已经登陆了
  loadend;
  //登录的账号
  //登录的密码
  utele;
  upass;
  reData;
  //活动弹窗
  actstatus;
  // 补充数据
  topbannerimg;
  topbannerurl;
  tishiyu = "欢迎光临本网站"; // 提示语
  // 11.21 柴
  flagStatus = false; //开闭的状态
  // 12.10 柴
  // 增加弹窗模板化功能
  oneStatus = false; // 单点模式
  moreStatus = false; // 多点模式
  moresrc =
    "https://51ean2c.oss-cn-beijing.aliyuncs.com/APP%E5%9B%BE%E7%89%87/banner/2018.3.5/%E7%A4%BC%E5%8C%85.png";
  liStatusone = false;
  liStatustwo = false;
  liStatusthr = false;
  liStatusfou = false;
  liStatusfiv = false;
  liStatussix = false;
  liStatussev = false;
  liStatuseig = false;
  liStatusnin = false;
  liStatusten = false;
  liStatusele = false;
  onehref = "";
  liarr = [];
  newliArr = [
    {
      btn_img_url: "",
      btn_no: "",
      relation_content: "",
      relation_type: 1,
    },
    {
      btn_img_url: "",
      btn_no: "",
      relation_content: "",
      relation_type: 1,
    },
    {
      btn_img_url: "",
      btn_no: "",
      relation_content: "",
      relation_type: 1,
    },
    {
      btn_img_url: "",
      btn_no: "",
      relation_content: "",
      relation_type: 1,
    },
    {
      btn_img_url: "",
      btn_no: "",
      relation_content: "",
      relation_type: 1,
    },
    {
      btn_img_url: "",
      btn_no: "",
      relation_content: "",
      relation_type: 1,
    },
    {
      btn_img_url: "",
      btn_no: "",
      relation_content: "",
      relation_type: 1,
    },
    {
      btn_img_url: "",
      btn_no: "",
      relation_content: "",
      relation_type: 1,
    },
    {
      btn_img_url: "",
      btn_no: "",
      relation_content: "",
      relation_type: 1,
    },
    {
      btn_img_url: "",
      btn_no: "",
      relation_content: "",
      relation_type: 1,
    },
    {
      btn_img_url: "",
      btn_no: "",
      relation_content: "",
      relation_type: 1,
    },
    {
      btn_img_url: "",
      btn_no: "",
      relation_content: "",
      relation_type: 1,
    },
  ];
  windowid;
  anistatus = false;
  // 新增在线比赛入口  要求:当用户在官网登陆了之后才显示此入口,并且携带jid以及token值,以便确认身份
  examStatus = false; //控制在线比赛的入口
  examUrl; //绑定url所用的数据
  encrypted;
  names;
  nsmr_encrypted;
  surveylist;
  // 控制顶部通栏广告
  bannerTop = false;
  //判断token
  ngOnInit() {
    $("title").html("e安在线官网-国内专业网络安全IT人才培训平台_专注于网络安全人才培养_益安在线");
        $("meta[name='description']").attr(
          "content",
          "e安在线-专注于网络安全人才培养，提供1000+免费网络安全培训课程，涵盖云计算,等级保护,web开发和工业互联网安全等课程，培养网络安全领域高端IT人才。"
        );
        $("meta[name='keywords']").attr(
          "content",
          "e安在线,e安在线官网,云计算,信息系统审计师,等级保护,web开发,大数据培训,软件开发,工业互联网安全,北京益安在线"
        );
    this.footer();
    this.actstatus = false;
    this.loadingComponent.login(true);
    if (window.localStorage.getItem("token") == null) {
      this.routeFlag = false;
    } else {
      this.routeFlag = true;
      this.NavService.loadflagchange();
      this.personFlag = this.NavService.personFlag; //控制个人中心的显示
      this.carted = this.NavService.carted; //购物车的显示
      this.newsed = this.NavService.newsed;
      this.unlogin = this.NavService.unlogin; //未登录时显示登录入口
      this.signFlag = this.NavService.signFlag; //未登录时显示注册的按钮
      this.is = this.NavService.is; //隐藏登录窗口
    }
    //this.NavService.isshow();
    this.utele = window.localStorage.getItem("usetele");
    //console.log(this.utele);
    this.upass = window.localStorage.getItem("usepass");
    ////每次进来要清空token
    //window.localStorage.setItem("token","");
    //console.log(localStorage.token);
    //console.log(this.NavService.personFlag);
    this.personFlag = this.NavService.personFlag; //控制个人中心的显示
    this.carted = this.NavService.carted; //购物车的显示
    this.newsed = this.NavService.newsed;
    this.unlogin = this.NavService.unlogin; //未登录时显示登录入口
    this.signFlag = this.NavService.signFlag; //未登录时显示注册的按钮
    this.is = this.NavService.is; // 控制登录的窗口
    this.isFind = this.NavService.isFind; //控制找回密码的窗口
    this.http
      .post(this.NavService.domain + "/pub/news/findNewsNotice", {})
      .map((res) => res.json())
      .subscribe((res) => {
        this.reData = res.data.content;
      });
    let header = new Headers({ "Content-Type": "application/json" }); //null,其实不表明 json 也可以, ng 默认好像是 json
    let options = new RequestOptions({
      headers: header,
      withCredentials: true,
    });
    // 获取调查问卷列表   暂未启用
    // this.http.post('/portal/questionnaire/takeListJsonData',{
    //   "pageNum":"1",
    //   "pageSize":"4",
    //   "order":""
    //   },options)
    // .map(res=>res.json())
    // .subscribe(res=>{
    //   console.log(res);
    // });
    //获取左侧菜单导航
    // this.http.post(this.NavService.domain+'/pub/portalMenu/getAllMenu',{})
    //   .map(res=>res.json())
    //   .subscribe(res=>{
    //   this.MenuData = res.data.parentData;
    //    // console.log(this.MenuData);
    //   });
    this.isNav = true; //首页显示导航一
    this.isNavOther = false; //在首页时不显示导航二
    this.isHome = false;
    //console.log(window.location.href);
    if (window.location.href.slice(-1) != "/") {
      this.isNav = false; //不在首页时不显示
      this.isNavOther = true; //不在首页时显示导航二
      this.isHome = true; //控制默认按钮"首页"的样式
      this.actstatus = false;
    } else {
      //获取弹出窗
      this.http
        .get(this.NavService.domain + "/popupWindow/selectOne")
        .map((res) => res.json())
        .subscribe((res) => {
          // console.log(res);
          var s = JSON.stringify(res.data);
          if (res.code == 0) {
            if (s != "{}") {
              setTimeout(() => {
                this.actstatus = true;
              }, 10000);
              this.windowid = res.data.window.id;
              // 查询成功
              if (res.data.window.templet == 0) {
                // 单点
                this.moreStatus = false;
                this.oneStatus = true;
                this.onehref = res.data.window.relation_content;
                this.moresrc = res.data.window.background_img_url;
              } else {
                // 多点
                this.moreStatus = true;
                this.oneStatus = false;
                this.moresrc = res.data.window.background_img_url;
                this.liarr = res.data.windowBtnList;
                for (var i = 0; i < this.liarr.length; i++) {
                  if (this.liarr[i].btn_no > 3) {
                    this.liarr[i].btn_no = this.liarr[i].btn_no + 1;
                    // console.log( this.liarr[i].btn_no)
                    for (var j = 0; j < this.newliArr.length; j++) {
                      if (this.liarr[i].btn_no == j + 1) {
                        this.newliArr[j].btn_img_url = this.liarr[
                          i
                        ].btn_img_url;
                        this.newliArr[j].btn_no = this.liarr[i].btn_no;
                        this.newliArr[j].relation_content = this.liarr[
                          i
                        ].relation_content;
                        this.newliArr[j].relation_type = this.liarr[
                          i
                        ].relation_type;
                      }
                    }
                  } else {
                    for (var j = 0; j < this.newliArr.length; j++) {
                      if (this.liarr[i].btn_no == j + 1) {
                        this.newliArr[j].btn_img_url = this.liarr[
                          i
                        ].btn_img_url;
                        this.newliArr[j].btn_no = this.liarr[i].btn_no;
                        this.newliArr[j].relation_content = this.liarr[
                          i
                        ].relation_content;
                        this.newliArr[j].relation_type = this.liarr[
                          i
                        ].relation_type;
                      }
                    }
                  }
                }
                // console.log(this.newliArr);
                // console.log(!!this.newliArr[0].btn_img_url);
              }
            }
          }
          // console.log(this.MenuData);
        });
    }
    window.onload = () => {
      $(".keyinput").keyup((event) => {
        var value = $(".keyinput").val();
        value = value.replace(/\?+/g, "");
        value = value.replace(/？+/g, "");
        value = value.replace(/#+/g, "");
        value = value.replace(/\/+/g, "");
        if (event.keyCode == 13) {
          if (!value) {
            value = "网络安全";
            window.location.href =
              this.NavService.skip_center + "/courses/search/" + value;
          } else {
            window.location.href =
              this.NavService.skip_center + "/courses/search/" + value;
          }
        }
      });
    };
    //获取首页广告图
    this.http
      .post(this.NavService.domain + "/bannerad/findIndexBanner", {})
      .map((res) => res.json())
      .subscribe(
        (res) => {
          if (res.code == 0) {
            // 说明是成功的数据内容
            this.topbannerimg = res.data.oneTopBanner[0].banner_url;
            this.topbannerurl = res.data.oneTopBanner[0].link;
            this.bannerTop = res.data.oneTopBanner[0].isShow;
            // console.log(this.bannerTop)
          } else {
            this.bannerTop = false;
            this.topbannerimg = "";
            this.topbannerurl = "";
          }
        },
        (err) => {
          this.bannerTop = false;
          this.topbannerimg = "";
          this.topbannerurl = "";
        }
      );
    if (!window.localStorage.getItem("token")) {
      this.examStatus = false;
    } else {
      //验证token是否失效
      //console.log(!window.localStorage.getItem("token"));
      this.http
        .post(this.NavService.domain + "/userCenter/getUserById", {
          token: window.localStorage.getItem("token"),
          jsessionid: window.localStorage.getItem("jsessionid"),
        })
        .map((res) => res.json())
        .subscribe(
          (res) => {
            //console.log(res+"检验token是否失效");
            if (res.code == 0) {
              this.examStatus = true; //说明已经登录
              var key = CryptoJS.enc.Utf8.parse("377902d57d26b67c");
              var srcs = CryptoJS.enc.Utf8.parse(res.data.username);
              this.encrypted = CryptoJS.AES.encrypt(srcs, key, {
                mode: CryptoJS.mode.ECB,
                padding: CryptoJS.pad.Pkcs7,
              });
              this.encrypted = this.encrypted.toString();
              this.encrypted = this.encrypted.replace(/\+/g, "%2B");
              this.encrypted = this.encrypted.replace(/\&/g, "%26");
              this.examUrl = this.NavService.examUrl + this.encrypted;
              this.names = res.data.name;
              console.log(this.names);
              var namekeys = CryptoJS.enc.Utf8.parse("377902d57d26b67c");
              var namesrcses = CryptoJS.enc.Utf8.parse(this.names);
              this.nsmr_encrypted = CryptoJS.AES.encrypt(namesrcses, namekeys, {
                mode: CryptoJS.mode.ECB,
                padding: CryptoJS.pad.Pkcs7,
              });
              this.nsmr_encrypted = this.nsmr_encrypted.toString();
              this.nsmr_encrypted = this.nsmr_encrypted.replace(/\+/g, "%2B");
              this.nsmr_encrypted = this.nsmr_encrypted.replace(/\&/g, "%26");
              // 调查问卷列表入口
              this.surveylist =
                this.NavService.surveyUrl +
                this.encrypted +
                "&drag=" +
                this.nsmr_encrypted;
              // 调取调查问卷列表数据,对数据进行重新整理,插入跳转的地址 调查问卷详情
              //  this.NavService.surveyDetail + "单个问卷id"+'&id='+this.encrypted +'&drag=' + this.nsmr_encrypted;
            } else {
              this.examStatus = false;
            }
          },
          (error) => {
            this.examStatus = false;
          }
        );
    }
  }
  // 活动弹窗领取优惠券 作废
  getpage() {
    this.tishiyu = "";
    $(".remewords").removeClass("ob");
    //  this.http.post(this.NavService.domain +'/user/getUserInfo',{
    this.http
      .post(this.NavService.domain + "/activityCoupon/getTheCoupon", {
        token: window.localStorage.getItem("token"),
        jsessionid: window.localStorage.getItem("jsessionid"),
      })
      .map((res) => res.json())
      .subscribe(
        (res) => {
          /*  res.code == 1  用户未登录
                  res.code == 0  说明用户已经登录    并且能领取了  status =  1 代表
               */
          if (res.code == 1) {
            //说明未登录
            this.tishiyu = res.data.showErrMsg;
            $(".remewords").addClass("ob");
          } else {
            //说明已经登录  并且可以领取了
            if (res.data.status == 1) {
              this.tishiyu = res.data.info;
              $(".remewords").addClass("ob");
            } else if (res.data.status == 2) {
              this.tishiyu = res.data.info;
              $(".remewords").addClass("ob");
            } else if (res.data.status == 3) {
              this.tishiyu = res.data.info;
              $(".remewords").addClass("ob");
            } else if (res.data.status == 5) {
              this.tishiyu = res.data.info;
              $(".remewords").addClass("ob");
            } else if (res.data.status == 6) {
              this.tishiyu = res.data.info;
              $(".remewords").addClass("ob");
            }
          }
        },
        (err) => {
          // 提示登录
          this.tishiyu = "系统繁忙,请稍后再试!";
          $(".remewords").addClass("ob");
        }
      );
  }
  // 新增弹出优惠券
  newgetPage(val, jug) {
    // console.log(val)
    if (val > 3) {
      val = val - 1;
    }
    if (jug == 2) {
      this.tishiyu = "";
      $(".remewords").removeClass("ob");
      //  this.http.post(this.NavService.domain +'/user/getUserInfo',{
      this.http
        .post(this.NavService.domain + "/popupWindow/windowCoupon", {
          token: window.localStorage.getItem("token"),
          jsessionid: window.localStorage.getItem("jsessionid"),
          btnNo: val,
          windowId: this.windowid,
        })
        .map((res) => res.json())
        .subscribe(
          (res) => {
            /*  res.code == 1  用户未登录
                  res.code == 0  说明用户已经登录    并且能领取了  status =  1 代表
               */
            if (res.code == 1) {
              //说明未登录
              this.anistatus = true;
              this.tishiyu = res.data.showErrMsg;
              $(".remewords").addClass("ob");
            } else {
              //说明已经登录  并且可以领取了
              if (res.data.status == 1) {
                this.anistatus = true;
                this.tishiyu = res.data.info;
                $(".remewords").addClass("ob");
              } else if (res.data.status == 2) {
                this.anistatus = true;
                this.tishiyu = res.data.info;
                $(".remewords").addClass("ob");
              } else if (res.data.status == 3) {
                this.anistatus = true;
                this.tishiyu = res.data.info;
                $(".remewords").addClass("ob");
              } else if (res.data.status == 5) {
                this.anistatus = true;
                this.tishiyu = res.data.info;
                $(".remewords").addClass("ob");
              } else if (res.data.status == 6) {
                this.anistatus = true;
                this.tishiyu = res.data.info;
                $(".remewords").addClass("ob");
              }
            }
          },
          (err) => {
            // 提示登录
            this.tishiyu = "系统繁忙,请稍后再试!";
            $(".remewords").addClass("ob");
          }
        );
    }
  }
  //当鼠标进入时清除定时器
  scroll_stop() {
    clearInterval(this.scrollTimer);
    //console.log("end");
  }
  //滚动的公告栏
  scroll_start() {
    var l = 1;
    //renderer.setElementStyle("background","red")
    //console.log(this.greetDiv.nativeElement);
    var _this = this;
    this.scrollTimer = setInterval(function () {
      l = l + 1;
      _this.renderer.setElementStyle(
        _this.greetDiv.nativeElement,
        "left",
        -l + "px"
      );
      if (l >= 400) {
        l = 1;
      }
    }, 50);
  }
  // dataHover(value:number){
  //   for(let key in this.MenuData){
  //     if(value == this.MenuData[key].id){
  //       //console.log(this.MenuData[i].sonData);
  //       this.NavItem = this.MenuData[key].sonData;
  //     }
  //   }
  // }
  loading() {
    //console.log(document.getElementsByClassName("index-wrap"));
  }
  //首页导航显示二维码,鼠标进入时显示,延迟四秒钟自己消失
  mouseenter() {
    //console.log("显示二维码");
    this.isShow = true;
  }
  mouseleave() {
    this.isShow = false;
  }
  //搜索课程
  seachval(event) {
    $(event.target).val("");
    $(".search-input a").hide();
  }
  searchblur(event) {
    var tempstr = $(event.target).val();
    if (!tempstr) {
      $(".search-input a").show();
    }
  }
  searchVal(value: string) {
    value = value.replace(/\?+/g, "");
    value = value.replace(/？+/g, "");
    value = value.replace(/#+/g, "");
    value = value.replace(/\/+/g, "");
    console.log(value);
    if (!value) {
      value = "网络安全";
      window.location.href =
        this.NavService.skip_center + "/courses/search/" + value;
    } else {
      window.location.href =
        this.NavService.skip_center + "/courses/search/" + value;
    }
  }
  //活动弹窗
  actclick() {
    this.actstatus = false;
  }
  footer() {
    var n = 0,
      m = 0;
    $(".slide-left").click(function () {
      if (m == 0) {
        //说明没有点击右侧的按钮
        n++;
        $(".top-one ul").css({
          left: "-" + 193 * n + "px",
        });
        if (n >= 4) {
          n = 4;
          $(".top-one ul").css({
            left: "-" + 193 * n + "px",
          });
        }
      } else {
        m--;
        $(".top-one ul").css({
          left: "-" + 193 * m + "px",
        });
        if (m <= 0) {
          m = 0;
          $(".top-one ul").css({
            left: "0px",
          });
        }
      }
    });
    $(".slide-right").click(function () {
      if (n == 0) {
        //说明没有点击左侧的按钮
        m++;
        $(".top-one ul").css({
          left: "-" + 193 * m + "px",
        });
        if (m >= 4) {
          m = 4;
          $(".top-one ul").css({
            left: "-" + 193 * m + "px",
          });
        }
      } else {
        n--;
        $(".top-one ul").css({
          left: "-" + 193 * n + "px",
        });
        if (n <= 0) {
          n = 0;
          $(".top-one ul").css({
            left: "0px",
          });
        }
      }
    });
  }
}
