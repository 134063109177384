import { Component,OnInit } from '@angular/core';

@Component({
    template: `
    <section class="center">
    <article>
        <h1 class="header">404</h1>
        <p>页面出错，请联系管理员！</p> 
    </article>
    <article>
        <ul>
            <li><a href="https://www.51ean.com">首页</a></li>
            <li>将在 <span id="mes">{{i}}</span> 秒钟后返回首页！</li>
        </ul>
    </article>
</section>
    `,
    styles: [`
    body
{
    background-color: #0A7189;
    color: #fff;
    font: 100% "Lato" , sans-serif;
    font-size: 1.8rem;
    font-weight: 300;
}

a
{
    color: #75C6D9;
    text-decoration: none;
}

h3
{
    margin-bottom: 1%;
}

ul
{
    list-style: none;
    margin: 0;
    padding: 0;
    line-height: 50px;
}
li{
    display: inline-block;
}

li a:hover
{
    color: #fff;
}

.center
{
    text-align: center;
    position:fixed;
    width:100%;height:100%;
    background:#0A7189;
    left:0;top:0;
    z-index:822222222222222;
     color:#fff;
}

/* Search Bar Styling */
form > *
{
    vertical-align: middle;
}

.srchBtn
{
    border: 0;
    border-radius: 7px;
    padding: 0 17px;
    background: #e74c3c;
    width: 99px;
    border-bottom: 5px solid #c0392b;
    color: #fff;
    height: 65px;
    font-size: 1.5rem;
    cursor: pointer;
}

.srchBtn:active
{
    border-bottom: 0px solid #c0392b;
}

.srchFld
{
    border: 0;
    border-radius: 7px;
    padding: 0 17px;
    max-width: 404px;
    width: 40%;
    border-bottom: 5px solid #bdc3c7;
    height: 60px;
    color: #7f8c8d;
    font-size: 19px;
}

.srchFld:focus
{
    outline-color: rgba(255, 255, 255, 0);
}

/* 404 Styling */
.header
{
    font-size: 7rem;
    font-weight: 700;
    margin: 2% 0 2% 0;
    text-shadow: 0px 3px 0px #7f8c8d;
     color:#fff;
}

/* Error Styling */
.error
{
    margin: -70px 0 2% 0;
    font-size: 4rem;
    text-shadow: 0px 3px 0px #7f8c8d;
    font-weight: 100;
    color: #fff;
}`]
})

export class  WrongComponent{
    i=5;
    ngOnInit(): void {
        //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
        //Add 'implements OnInit' to the class.
    this.fun()
    }
    fun(){
        var intervalid; 
        intervalid = setInterval(()=>{
             if (this.i<=1) { 
                 this.i = 0
            window.location.href = "https://www.51ean.com"; 
            clearInterval(intervalid); 
             } 
             this.i = this.i-1;   
        }, 1000);     
    }
    
}