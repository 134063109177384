import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { NavService } from "../../nav.service";
import { HttpClient } from "@angular/common/http";
import { HttpHeaders } from "@angular/common/http";
import { Http } from "@angular/http";
import "rxjs/add/operator/map"; //调用映射的响应可观察到得到解析的人对象。
declare var jQuery: any; //定义jquery
declare var $: any; //定义jquery
// declare var niceScroll:any;

@Component({
  selector: "app-remoshedule",
  templateUrl: "./remoschedule.component.html",
  styleUrls: ["./remoshedule.component.css"],
})
export class RemosheduleComponent implements OnInit {
  constructor(
    private router: Router,
    private http: Http,
    private NavService: NavService
  ) {}
  // 课程包分栏
  bgData = [];
  title = "";
  cbstatus = "";
  cbtabStatus = "";
  // 错误提示弹窗数据
  useWrongtatus = false;
  resmsg = "";
  // 分类tab栏
  listStatus = -1; //-1: 全部, 0:未排课, 1:已排课, 2:已上课
  // 细节课程表展示
  sheStatus = false;
  sheStatusd = true;
  sheStatusres = false;
  sheStatuspas = false;
  // 单选数据
  sheData = "";
  //远程教室课程列表展示
  couList = [];
  // 权限问题
  operStatus = false;
  operNoStatus = true;
  //排课时间冲突以及不足24小时弹窗
  Insufficient = false; //不足24小时,排课时间冲突
  classColor = false; //改变背景色
  ngOnInit() {
    /**
     * 第一步要进行角色权限的判断
     * 分为 远程教室用户   远程教室负责人
     *
     */
    // 数据初始化 获取远程管理用户的信息
    this.http
      .post(this.NavService.domain + "/rc/rcusers/selectOneUser", {
        token: localStorage.getItem("token"),
        jsessionid: localStorage.getItem("jsessionid"),
      })
      .map((res) => res.json())
      .subscribe(
        (res) => {
          // console.log(res);
          if (res.code == 200) {
            if (res.data.userRole == 2) {
              // 远程教室负责人
              this.operStatus = true;
              this.operNoStatus = false;
            }
          } else {
            this.useWrongtatus = true;
            this.resmsg = "用户身份确认有误,请确认后再试";
            setTimeout(() => {
              this.useWrongtatus = false;
              location.href = "../personal-center";
            }, 3000);
          }
        },
        (err) => {
          //console.log(err);
          this.useWrongtatus = true;
          this.resmsg = "用户身份确认有误,请稍后再试";
          setTimeout(() => {
            this.useWrongtatus = false;
            location.href = "../personal-center/mcourse";
          }, 3000);
        }
      );
    // //console.log(niceScroll);{background: "#eee",cursorborder:"",cursorcolor:"#ccc",cursoropacitymin:1}
    //  首先获取课程包
    this.http
      .post(this.NavService.domain + "/rc/userCenter/getBoughtCourse", {
        token: localStorage.getItem("token"),
        jsessionid: localStorage.getItem("jsessionid"),
        pageNo: "1",
        pageSize: "1000000",
      })
      .map((res) => res.json())
      .subscribe(
        (res) => {
          if (res.code == 200) {
            if (res.data.list.length != 0) {
              this.bgData = res.data.list;
              // // 获取第一个课程包的id
              this.cbtabStatus = "0";
              this.cbstatus = this.bgData[0].coursePkgid;
              this.http
                .post(
                  this.NavService.domain +
                    "/rc/coursePlanCenter/queryCoursePlanInfo",
                  {
                    token: localStorage.getItem("token"),
                    jsessionid: localStorage.getItem("jsessionid"),
                    cpId: this.cbstatus,
                    status: this.listStatus,
                  }
                )
                .map((res) => res.json())
                .subscribe(
                  (res) => {
                    // this.bgData = res.data.list;
                    // 获取第一个课程包的id
                    if (res.code == 200) {
                      console.log(res.data);
                      console.log(res.data.length);
                      if (res.data.length != 0) {
                        this.couList = res.data;
                        this.couList.forEach(function (val) {
                          if (val.rcCoursePlanTimeList.length == 0) {
                            // 没有排课安排
                            val.isHave = 0;
                          } else {
                            // 有排课安排
                            val.isHave = 1;
                          }
                        });
                      } else {
                        this.couList = [];
                        this.title = "暂无数据";
                      }
                    } else {
                      this.couList = [];
                      this.useWrongtatus = true;
                      this.resmsg = res.data.message;
                      setTimeout(() => {
                        this.useWrongtatus = false;
                      }, 3000);
                    }
                  },
                  (err) => {
                    this.couList = [];
                    this.useWrongtatus = true;
                    this.resmsg = res.data.message;
                    setTimeout(() => {
                      this.useWrongtatus = false;
                    }, 3000);
                  }
                );
            } else {
              this.couList = [];
              this.title = "暂无数据";
            }
          } else {
            this.useWrongtatus = true;
            this.resmsg = res.data.message;
            setTimeout(() => {
              this.useWrongtatus = false;
            }, 3000);
          }
        },
        (err) => {
          this.useWrongtatus = true;
          this.resmsg = "请求数据出错,请稍后重试!";
          setTimeout(() => {
            this.useWrongtatus = false;
          }, 3000);
        }
      );
  }
  // 课程包分类
  /*
   *it 为课程包id
   */
  planBg(it, index) {
    this.title = "";
    this.sheData = "";
    this.couList = [];
    this.cbstatus = it;
    this.sheStatus = false;
    this.sheStatusd = false;
    this.sheStatusres = false;
    this.cbtabStatus = index;
    //console.log(it, this.listStatus)
    this.http
      .post(
        this.NavService.domain + "/rc/coursePlanCenter/queryCoursePlanInfo",
        {
          token: localStorage.getItem("token"),
          jsessionid: localStorage.getItem("jsessionid"),
          cpId: it,
          status: this.listStatus,
        }
      )
      .map((res) => res.json())
      .subscribe(
        (res) => {
          // this.bgData = res.data.list;
          // 获取第一个课程包的id
          //console.log(res);
          if (res.code == 200) {
            if (res.data.length != 0) {
              this.couList = res.data;
              this.couList.forEach(function (val) {
                if (val.rcCoursePlanTimeList.length == 0) {
                  // 没有排课安排
                  val.isHave = 0;
                } else {
                  // 有排课安排
                  val.isHave = 1;
                }
                console.log(val.isHave);
              });
            } else {
              this.couList = [];
              this.title = "暂无数据";
            }
          } else {
            this.couList = [];
          }
        },
        (err) => {
          this.couList = [];
        }
      );
  }
  //全部
  /**
   *
   * @param num  根据num的值判断当前的分类
   */
  planA(num) {
    this.title = "";
    this.sheData = "";
    this.couList = [];
    this.listStatus = num;
    this.sheStatus = false;
    this.sheStatusd = false;
    this.sheStatusres = false;
    //console.log(this.cbstatus, this.listStatus)
    // $(".scroll-diving").scrollBar();
    // $(".chose-ul").scrollBar();
    this.http
      .post(
        this.NavService.domain + "/rc/coursePlanCenter/queryCoursePlanInfo",
        {
          token: localStorage.getItem("token"),
          jsessionid: localStorage.getItem("jsessionid"),
          cpId: this.cbstatus,
          status: this.listStatus,
        }
      )
      .map((res) => res.json())
      .subscribe(
        (res) => {
          // this.bgData = res.data.list;
          // 获取第一个课程包的id
          // console.log(res);
          this.title = "";
          if (res.code == 200) {
            if (res.data.length != 0) {
              this.couList = res.data;
              this.couList.forEach(function (val) {
                if (val.rcCoursePlanTimeList.length == 0) {
                  // 没有排课安排
                  val.isHave = 0;
                } else {
                  // 有排课安排
                  val.isHave = 1;
                }
              });
            } else {
              this.couList = [];
              this.title = "暂无数据";
            }
          } else {
            this.title = "暂无数据";
            this.couList = [];
            this.useWrongtatus = true;
            this.resmsg = res.message;
            setTimeout(() => {
              this.useWrongtatus = false;
            }, 3000);
          }
        },
        (err) => {
          this.couList = [];
          this.title = "暂无数据";
          this.couList = [];
          this.useWrongtatus = true;
          this.resmsg = "数据请求异常,请稍后再试!";
          setTimeout(() => {
            this.useWrongtatus = false;
          }, 3000);
        }
      );
  }
  //  点击展示课程列表细节
  detailData = [];
  teamId; //远企id
  planD(val, tid) {
    //console.log(val);
    this.title = "";
    this.teamId = tid; //教企课程表的id
    // $(".chose-ul").scrollBar();
    this.http
      .post(
        this.NavService.domain + "/rc/coursePlanCenter/queryCoursePlanTimeList",
        {
          // token: localStorage.getItem("token"),
          // jsessionid: localStorage.getItem("jsessionid"),
          courseId: val,
          // "status": this.listStatus
        }
      )
      .map((res) => res.json())
      .subscribe(
        (res) => {
          // console.log(res);
          if (res.code == 200) {
            // 数据成功
            if (res.data.length != 0) {
              this.sheStatus = true;
              this.sheStatusd = true;
              this.detailData = res.data;
            } else {
              // console.log(res.message);
              this.sheStatus = false;
              this.sheStatusd = false;
              this.useWrongtatus = true;
              this.resmsg = "暂无课程时间安排，敬请期待";
              setTimeout(() => {
                this.useWrongtatus = false;
              }, 3000);
            }
          } else {
            this.sheStatus = false;
            this.sheStatusd = false;
            this.useWrongtatus = true;
            this.resmsg = res.message;
            setTimeout(() => {
              this.useWrongtatus = false;
            }, 3000);
          }
        },
        (err) => {
          this.sheStatus = false;
          this.sheStatusd = false;
          this.useWrongtatus = true;
          this.resmsg = "请求数据出错,请稍后重试!";
          setTimeout(() => {
            this.useWrongtatus = false;
          }, 3000);
        }
      );
  }
  // 单个点击课程时间表数据
  checkClickdetail(e, value, ind) {
    this.sheData = value;
    //console.log(this.sheData, this.teamId);
  }
  // 确认选择  时间过期待显示
  planSure() {
    if (this.sheData.length != 0) {
      // 发送数据请求 成功弹出确认的按钮 失败弹出重新选择的按钮
      this.http
        .post(
          this.NavService.domain +
            "/rc/coursePlanCenter/modifyTeachingEnterpriseCourseTime",
          {
            token: localStorage.getItem("token"),
            jsessionid: localStorage.getItem("jsessionid"),
            id: this.teamId,
            coursePlanTimeId: this.sheData,
          }
        )
        .map((res) => res.json())
        .subscribe(
          (res) => {
            // console.log(res);
            if (res.code == 200) {
              if (res.data.success == 1) {
                this.sheStatusd = false; //全部课程展示
                // 说明排课成功
                this.sheStatus = true;
                this.sheStatusres = true;
              } else if (res.data.success == 2) {
                if (res.data.code == 300) {
                  this.classColor = true; //排课按钮不可点，置灰
                  this.Insufficient = true; //弹窗展示
                  this.resmsg = res.data.message; //展示提示文字
                } else {
                  // 已过期
                  this.sheStatusres = false;
                  this.sheStatusd = false;
                  this.sheStatus = true;
                  this.sheStatuspas = true;
                }
              } else {
                this.sheStatus = false; // 选课弹窗展示
                this.sheStatusd = false; //全部课程展示
                this.sheStatusres = false; //排课成功展示
                this.useWrongtatus = true;
                this.resmsg = res.data.message;
                setTimeout(() => {
                  this.useWrongtatus = false;
                }, 3000);
              }
            } else {
              this.sheStatus = false; // 选课弹窗展示
              this.sheStatusd = false; //全部课程展示
              this.sheStatusres = false; //排课成功展示
              this.useWrongtatus = true;
              this.resmsg = res.data.message;
              setTimeout(() => {
                this.useWrongtatus = false;
              }, 3000);
            }
          },
          (err) => {
            this.sheStatus = false; // 选课弹窗展示
            this.sheStatusd = false; //全部课程展示
            this.sheStatusres = false; //排课成功展示
            this.useWrongtatus = true;
            this.resmsg = "请求数据出错,请稍后重试!";
            setTimeout(() => {
              this.useWrongtatus = false;
            }, 3000);
          }
        );
    } else {
      this.useWrongtatus = true;
      this.sheStatus = false;
      // 逻辑后期沟通
      this.resmsg = "数据不能为空,请选择相关的数据";
      setTimeout(() => {
        this.useWrongtatus = false;
      }, 3000);
    }
  }
  // 返回值之后的确认
  planLose() {
    this.sheStatus = false;
    this.sheStatusd = false;
    this.sheStatusres = false;
    this.planA(this.listStatus);
  }
  // 重新選擇
  planAgain() {
    // 时间过期 点击直接关闭所有的弹窗
    this.sheStatuspas = false;
    this.sheStatus = false;
    this.sheStatusd = false;
    this.sheStatusres = false;
  }
  // 点击关闭弹窗
  clickCloseAll() {
    this.sheStatus = false;
    this.sheStatusd = false;
    this.sheStatusres = false;
    this.sheStatuspas = false;
    this.useWrongtatus = false;
    this.sheData = ""; //点击关闭的时候，重新赋值为空。避免已经勾选过，再次点击的时候未勾选的情况下可以排课成功
    this.Insufficient = false; //不足24小时
  }
  // 关闭时间冲突相关弹窗、
  clickClose() {
    this.Insufficient = false; //不足24小时
    this.classColor = false;
  }
}
