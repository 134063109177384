import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { RemoteComponent } from "./remote.component";
import { RemoindexcenterComponent } from "../remoindexcenter/remoindexcenter.component";
import { RemoteenterComponent } from "../remoteenter/remoteenter.component";
import { RemoteapplyComponent } from "../remoteapply/remoteapply.component";
import { RemotedscheduleComponent } from "../remotedschedule/remotedschedule.component";
import { RemotedetailComponent } from "../remotedetail/remotedetail.component";
import { ClassdetailComponent } from "../classdetail/classdetail.component";
import { RemotesdetailComponent } from "../remotesdetail/remotedetail.component";
import { RemoaboutComponent } from "../remoabout/remoabout.component";
import { RemoteindexComponent } from '../remotecenter/remoteindex.component';
const remoteRoutes: Routes = [
  {
    path: "remote",
    component: RemoteComponent,
    children: [
      {
        path: "home",
        component: RemoteenterComponent
      },
      {
        path: "choosecenter",
        component: RemoindexcenterComponent,
        children: [
          {
            path: '',
            component: RemoteindexComponent,
          },
          {
            path: "remote/:id",
            component: RemotedetailComponent
          },
          {
            path: "basis",
            component: RemoteindexComponent
          },
           {
            path: "up",
            component: RemoteindexComponent
          },
          {
            path: "class/:id",
            component: ClassdetailComponent
          },
          {
            path: "remotes/:id",
            component: RemotesdetailComponent
          }
        ]
      },
      {
        path: "remoabout",
        component: RemoaboutComponent
      },
      {
        path: "roomapply",
        component: RemoteapplyComponent
      },
      {
        path: "schedule",
        component: RemotedscheduleComponent
      },

    ]
  }
];
@NgModule({
  imports: [RouterModule.forChild(remoteRoutes)],
  exports: [RouterModule],
  declarations: []
})
export class RemoteRoutingModule { }
