import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { NavService } from "../../nav.service";
import { HttpClient } from "@angular/common/http";
import { HttpHeaders, HttpRequest } from "@angular/common/http";
import { Http, RequestOptions, Headers } from "@angular/http";
import "rxjs/add/operator/map"; //调用映射的响应可观察到得到解析的人对象。
declare var jQuery: any; //定义jquery
declare var $: any; //定义jquery
@Component({
  selector: "app-remote",
  templateUrl: "./remote.component.html",
  styleUrls: ["./remote.component.css"],
})
export class RemoteComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: Http,
    private NavService: NavService
  ) {}
  cpId;
  // 新增在线比赛入口  要求:当用户在官网登陆了之后才显示此入口,并且携带jid以及token值,以便确认身份
  examStatus = false; //控制在线比赛的入口
  examUrl; //绑定url所用的数据
  encrypted;
  names;
  nsmr_encrypted;
  surveylist;
  //登录的相关数据
  //初始化数据
  leng; //手机号的状态
  name = "";
  tele = "";
  teleword; //手机验证码
  verify = "";
  passWord = "";
  isPassword = "";
  tit = "";
  show;
  src;
  qq;
  verifycode; //获取验证码按钮存在的状态
  times = 60; //时间
  remcon = ""; //提示信息
  justsign; //立即注册按钮显示的状态
  unsign; //不能注册
  remessage = ""; //提示信息
  remstatus = false;
  picuser; //用户头像
  usernameshow; //用户名
  telename;
  personFlag; //控制个人中心的显示
  carted; //购物车的显示
  newsed; //控制消息
  unlogin; //未登录时显示登录入口
  signFlag; //未登录时显示注册的按钮
  is;
  isFind;
  isremember = "0";
  userName;
  pass;
  ngOnInit() {
    this.verifycode = true;
    this.src = this.NavService.domain + "/pub/verifi/imgCode";
    $(".copynavs").hide();
    // this.router.navigate(["./remote/choosecenter"]); //设置父子路由中的默认子路由显示
    // console.log(this.router);
    // this.route.paramMap.subscribe((params: ParamMap) => {
    //   this.cpId = params.get("id");
    //   console.log(this.cpId);
    // });
    if (!window.localStorage.getItem("token")) {
      this.examStatus = false;
      console.log("weidegnl");
    } else {
      //验证token是否失效
      console.log(!window.localStorage.getItem("token"));
      this.http
        .post(this.NavService.domain + "/userCenter/getUserById", {
          token: window.localStorage.getItem("token"),
          jsessionid: window.localStorage.getItem("jsessionid"),
        })
        .map((res) => res.json())
        .subscribe(
          (res) => {
            console.log(res + "检验token是否失效");
            if (res.code == 0) {
              this.examStatus = true; //说明已经登录
              var key = CryptoJS.enc.Utf8.parse("377902d57d26b67c");
              var srcs = CryptoJS.enc.Utf8.parse(res.data.username);
              this.encrypted = CryptoJS.AES.encrypt(srcs, key, {
                mode: CryptoJS.mode.ECB,
                padding: CryptoJS.pad.Pkcs7,
              });
              this.encrypted = this.encrypted.toString();
              this.encrypted = this.encrypted.replace(/\+/g, "%2B");
              this.encrypted = this.encrypted.replace(/\&/g, "%26");
              this.examUrl = this.NavService.examUrl + this.encrypted;
              this.names = res.data.name;
              console.log(this.names);
              var namekeys = CryptoJS.enc.Utf8.parse("377902d57d26b67c");
              var namesrcses = CryptoJS.enc.Utf8.parse(this.names);
              this.nsmr_encrypted = CryptoJS.AES.encrypt(namesrcses, namekeys, {
                mode: CryptoJS.mode.ECB,
                padding: CryptoJS.pad.Pkcs7,
              });
              this.nsmr_encrypted = this.nsmr_encrypted.toString();
              this.nsmr_encrypted = this.nsmr_encrypted.replace(/\+/g, "%2B");
              this.nsmr_encrypted = this.nsmr_encrypted.replace(/\&/g, "%26");
            } else {
              this.examStatus = false;
            }
          },
          (error) => {
            this.examStatus = false;
          }
        );
    }
    //回车登录
    $(".coursePassword,.courseAccount").keyup((event) => {
      if (event.keyCode == 13) {
        this.userName = $(".courseAccount input").val();
        this.pass = $(".coursePassword input").val();
        this.loaded();
      }
    });
  }
  // 页面锚点
  // goDistance1() {
  //   window.scrollTo(100, 600);
  //   this.router.navigate(["/choosecenter", { id: "readMore" }]);
  // }
  jump() {
    // console.log(this.examUrl);
    if (window.localStorage.getItem("token")) {
      // window.location.href = this.examUrl;
      window.open(this.examUrl);
    } else {
      this.is = true;
    }
  }
  /* 关于登录部分代码 */
  //点击隐藏登录框
  hide() {
    this.is = false;
    this.isFind = false;
    window.location.reload();
  }
  //获取用户名
  userClick(value) {
    ////console.log(value);
    this.userName = value;
  }
  //获取密码
  password(value) {
    ////console.log(value);
    this.pass = value;
  }
  //记住我
  checkme(flag) {
    //点击记住我(这里面包括密码和用户名)
    ////console.log(flag);
    this.isremember = "0";
    ////console.log(flag.target.checked);
    if (flag.target.checked == true) {
      this.isremember = "1";
    }
  }
  //开始登录
  loaded() {
    let encoder = this.pass.toString("base64");
    var key = CryptoJS.enc.Utf8.parse("377902d57d26b67c");
    var srcs = CryptoJS.enc.Utf8.parse(encoder);
    //console.log(srcs);
    var encrypted = CryptoJS.AES.encrypt(srcs, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    encrypted = encrypted.toString();
    //alert(encrypted.toString());
    this.http
      .post(this.NavService.domain + "/user/login", {
        username: this.userName,
        password: encrypted,
        isremember: this.isremember,
      })
      .map((res) => res.json())
      .subscribe(
        (res) => {
          //console.log(res);
          if (res.code == 0) {
            //说明登录成功
            //如果点击了记住wo
            window.localStorage.setItem("token", res.data.token);
            window.localStorage.setItem("jsessionid", res.data.jsessionid);
            this.personFlag = true; //控制个人中心的显示
            this.carted = true; //购物车的显示
            this.newsed = true;
            this.unlogin = false; //未登录时显示登录入口
            this.signFlag = false; //未登录时显示注册的按钮
            this.is = false; //隐藏登录窗口nickName
            this.remstatus = false;
            this.picuser = res.data.userAvatar; //用户头像
            this.usernameshow = res.data.nickName; //用户名
            this.telename = res.data.userMobile.slice(0, 3);
            window.localStorage.setItem("pic", res.data.userAvatar);
            window.localStorage.setItem("nickName", res.data.nickName);
            window.localStorage.setItem("tele", this.telename);
            window.location.reload();
          } else {
            //登录失败
            this.personFlag = false; //控制个人中心的显示
            this.carted = false; //购物车的显示
            this.newsed = false;
            this.unlogin = true; //未登录时显示登录入口
            this.signFlag = true; //未登录时显示注册的按钮
            this.is = true; //隐藏登录窗口
            this.remstatus = true;
            this.remessage = res.msg;
          }
        },
        (error) => {
          ////console.log(error);
        }
      );
  }
  //找回密码显示
  findPass() {
    this.is = false;
    this.isFind = true;
    //点击找回密码
  }
  //渲染图片二维码
  imgSrc() {
    this.src = this.NavService.domain + "/pub/verifi/imgCode";
    this.src += "?id=" + Math.random() * 10;
  }
  //获取电话
  saveTele(value: string) {
    this.tele = value;
    ////console.log(this.tele);
    var reg = new RegExp(/^1[3|4|5|6|7|8|9][0-9]\d{8}$/);
    ////console.log(reg.test(this.tele));
    if (reg.test(this.tele)) {
      this.remcon = "";
    } else {
      this.remcon = "手机号码有误,请修改手机号码";
    }
  }
  //获取图片验证码
  saveVerify(value: string) {
    this.verify = value;
    ////console.log(this.verify);
  }
  //获取动态的验证码
  safe() {
    this.times = 60;
    var reg = new RegExp(/^1[3|4|5|6|7|8|9][0-9]\d{8}$/);
    ////console.log(this.verify);
    if (!reg.test(this.tele)) {
      this.remcon = "请输入正确的手机号---";
    } else if (!this.verify) {
      this.remcon = "请输入图片验证码";
    } else {
      this.remcon = "";
      this.unsign = false;
      this.justsign = true;
      // 这里加了一个图片验证码的参数,进行图片验证码的校验
      var info = {
        mobile: this.tele,
        imgValiCode: this.verify,
      };
      $.ajax({
        type: "POST",
        crossDomain: true,
        xhrFields: {
          withCredentials: true,
        },
        data: JSON.stringify(info),
        contentType: "application/json;charset=UTF-8",
        url: this.NavService.domain + "/pub/shortMsg/send",
        dataType: "json",
        success: (res) => {
          //console.log(res);
          if (res.code == 0) {
            // console.log(this.times);
            this.verifycode = false;
            var timer = setInterval(() => {
              this.times--;
              if (this.times <= 0) {
                this.verifycode = true;
                clearInterval(timer);
                this.src = this.NavService.domain + "/pub/verifi/imgCode";
                this.src += "?id=" + Math.random() * 10;
              }
            }, 1000);
          } else {
            this.remcon = res.msg;
            this.src = this.NavService.domain + "/pub/verifi/imgCode";
            this.src += "?id=" + Math.random() * 10;
          }
        },
      });
    }
  }
  savePassword(value: string) {
    this.passWord = value;
  }
  //获取第二次密码  在这里判断两次密码是否输入一致
  saveisPassword(value: string) {
    this.isPassword = value;
    if (this.passWord == this.isPassword) {
      this.remcon = "";
    } else {
      this.remcon = "两次输入的密码不一致";
    }
    ////console.log(this.isPassword);
  }
  //获取手机验证码
  saveTelev(value: string) {
    this.teleword = value;
    if (!value) {
      this.verifycode = true;
    }
  }
  //找回密码
  foundpass() {
    //console.log(1);
    //点击确认找回密码
    //开始获取数据
    var reg = new RegExp(/^1[3|4|5|6|7|8|9][0-9]\d{8}$/);
    if (!reg.test(this.tele)) {
      //验证手机号
      this.remcon = "手机号码有误,请修改手机号码";
    } else if (!this.verify) {
      this.remcon = "请输入图片验证码";
    } else if (!this.passWord) {
      //密码
      this.remcon = "请输入密码";
    } else if (!this.isPassword) {
      this.remcon = "请再次输入密码";
    } else if (this.passWord !== this.isPassword) {
      this.remcon = "两次输入的密码不一致";
    } else if (!this.teleword) {
      this.remcon = "请输入手机验证码";
    } else {
      this.remcon = "";
      //let encoder = this.passWord.toString('base64');
      var key = CryptoJS.enc.Utf8.parse("377902d57d26b67c");
      var srcs = CryptoJS.enc.Utf8.parse(this.passWord);
      ////console.log(srcs);
      var encrypted = CryptoJS.AES.encrypt(srcs, key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
      });
      encrypted = encrypted.toString();
      //let encoderpass = this.isPassword.toString('base64');
      var keys = CryptoJS.enc.Utf8.parse("377902d57d26b67c");
      var srcss = CryptoJS.enc.Utf8.parse(this.isPassword);
      ////console.log(srcs);
      var encryptedpass = CryptoJS.AES.encrypt(srcss, keys, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
      });
      encryptedpass = encryptedpass.toString();
      //alert(encryptedpass.toString());
      let header = new Headers({ "Content-Type": "application/json" }); //null,其实不表明 json 也可以, ng 默认好像是 json
      let options = new RequestOptions({
        headers: header,
        withCredentials: true,
      });
      var userInfo = {
        mobile: this.tele,
        captcha: this.teleword,
        repassword: encryptedpass,
        password: encrypted,
      };
      this.http
        .post(
          this.NavService.domain + "/user/getPwd",
          JSON.stringify(userInfo),
          options
        )
        .map((res) => res.json())
        .subscribe(
          (res) => {
            // console.log(res);
            if (res.code == 0) {
              this.remcon = res.msg + "正在为您切换登录窗......";
              this.is = true;
              this.isFind = false;
            } else {
              this.remcon = res.msg;
            }
          },
          (error) => {
            this.remcon = error.msg;
          }
        );
    }
  }
  /* 登录部分结束 */
}
