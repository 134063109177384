import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NavService } from "../../nav.service";
import { HttpClient } from "@angular/common/http";
import { HttpHeaders, HttpRequest } from "@angular/common/http";
import { Http, RequestOptions, Headers } from "@angular/http";
import "rxjs/add/operator/map"; //调用映射的响应可观察到得到解析的人对象。
declare var jQuery: any; //定义jquery
declare var $: any; //定义jquery
@Component({
  selector: "app-home",
  templateUrl: "./remoteenter.component.html",
  styleUrls: ["./remoteenter.component.css"],
})
export class RemoteenterComponent implements OnInit {
  constructor(
    private router: Router,
    private http: Http,
    private NavService: NavService
  ) {}
  // 初始化数据
  tabNumList = 0;
  tabList = []; // 选课中心tab列表数据
  listData = []; // 选课中心的渲染数据
  upData = []; //增强包渲染数据
  teaData = []; //讲师渲染数据
  tabTitle = ""; //暂无数据
  listTitle = ""; //暂无数据
  tabListId = ""; // 基础包tab列表的第一条数据
  bannerData = [];

  ngOnInit() {
    window.scrollTo(0, 0);
    $("html").scrollTop(0);
    // console.log(this.router);

    // 获取首页-选课中心-初始化数据 tab栏列表获取 默认渲染第一个数据的内容
    this.http
      .post(
        this.NavService.domain + "/rc/index/queryRecommendCoursePackageLevel",
        {}
      )
      .map((res) => res.json())
      .subscribe(
        (res) => {
          if (res.code == 200) {
            // 说明数据是成功的
            if (res.data.length > 0) {
              this.tabList = res.data;
              this.tabListId = res.data[0].id;
            } else {
              this.tabList = [];
              return false;
            }
            // 获取第一条数据的id值
            this.http
              .post(
                this.NavService.domain +
                  "/rc/index/queryRecommendCoursePackageBy",
                {
                  packageLevelId: this.tabListId,
                }
              )
              .map((res) => res.json())
              .subscribe(
                (res) => {
                  if (res.code == 200) {
                    // 说明数据是成功的
                    this.listTitle = "";
                    this.listData = res.data;
                  } else {
                    this.listTitle = "暂无数据";
                    this.listData = [];
                  }
                },
                (err) => {
                  this.listTitle = "暂无数据";
                  this.listData = [];
                }
              );
          } else {
            this.tabTitle = "暂无数据";
            this.tabList = [];
          }
        },
        (err) => {
          this.tabTitle = "暂无数据";
          this.tabList = [];
        }
      );
    // 增强包推荐数据  两个
    this.http
      .post(this.NavService.domain + "/rc/index/selectAddCoursePackageList", {})
      .map((res) => res.json())
      .subscribe(
        (res) => {
          if (res.code == 200) {
            // 说明数据是成功的
            this.upData = res.data;
          } else {
            this.upData = [];
          }
        },
        (err) => {
          this.upData = [];
        }
      );
    // 轮播图
    this.http
      .post(this.NavService.domain + "/rc/rcBanner/homeBanner", {})
      .map((res) => res.json())
      .subscribe(
        (res) => {
          if (res.code == 200) {
            // 说明数据是成功的
            this.bannerData = res.data;
            setTimeout(() => {
              // 轮播图
              var mySwiper = new Swiper(".swiper-container", {
                autoplay: 3000,
                loop: true,
                pagination: ".swiper-pagination",
                prevButton: ".swiper-button-prev",
                nextButton: ".swiper-button-next",
                autoplayDisableOnInteraction: false,
                paginationClickable: true,
                clickable: true,
              });
              $(".swiper-container")
                .mouseenter(function () {
                  mySwiper.stopAutoplay();
                })
                .mouseleave(function () {
                  mySwiper.startAutoplay();
                });
              console.log(mySwiper);
            }, 400);
          } else {
            this.teaData = [];
          }
        },
        (err) => {
          this.teaData = [];
        }
      );
    // 讲师  八条数据
    this.http
      .post(this.NavService.domain + "//teacher/isTopTeacherList", {
        pageNo: 1,
        pageSize: 8,
        isRcTeacher: 1,
      })
      .map((res) => res.json())
      .subscribe(
        (res) => {
          if (res.code == 0) {
            // 说明数据是成功的
            this.teaData = res.data.list;
          } else {
            this.teaData = [];
          }
        },
        (err) => {
          this.teaData = [];
        }
      );
  }
  /*选课中心部分的tab栏切换
    函数名:  tabClick  tab栏切换函数
    params  tabNum  当前传入的触发条件
            tabId  当前等级包的id
  */
  tabClick(tabNum, tabId) {
    console.log(tabNum, tabId);
    this.tabNumList = tabNum;
    // 发送数据请求
    this.http
      .post(
        this.NavService.domain + "/rc/index/queryRecommendCoursePackageBy",
        {
          packageLevelId: tabId,
        }
      )
      .map((res) => res.json())
      .subscribe(
        (res) => {
          if (res.code == 200) {
            // 说明数据是成功的
            this.listData = res.data;
          } else {
            this.listTitle = "暂无数据";
            this.listData = [];
          }
        },
        (err) => {
          this.listTitle = "暂无数据";
          this.listData = [];
        }
      );
  }
}
