import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NavService } from "../../nav.service";
import { HttpClient } from "@angular/common/http";
import { HttpHeaders, HttpRequest } from "@angular/common/http";
import { Http, RequestOptions, Headers } from "@angular/http";
import "rxjs/add/operator/map"; //调用映射的响应可观察到得到解析的人对象。
declare var jQuery: any; //定义jquery
declare var $: any; //定义jquery
@Component({
  selector: "app-remote",
  templateUrl: "./remoteindex.component.html",
  styleUrls: ["./remoteindex.component.css"],
})
export class RemoteindexComponent implements OnInit {
  constructor(
    private router: Router,
    private http: Http,
    private NavService: NavService
  ) {}
  // 初始化数据
  nowUrl = "";
  levelAll = -1; //等级全部选项 tab
  direAll = -1; //方向全部选项 tab
  levelId = null; //等级的id 传值
  direId = null; //方向的id 传值
  title = ""; //数据提示
  listCdata = [];
  //课程包等级
  packageLevesList = [];
  //课程包方向
  packageDirectionsList = [];
  // 课程包(增强包)集合
  zipList = [];
  ngOnInit() {
    console.log(this.router.url);
    window.scrollTo(0, 0);
    // 路由跳转到顶部
    this.router.events.subscribe((evt) => {
      window.scrollTo(0, 0);
    });
    this.nowUrl = this.router.url;
    // 判断选课中心的入口 与此同时初始化数据
    switch (this.nowUrl) {
      case "/remote/choosecenter/up":
        console.log("增强包");
        this.http
          .post(this.NavService.domain + "/rc/coursebackage/selectPackages", {
            level: 1,
          })
          .map((res) => res.json())
          .subscribe(
            (res) => {
              if (res.code == 200) {
                this.packageLevesList = res.data.packageLeves;
                this.packageDirectionsList = res.data.packageDirections;
                this.zipList = res.data.packages;
              } else {
                this.packageLevesList = [];
                this.packageDirectionsList = [];
                this.zipList = [];
              }
            },
            (err) => {
              this.packageLevesList = [];
              this.packageDirectionsList = [];
              this.zipList = [];
            }
          );
        break;
      case "/remote/choosecenter/basis":
        console.log("基础包");
        this.http
          .post(this.NavService.domain + "/rc/coursebackage/selectPackages", {
            level: 0,
          })
          .map((res) => res.json())
          .subscribe(
            (res) => {
              if (res.code == 200) {
                this.packageLevesList = res.data.packageLeves;
                this.packageDirectionsList = res.data.packageDirections;
                this.zipList = res.data.packages;
              } else {
                this.packageLevesList = [];
                this.packageDirectionsList = [];
                this.zipList = [];
              }
            },
            (err) => {
              this.packageLevesList = [];
              this.packageDirectionsList = [];
              this.zipList = [];
            }
          );
        break;
      case "/remote/choosecenter":
        console.log("混合包");
        this.http
          .post(this.NavService.domain + "/rc/coursebackage/selectPackages", {})
          .map((res) => res.json())
          .subscribe(
            (res) => {
              if (res.code == 200) {
                this.packageLevesList = res.data.packageLeves;
                this.packageDirectionsList = res.data.packageDirections;
                this.zipList = res.data.packages;
              } else {
                this.packageLevesList = [];
                this.packageDirectionsList = [];
                this.zipList = [];
              }
            },
            (err) => {
              this.packageLevesList = [];
              this.packageDirectionsList = [];
              this.zipList = [];
            }
          );
        break;
    }
    //滚动条滚动
    $(document).ready(function () {
      // 吸顶效果
      $(window).scroll(function () {
        if ($(window).scrollTop() > 100) {
          $(".remote-title").css({
            position: "fixed",
            top: 0,
            background: "#fff",
            "z-index": 100000,
          });
        } else {
          $(".remote-title").css({ position: "inherit", "z-index": 0 });
        }
      });
    });
    // // 路由跳转到顶部
    // this.router.events.subscribe((evt) => {
    //   window.scrollTo(0, 0);
    // });
  }
  /**
   * 课程等级
   * params levelid  等级id
   *        i 当前的索引
   * 课程方向
   *  params direid  方向id
   *        i 当前的索引
   */
  zipLevel(levelid, i) {
    this.levelAll = i;
    this.levelId = levelid;
    // console.log(this.direId, this.levelId);
    if ($(window).scrollTop() > 300) {
      $(window).scrollTop(0);
    }
    this.http
      .post(
        this.NavService.domain + "/rc/coursebackage/selectPackagesByCondition",
        {
          packageLevelId: this.levelId,
          packageDirectionId: this.direId,
        }
      )
      .map((res) => res.json())
      .subscribe(
        (res) => {
          if (res.code == 200) {
            console.log(res.data);
            this.zipList = res.data;
          } else {
            this.zipList = [];
          }
        },
        (err) => {
          this.zipList = [];
        }
      );
  }
  zipDire(direid, i) {
    this.direAll = i;
    this.direId = direid;
    // console.log(this.direId, this.levelId);
    if ($(window).scrollTop() > 300) {
      $(window).scrollTop(0);
    }
    this.http
      .post(
        this.NavService.domain + "/rc/coursebackage/selectPackagesByCondition",
        {
          packageLevelId: this.levelId,
          packageDirectionId: this.direId,
        }
      )
      .map((res) => res.json())
      .subscribe(
        (res) => {
          if (res.code == 200) {
            console.log(res.data);
            this.zipList = res.data;
          } else {
            this.zipList = [];
          }
        },
        (err) => {
          this.zipList = [];
        }
      );
  }
  // 模糊查询
  searInput(val) {
    console.log(val);
    this.levelAll = -1;
    this.direAll = -1;
    this.levelId = null;
    this.direId = null;
    this.http
      .post(
        this.NavService.domain + "/rc/coursebackage/selectPackagesByKeword",
        {
          keyword: val,
        }
      )
      .map((res) => res.json())
      .subscribe(
        (res) => {
          if (res.code == 200) {
            this.zipList = res.data;
          } else {
            this.zipList = [];
          }
        },
        (err) => {
          this.zipList = [];
        }
      );
  }
}
