import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
// import { FullCalendarModule } from '@fullcalendar/angular'; // for FullCalendar!
// import {CdkTableModule} from '@angular/cdk/table';
// import {CdkTreeModule} from '@angular/cdk/tree';
// import { MatButtonModule }from '@angular/material';
//初始化HttpClientModule这里是http模块
import { HttpClientModule } from '@angular/common/http';
//注册http服务
import { HttpModule } from '@angular/http';
//import { QRCodeModule } from 'angular2-qrcode';

//配置路由
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
//注入新的组件
//import {AuthenComponent} from './authen/authen.component'; //认证组件
//import {TrainCampComponent} from './train-camp/train-camp.component'; //集训营内容组件
//import {MessageComponent} from './message/message.component'; //资讯内容组件
import { SignComponent } from './sign.component'; // 注册内容组件
import { WrongComponent } from './wrong/wrong.component'; // 404页面
//import {JobTeacherComponent} from './job-teacher/jobteacher.component'; //首页的讲师招募
//import { DengbaoComponent } from './dengbao/isaydengbao.component'; //我说等保
//讲师集合的组件
//import { TeacherComponent } from './teacher/teacher.component';
//import { TeacherSimpleComponent } from './teacher/teacher-simple.component';
//二级页面的组件  从home页面跳转过来的二级页面
//import  { PersonalCenterComponent } from './personal-center/personal-center.component';  //个人中心组件
//import { AuthenCourseComponent } from './home/authen-course-list/authen-course.component'; //认证课程模板组件  是每一个课程调取的页面
//import { CiipComponent } from './authen/authen-list/ciip.component'; //等保培训组件   包括首页入口以及认证培训的入口之一
//import { CispComponent } from './authen/authen-list/cisp.component'; //等保培训组件   包括首页入口以及认证培训的入口之一
//个人中心的组件
//import { PersonalTemplateComponent } from './personal-center/personal-template.component';
//import { MyMessageComponent } from './personal-center/my-message/my-message.component';  //我的消息组件
//import { MyCourseCenterComponent } from './personal-center/my-course/my-course.component';  //我的课程组件
//import { MyTestCenterComponent } from './personal-center/my-test/my-test.component';  //我的测试组件
//import { MyCertificateCenterComponent } from './personal-center/my-certificate/my-certificate.component'; //我的证书组件
//import { MyOrderCenterComponent } from './personal-center/my-order/my-order.component'; //我的订单组件
//import { MyCollectCenterComponent } from './personal-center/my-collect/my-collect.component'; //我的收藏组件
//import { MyAnswerCenterComponent } from './personal-center/my-answer/my-answer.component'; //我的问答组件
//import { MyMoneyCenterComponent } from './personal-center/my-money/my-money.component'; //我的资产组件
//import { PersonalInfoCenterComponent } from './personal-center/personal-info/personal-info.component'; //个人信息组件
//import { AccountSafeCenterCopmponent } from './personal-center/account-safe/account-safe.component'; /;

import { RemoteComponent } from './remote-class/remote/remote.component';
// import { RemotedetailComponent } from './remote-class/remotedetail/remotedetail.component';
// import { RemotedscheduleComponent } from './remote-class/remotedschedule/remotedschedule.component';
// import { RemoteapplyComponent } from './remote-class/remoteapply/remoteapply.component;
//import  { PersonalServe } from './personal-center/personal.service';
//import  { PersonalDirective } from './personal-center/personal.directive';
import { simpleOrderComponent } from './personal-center/simpleorder/simple.component';

//考试的组件
import { loadingComponent } from './load.component';


import { HeroControlsComponent } from './Zjean/zjean.component';
import { SwipersComponent } from './zje.component';
//
//import { QqaComponent }from './qrqcode.component';
// 自定义管道
// import { MywidthPipe } from './personal-center/my-course/width.pipe';
// 远程教室入口模块
import { RemoteRoutingModule } from './remote-class/remote/remote-routing.module';
import { RemoteModule } from './remote-class/remote/remote.module';
// import { RemotecenterRoutingModule } from './remote-class/remoindexcenter/remotein-routing.module';
// import { RemocenterModule } from './remote-class/remoindexcenter/remotein.module';
// 远程教室个人中心
import { RemoindexComponent } from './remote-center/remo-center-index/remoindex.componet';
import { RemoindexRoutingModule } from './remote-center/remo-center-index/remoindex-routing.module';
import { RemoindexModule } from './remote-center/remo-center-index/remoindex.module';
// import { RemoteinRoutingModule }  from './remote-class/remoteindex/remotein-routing.module';
// import { RemoteinModule } from './remote-class/remoteindex/remotein.module'
import { JustOrderComponent } from  './just-order/just-order.component';

  import { formArrayNameProvider } from '@angular/forms/src/directives/reactive_directives/form_group_name';
@NgModule({
  declarations: [
    AppComponent,
    //CiipComponent,
    //TrainCampComponent,
    SignComponent,
    JustOrderComponent,
    WrongComponent,
    //AuthenComponent,
    //AuthenCourseComponent,
    //CispComponent,
    //PersonalCenterComponent,
    //PersonalTemplateComponent,
    //MyMessageComponent,
    //PersonalDirective,
    //MyCourseCenterComponent,
    //MyTestCenterComponent,
    //MyCertificateCenterComponent,
    //MyOrderCenterComponent,
    //MyCollectCenterComponent,
    //MyAnswerCenterComponent,
    //MyMoneyCenterComponent,
    //PersonalInfoCenterComponent,
    //AccountSafeCenterCopmponent,
    //TeacherComponent,
    //Te,
    RemoteComponent,
    RemoindexComponent,
    // RemotedetailComponent,
    // RemotedscheduleComponent,
    // RemoteapplyComponentache,
    //JobTeacherComponent,
    loadingComponent,
    //simpleOrderComponent,
    //CiiptComponent,
    //CisaComponent,
    //CispsComponent,
    //CisspComponent,
    //CobitComponent,
    //CriscComponent,
    //IsoTComponent,
    //IsosComponent,
    //ItilComponent,
    //ItssComponent,
    //PrinceComponent,
    //QyjgComponent,
    //YwcxComponent,
    //YwcxgjComponent,
    HeroControlsComponent,
    SwipersComponent,
    
    //QqaComponent,
    //DengbaoComponent,
    // MywidthPipe
  ],
  imports: [
    BrowserModule,
    FormsModule,
    //在browserModule模块之下引用
    HttpModule,
    HttpClientModule,
    // MatButtonModule,
    // CdkTableModule,
    // CdkTreeModule,
    // MatPaginatorModule,
    //QRCodeModule,
    RemoteModule,
    RemoteRoutingModule,
    RemoindexModule,
    RemoindexRoutingModule,
    // RemotecenterRoutingModule,
    // RemocenterModule,
    // RemoteinModule,
    // RemoteinRoutingModule,
    RouterModule.forRoot(
      [
        {
          path: '',
          //component: HomeComponent
          loadChildren: 'app/home/home.module#HomeModule'
        },
        {
          path: 'home',
          //component: HomeComponent
          loadChildren: 'app/home/home.module#HomeModule'
        },
        //{
        //  path: 'login/aaa',
        //  //component: HomeComponent
        //  loadChildren: 'app/home/home.module#HomeModule'
        //},
        {
          path: 'courses',
          //component: CoursesComponent
          loadChildren: 'app/courses/courses.module#CoursesModule'
        },
        {
          path: 'courses/:sid/:scon',
          //component: CoursesComponent
          loadChildren: 'app/courses/courses.module#CoursesModule'
        },
        {
          path: 'home/courses/:id/:typeid',
          //component: CoursesComponent
          loadChildren: 'app/courses/courses.module#CoursesModule'
        },
        {
          path: 'home/courses/:itemid',
          //component: CoursesComponent
          loadChildren: 'app/courses/courses.module#CoursesModule'
        }, {
          path: 'home/courses/:id',
          //component: CoursesTemplateComponent
          loadChildren: 'app/courses/coursestem.module#CoursestemModule'
        },
        {
          path: 'courses/:id',
          //component: CoursesTemplateComponent
          loadChildren: 'app/courses/coursestem.module#CoursestemModule'
        },
        {
          path: 'authen',
          //component:AuthenComponent
          loadChildren: 'app/authen/authen.module#AuthenModule'
        },
        {
          path: 'train',
          //component: TrainCampComponent
          loadChildren: 'app/train-camp/traincamp.module#TraincampModule'
        },
        {
          path: 'career',
          //component: CareerComponent
          loadChildren: 'app/career/career.module#CareerModule'
        },
        {
          path: 'job/:id',
          //component: JobComponent
          loadChildren: 'app/job/job.module#JobModule'
        },
        {
          path: 'message',
          //component: MessageComponent
          loadChildren: 'app/message/message.module#MessageModule'
        },
        {
          path: 'message/:id/:typeid',
          //component: MessageTemComponent
          loadChildren: 'app/message/message-template/messagetem.module#MessagetemModule'
        },
        {
          path: 'sign',
          component: SignComponent
          //loadChildren: 'app/sign.component#SignComponent'
        },
        {
          path: 'answer',
          //component:AnswerComponent
          loadChildren: 'app/answer/answer.module#AnswerModule'
        },
        {
          path: 'answer/:id',
          //component:AnswerTemplateComponent
          loadChildren: 'app/answer/answertem.module#AnswertemModule'
        },
        {
          path: 'partner',
          //component:PartnerComponent
          loadChildren: 'app/partner/partner.module#PartnerModule'
        },
        {
          path: 'dengbao',
          //component:DengbaoComponent
          loadChildren: 'app/dengbao/dengbao.module#DengbaoModule'
        },
        //{
        //  path:'cisps',
        //  component:CispComponent
        //},
        {
          path: 'safe',
          //component:SafeComponent
          loadChildren: 'app/home/safe.module#SafeModule'
        },
        {
          path: 'zjean/:id',
          component: HeroControlsComponent
          //loadChildren: 'app/Zjean/zj.module#ZjModule'
        },
        {
          path: 'authencourses',
          //component:AuthenCourseComponent,
          loadChildren: 'app/home/authen-course-list/authenlist.module#AuthenlistModule'
        },
        {
          path: 'exclusive',
          //component:Exclusive
          loadChildren: 'app/new-user/exclusive.module#ExclusiveModule'
        },
        {
          path: 'guide',
          //component:
          loadChildren: 'app/guide/guide.module#GuideModule'
        },
        {
          path: 'career/:id',
          //component:CareerRouteTemplateComponent
          loadChildren: 'app/career/career-list/careerlist.module#CareerlistModule'
        },
        {
          path: 'personal-center',
          //component: PersonalCenterComponent
          loadChildren: 'app/personal-center/pc.module#PcModule'
        },
        {
          path: 'teacher',
          //component: TeacherComponent
          loadChildren: 'app/teacher/teacher.module#TeacherModule'
        },
        {
          path: 'teacher/remote',
          //component: TeacherComponent
          loadChildren: 'app/teacher/teacher.module#TeacherModule'
        },
        {
          path: 'teacher/:id',
          //component: TeacherSimpleComponent
          loadChildren: 'app/teacher/teachersim.module#TeachersimModule'
        },
        {
          path: 'exam/:id/:couid',
          //component:ExamComponent
          loadChildren: 'app/exam/exam.module#ExamModule'
        },
        {
          path: 'lecturer',
          //component:JobTeacherComponent
          loadChildren: 'app/job-teacher/jobt.module#JobtModule'
        },
        {
          path: 'partner/:typeid/:id',
          //component:partCourseComponet
          loadChildren: 'app/partcourse/partcourse.module#PartcourseModule'
        },
        {
          path: 'cart',
          //component:CartComponent
          loadChildren: 'app/cart/cart.module#CartModule'
        },
        {
          path: 'cart/:id',
          //component:CartComponent
          loadChildren: 'app/cart/cart.module#CartModule'
        },
        {
          path: 'wrong',
          component: WrongComponent
        }, 
        {
          path: 'order-list/:id',
          component: JustOrderComponent,
          //  loadChildren: 'app/just-order/just.module#JustModule'
        },
        {
          path: 'remote',
          component: RemoteComponent
          // loadChildren: 'app/remote-class/remote/remote.module#RemoteModule'
        },
        {
          path: 'remocenter',
          component: RemoindexComponent
          // loadChildren: 'app/remote-class/remote/remote.module#RemoteModule'
        },
        // {
        //   path:'remote/:id',
        //   component: RemotedetailComponent
        //   // loadChildren: 'app/remote-class/remote/remote.module#RemoteModule'
        // },
        {
          path: '**',
          redirectTo: 'wrong',
          pathMatch: 'full'
        }
      ]
    )
  ],
  providers: [],
  //providers: [PersonalServe],
  //entryComponents: [
  //  MyMessageComponent,
  //  PersonalTemplateComponent,
  //  MyCourseCenterComponent,
  //  MyTestCenterComponent,
  //  MyCertificateCenterComponent,
  //  MyOrderCenterComponent,
  //  MyCollectCenterComponent,
  //  MyAnswerCenterComponent,
  //  MyMoneyCenterComponent,
  //  PersonalInfoCenterComponent,
  //  AccountSafeCenterCopmponent,
  //  simpleOrderComponent
  //]
  //,
  bootstrap: [AppComponent]
})
export class AppModule { }
