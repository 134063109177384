import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavService } from '../../nav.service';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Http } from '@angular/http';
import 'rxjs/add/operator/map';//调用映射的响应可观察到得到解析的人对象。
declare var jQuery: any;  //定义jquery
declare var $: any;  //定义jquery
@Component({
  selector: 'app-remouser',
  templateUrl: './remouser.component.html',
  styleUrls: ['./remouser.component.css']
})
export class RemouserComponent implements OnInit {

  constructor(
    private router: Router,
    private http: Http,
    private NavService: NavService
  ) { }
  // 初始化数据
  title = '';
  no_ability = false;
  all_status = false;
  detail_status = false;
  userNum = 0;// 目前关联
  nocheck = 0;
  allUserData = [];
  // 分页数据
  currpage = 1;//当前的页数;
  lengs: number;
  AllNum = [];
  //删除弹出数据
  useDelestatus = false; //控制删除弹窗的开关
  // 全选数组
  checkAlldata = [];
  choseFlag; //说明全选 false //说明取消全选
  // 单选数据
  checkSingle = []; //单选最终数据
  checkTem = []; //单选的过渡数据
  //  错误提示弹窗
  useWrongtatus = false;
  resmsg = "";
  // 审核细节数据
  checkDetail = []; //总数据
  cStatus = 5; //审核分类
  cWords = '';//关键字
  // 批量删除 批量拒绝
  delingData = [];
  delingTem = []; //临时数据
  // 细节审核弹窗数据
  detailPastatus = false;
  detailRefusestatus = false;
  // 搜索数据
  serdata = "无"
  // 单击确认按钮
  clickTong = [];
  // 单点通过和拒绝的
  clickSinStatus = false;
  clickSinRefusestatus = false;
  ngOnInit() {
    // this.clickNo();
    //  this.router.navigate(['./remocenter/remohave']);  //设置父子路由中的默认子路由显示
    //console.log(this.router.url);
    // 判断用户是否已经登录
    this.http.post(this.NavService.domain_xl + '/user/getUserInfo', {
      token: localStorage.getItem("token"),
      jsessionid: localStorage.getItem("jsessionid")
    })
      .map(res => res.json())
      .subscribe(
        res => {
          //console.log(res)
          if (res.code == 0) {
            // 说明已经登陆了  
            // 获取用户信息 ,知道是什么用户类别
            this.http.post(this.NavService.domain + '/rc/rcusers/selectOneUser', {
              token: localStorage.getItem("token"),
              jsessionid: localStorage.getItem("jsessionid")
            }).map(res => (res.json())).subscribe(
              res => {
                //console.log(res)
                if (res.code == 200) {
                  if (res.data.userRole == 0) {
                    // 普通用户 返回普通用户的个人中心
                    location.href = "../personal-center";

                  } else if (res.data.userRole == 1) {
                    // 企业用户
                    this.clickNo();

                  } else {
                    this.clickAll();
                  }
                } else {
                  location.href = "../personal-center";
                }
              },
              err => {
                //console.log(err);
                location.href = "../personal-center";
              }
            );
          } else {
            // 未登录 返回普通的用户中心
            location.href = "../personal-center";
          }
        },
        err => {
          //console.log(err)
          location.href = "../personal-center";
        }
      )

  }
  // 点击标题部分的全选功能
  checkClickall(e, value) {
    this.checkAlldata = [];
    //console.log(e.target);
    if (value) {
      $(".checktail").prop("checked", true);
      $(".checktail").each((index, ele) => {
        this.checkAlldata.push($(ele).attr("id"));
      })
    } else {
      this.checkAlldata = [];
      $(".checktail").prop("checked", false);
    }
    //console.log(this.checkAlldata)
  }
  // 单个点击数据
  checkClick(e, value: Number, ind, allS) {
    var tempEle = $(e.target);
    //console.log(tempEle.prop("checked"), allS)
    if (allS) {
      // 全选
      if (tempEle.prop("checked")) {
        // //console.log(this.checkAlldata,"选中");
        this.checkAlldata.push(value);
        //console.log(this.checkAlldata, "选中");
      } else {
        var temindex = this.checkAlldata.indexOf(value);
        //console.log(temindex);
        for (var i = 0; i < this.checkAlldata.length; i++) {
          if (this.checkAlldata[i] != value) {
            // 数据存起来
            this.checkTem.push(this.checkAlldata[i])
          }
        }
        this.checkAlldata = this.checkTem;
        //console.log(this.checkAlldata, "未选中");
        this.checkTem = [];
      }
    } else {
      if (tempEle.prop("checked")) {
        // //console.log(this.checkAlldata,"选中");
        this.checkAlldata.push(value);
        //console.log(this.checkAlldata, "选中未");
      } else {
        var temindex = this.checkAlldata.indexOf(value);
        //console.log(temindex);
        for (var i = 0; i < this.checkAlldata.length; i++) {
          if (this.checkAlldata[i] != value) {
            // 数据存起来
            this.checkTem.push(this.checkAlldata[i])
          }
        }
        this.checkAlldata = this.checkTem;
        //console.log(this.checkAlldata, "未选中未");
        this.checkTem = [];
      }
    };
    if (this.checkAlldata.length < 8) {
      //console.log("退出")
      $(".checktop").prop("checked", false);
    } else {
      //console.log("全选")
      $(".checktop").prop("checked", true);
    }
  }
  // 删除操作
  clickDele() {

    if (this.checkAlldata.length > 0) {
      this.useDelestatus = true;
    } else {
      this.detailPastatus = false;
      this.detailRefusestatus = false;
      this.useWrongtatus = true;
      this.resmsg = "数据不能为空, 请选择想要操作的数据 !";
      setTimeout(() => {
        this.useWrongtatus = false;
      }, 3000)
    };
  }
  // 点击取消
  clickN() {
    this.useDelestatus = false;
  }
  // 点击确认删除
  clickDelesure() {
    this.useDelestatus = false;
    //console.log(this.checkAlldata);
    if (this.checkAlldata.length > 0) {
      // 说明是全选
      this.http.post(this.NavService.domain + '/rc/rcusers/deleteUsers', {
        token: localStorage.getItem("token"),
        jsessionid: localStorage.getItem("jsessionid"),
        ids: this.checkAlldata
      }).map(res => (res.json())).subscribe(
        res => {
          //console.log(res);
          if (res.code == 200) {
            // 说明删除成功 重新渲染数据
            this.checkAlldata = [];
            this.checkTem = [];
            this.AllNum = [];
            this.currpage = 1;//当前的页数;
            this.lengs = 0;
            this.clickAll();
          } else {
            this.useWrongtatus = true;
            this.resmsg = res.message;
            setTimeout(() => {
              this.useWrongtatus = false;
            }, 3000)
          }
        },
        err => {
          this.useWrongtatus = true;
          this.resmsg = err.message;
          setTimeout(() => {
            this.useWrongtatus = false;
          }, 3000)
        });
    };
  }
  // 普通用户
  clickNo() {
    this.no_ability = true;
    this.all_status = false;
    this.detail_status = false;
  }
  // 管理员权限展示已经关联的用户
  clickAll() {
    this.title = "";
    this.checkAlldata = [];
    this.checkDetail = [];
    this.delingData = []; //清空数组
    this.AllNum = [];
    this.currpage = 1;//当前的页数;
    this.lengs = 0;
    this.no_ability = false;
    this.all_status = true;
    this.detail_status = false;
    this.http.post(this.NavService.domain + '/rc/rcusers/selectPassedUser', {
      token: localStorage.getItem("token"),
      jsessionid: localStorage.getItem("jsessionid"),
      size: 8,
      current: 1
    }).map(res => (res.json())).subscribe(
      res => {
        if (res.code == 200) {
          // 数据成功
          this.userNum = res.data.rcuserPage.total;
          this.nocheck = res.data.rcunpassCount;
          if (res.data.rcuserPage.records.length != 0) {
            this.title = "";
            this.allUserData = res.data.rcuserPage.records;
            //console.log(res.data.rcuserPage.pages)
            var arr = new Array(res.data.rcuserPage.pages);
            //console.log(arr)
            for (var i = 0; i < arr.length; i++) {
              //console.log(i)
              this.AllNum.push(i + 1 + "");
            };
            var len = res.data.rcuserPage.pages;
            this.lengs = len;
            $(".page-move").css({ "left": "0", "width": len * 38 + "px" });
            if (len > 10) {
              $(".page-box").css({ "width": 10 * 38.5 + "px" });
              $(".page").css({ "width": (10 * 38.5 + 114) + "px" });
            } else {
              $(".page-box").css({ "width": len * 38.5 + "px" });
              $(".page").css({ "width": (len * 38.5 + 114) + "px" });
            };
          } else {
            this.title = "暂无数据";
          }
        } else {
          this.useWrongtatus = true;
          this.resmsg = res.message;
          setTimeout(() => {
            this.useWrongtatus = false;
          }, 3000);
        }
      },
      err => {
        //console.log(err)
        this.useWrongtatus = true;
        this.resmsg = "请求异常,请稍后再试";
        setTimeout(() => {
          this.useWrongtatus = false;
        }, 3000);
      }
    )
  }
  // 审核分页
  pageclick(value: number, event, leng: number) {
    // 初始化关于选择的数据
    this.choseFlag = false;
    this.checkAlldata = [];
    $(".checktop").prop("checked", false);
    /////////////////
    this.lengs = leng;
    this.currpage = value;
    $(".prepage").css({ "cursor": "pointer", "color": "#006ddc" });
    $(".nextpage").css({ "cursor": "pointer", "color": "#006ddc" });
    $(".page-move li").css({ "background-color": "#fff", "color": "#333" });
    $(event.target).css({ "background-color": "#2179FF", "color": "#fff" });
    if (value >= 5 && value < leng - 2) {
      $(".page-move").animate({ "left": "-" + (value - 1) * 35 + "px" }, 100);
      //$(selector).animate(styles,speed,easing,callback)
    }
    if (value < 5) {
      $(".page-move").animate({ "left": "0" }, 20);
    }
    if (leng - value < 7) {
      $(".page-move").animate({ "left": "-" + (leng - 7) * 33.25 + "px", "right": "0px" }, 20);
    }
    this.http.post(this.NavService.domain_hh + '/rc/rcusers/selectPassedUser', {
      token: localStorage.getItem("token"),
      jsessionid: localStorage.getItem("jsessionid"),
      current: Number(value),
      size: 8
    })
      .map(res => res.json())
      .subscribe(
        res => {
          //console.log(res);
          this.allUserData = res.data.rcuserPage.records;

        },
        error => {
          this.useWrongtatus = true;
          this.resmsg = "数据异常,请稍后重试!";
          setTimeout(() => {
            this.useWrongtatus = false;
          }, 3000);
        }
      );
  }
  //上一页
  pagepre() {
    // 初始化关于选择的数据
    this.choseFlag = false;
    this.checkAlldata = [];
    $(".checktop").prop("checked", false);
    /////////////////////
    //this.currpage   这里是记录上次的点击要是超过了总的长度就提示是最后一页了
    $(".nextpage").css({ "cursor": "pointer", "color": "#006ddc" });
    if (this.currpage == 1) {
      $(".page-move li").css({ "background-color": "#fff", "color": "#333" });
      $(".p" + this.currpage).css({ "background-color": "#2179FF", "color": "#fff" });
      $(".prepage").css({ "cursor": "not-allowed", "color": "#333" });
      //////console.log("0");
    } else {
      this.currpage = this.currpage - 1;
      $(".page-move li").css({ "background-color": "#fff", "color": "#333" });
      $(".p" + this.currpage).css({ "background-color": "#2179FF", "color": "#fff" });
      if (this.currpage >= 5 && this.currpage < this.lengs - 3) {
        $(".page-move").animate({ "left": "-" + (this.currpage - 1) * 35 + "px" }, 100);
        //$(selector).animate(styles,speed,easing,callback)
      };
      if (this.currpage < 5) {
        $(".page-move").animate({ "left": "0" }, 20);
      };
      if (this.lengs - this.currpage < 2) {
        $(".page-move").animate({ "left": "-" + (this.lengs - 7) * 35.25 + "px", "right": "0px" }, 20);
      };
      this.http.post(this.NavService.domain_hh + '/rc/rcusers/selectPassedUser', {
        token: localStorage.getItem("token"),
        jsessionid: localStorage.getItem("jsessionid"),
        current: Number(this.currpage),
        size: 8
      })
        .map(res => res.json())
        .subscribe(
          res => {
            this.allUserData = res.data.rcuserPage.records;
          },
          error => {
            this.useWrongtatus = true;
            this.resmsg = "数据异常,请稍后重试!";
            setTimeout(() => {
              this.useWrongtatus = false;
            }, 3000);
          }
        );
    }
  }
  //下一页
  pagenext() {
    // 初始化关于选择的数据
    this.choseFlag = false;
    this.checkAlldata = [];
    $(".checktop").prop("checked", false);
    //////////////
    //this.currpage   这里是记录上次的点击要是超过了总的长度就提示是最后一页了
    $(".prepage").css({ "cursor": "pointer", "color": "#006ddc" });
    this.currpage = Number(this.currpage) + 1;
    $(".page-move li").css({ "background-color": "#fff", "color": "#333" });
    $(".p" + this.currpage).css({ "background-color": "#2179FF", "color": "#fff" });
    if (this.currpage - this.lengs >= 0) {
      this.currpage = this.lengs;
      $(".p" + this.currpage).css({ "background-color": "#2179FF", "color": "#fff" });
      $(".nextpage").css({ "cursor": "not-allowed", "color": "#333" })
    };
    if (this.currpage >= 5 && this.currpage < this.lengs - 5) {
      $(".page-move").animate({ "left": "-" + (this.currpage - 1) * 35 + "px" }, 100);
    };
    if (this.currpage < 5) {
      $(".page-move").animate({ "left": "0" }, 20);
    };
    //////console.log(typeof this.currpage);
    if (this.lengs - this.currpage < 7) {
      //////console.log("daotou")
      $(".page-move").animate({ "left": "-" + (this.lengs - 7) * 33.25 + "px", "right": "0px" }, 20);
    };
    this.http.post(this.NavService.domain_hh + '/rc/rcusers/selectPassedUser', {
      token: localStorage.getItem("token"),
      jsessionid: localStorage.getItem("jsessionid"),
      current: Number(this.currpage),
      size: 8

    })
      .map(res => res.json())
      .subscribe(
        res => {
          this.allUserData = res.data.rcuserPage.records;

        },
        error => {
          this.useWrongtatus = true;
          this.resmsg = "数据异常,请稍后重试!";
          setTimeout(() => {
            this.useWrongtatus = false;
          }, 3000);
        }
      );
  }
  // 审核的细节
  // 0：未审核；1：已审核；2：已拒绝；5：全部
  clickDetail() {
    this.checkAlldata = [];
    this.checkDetail = [];
    this.delingData = []; //清空数组
    this.title = '';
    this.AllNum = [];
    this.currpage = 1;//当前的页数;
    this.lengs = 0;
    this.no_ability = false;
    this.all_status = false;
    this.detail_status = true;
    this.cStatus = 5;
    this.http.post(this.NavService.domain + '/rc/rcusers/selectUserByKeywords', {
      token: localStorage.getItem("token"),
      jsessionid: localStorage.getItem("jsessionid"),
      size: 8,
      current: 1,
      keywords: "",
      pstatus: 5
    }).map(res => (res.json())).subscribe(
      res => {
        //console.log(res);
        if (res.code == 200) {
          if (res.data.records.length <= 0) {
            this.title = "暂无数据";
          } else {
            this.title = "";
          }
          this.checkDetail = res.data.records;
          var arr = new Array(res.data.pages);
          //console.log(arr)
          for (var i = 0; i < arr.length; i++) {
            //console.log(i);
            this.AllNum.push(i + 1 + "");
          };
          var len = res.data.pages;
          this.lengs = len;
          $(".page-move").css({ "left": "0", "width": len * 38 + "px" });
          if (len > 10) {
            $(".page-box").css({ "width": 10 * 38.5 + "px" });
            $(".page").css({ "width": (10 * 38.5 + 114) + "px" });
          } else {
            $(".page-box").css({ "width": len * 38.5 + "px" });
            $(".page").css({ "width": (len * 38.5 + 114) + "px" });
          };
        } else {
          this.title = "暂无数据";
          this.useWrongtatus = true;
          this.resmsg = res.message;
          setTimeout(() => {
            this.useWrongtatus = false;
          }, 3000);
        }
      },
      err => {
        this.useWrongtatus = true;
        this.resmsg = "数据异常,请稍后重试!";
        setTimeout(() => {
          this.useWrongtatus = false;
        }, 3000);
      });
  }
  // 未审核
  clickNoc() {
    this.checkDetail = [];
    this.title = '';
    this.AllNum = [];
    this.currpage = 1;//当前的页数;
    this.lengs = 0;
    this.cStatus = 0;
    this.http.post(this.NavService.domain + '/rc/rcusers/selectUserByKeywords', {
      token: localStorage.getItem("token"),
      jsessionid: localStorage.getItem("jsessionid"),
      size: 8,
      current: 1,
      keywords: "",
      pstatus: 0
    }).map(res => (res.json())).subscribe(
      res => {
        //console.log(res);
        this.checkDetail = res.data.records;
        if (this.checkDetail.length <= 0) {
          this.title = "暂无数据"
        }
        var arr = new Array(res.data.pages);
        //console.log(arr)
        for (var i = 0; i < arr.length; i++) {
          //console.log(i)
          this.AllNum.push(i + 1 + "");
        };
        var len = res.data.pages;
        this.lengs = len;
        $(".page-move").css({ "left": "0", "width": len * 38 + "px" });
        if (len > 10) {
          $(".page-box").css({ "width": 10 * 38.5 + "px" });
          $(".page").css({ "width": (10 * 38.5 + 114) + "px" });
        } else {
          $(".page-box").css({ "width": len * 38.5 + "px" });
          $(".page").css({ "width": (len * 38.5 + 114) + "px" });
        };
      },
      err => {
        this.useWrongtatus = true;
        this.resmsg = "数据异常,请稍后重试!";
        setTimeout(() => {
          this.useWrongtatus = false;
        }, 3000);
      });
  }
  // 已审核
  clickAlready() {
    this.checkDetail = [];
    this.title = '';
    this.AllNum = [];
    this.currpage = 1;//当前的页数;
    this.lengs = 0;
    this.cStatus = 1;
    this.http.post(this.NavService.domain + '/rc/rcusers/selectUserByKeywords', {
      token: localStorage.getItem("token"),
      jsessionid: localStorage.getItem("jsessionid"),
      size: 8,
      current: 1,
      keywords: "",
      pstatus: 1
    }).map(res => (res.json())).subscribe(
      res => {
        //console.log(res);
        this.checkDetail = res.data.records;
        if (this.checkDetail.length <= 0) {
          this.title = "暂无数据";

        }
        var arr = new Array(res.data.pages);
        //console.log(arr)
        for (var i = 0; i < arr.length; i++) {
          //console.log(i)
          this.AllNum.push(i + 1 + "");
        };
        var len = res.data.pages;
        this.lengs = len;
        $(".page-move").css({ "left": "0", "width": len * 38 + "px" });
        if (len > 10) {
          $(".page-box").css({ "width": 10 * 38.5 + "px" });
          $(".page").css({ "width": (10 * 38.5 + 114) + "px" });
        } else {
          $(".page-box").css({ "width": len * 38.5 + "px" });
          $(".page").css({ "width": (len * 38.5 + 114) + "px" });
        };
      },
      err => {
        this.useWrongtatus = true;
        this.resmsg = "数据异常,请稍后重试!";
        setTimeout(() => {
          this.useWrongtatus = false;
        }, 3000);
      });
  }
  // 已拒绝
  clickRefused() {
    this.checkDetail = [];
    this.title = '';
    this.AllNum = [];
    this.currpage = 1;//当前的页数;
    this.lengs = 0;
    this.cStatus = 2;
    this.http.post(this.NavService.domain + '/rc/rcusers/selectUserByKeywords', {
      token: localStorage.getItem("token"),
      jsessionid: localStorage.getItem("jsessionid"),
      size: 8,
      current: 1,
      keywords: "",
      pstatus: 2
    }).map(res => (res.json())).subscribe(
      res => {
        //console.log(res);
        this.checkDetail = res.data.records;
        if (this.checkDetail.length <= 0) {
          this.title = "暂无数据";
          this.checkDetail = [];
        }
        var arr = new Array(res.data.pages);
        //console.log(arr)
        for (var i = 0; i < arr.length; i++) {
          //console.log(i)
          this.AllNum.push(i + 1 + "");
        };
        var len = res.data.pages;
        this.lengs = len;
        $(".page-move").css({ "left": "0", "width": len * 38 + "px" });
        if (len > 10) {
          $(".page-box").css({ "width": 10 * 38.5 + "px" });
          $(".page").css({ "width": (10 * 38.5 + 114) + "px" });
        } else {
          $(".page-box").css({ "width": len * 38.5 + "px" });
          $(".page").css({ "width": (len * 38.5 + 114) + "px" });
        };
      },
      err => {
        this.useWrongtatus = true;
        this.resmsg = "数据异常,请稍后重试!";
        setTimeout(() => {
          this.useWrongtatus = false;
        }, 3000);
      });
  }
  // 搜索
  userSea(value: string) {
    //console.log(!value);
    if (value.length != 0) {
      this.serdata = value;
    }
    this.checkDetail = [];
    this.title = '';
    this.AllNum = [];
    this.currpage = 1;//当前的页数;
    this.lengs = 0;
    this.cStatus = 5;
    this.http.post(this.NavService.domain + '/rc/rcusers/selectUserByKeywords', {
      token: localStorage.getItem("token"),
      jsessionid: localStorage.getItem("jsessionid"),
      size: 8,
      current: 1,
      keywords: this.serdata,
      pstatus: this.cStatus
    }).map(res => (res.json())).subscribe(
      res => {
        //console.log(res);
        this.serdata = '无';
        this.checkDetail = res.data.records;
        if (this.checkDetail.length <= 0) {
          this.title = "暂无数据";
          this.checkDetail = [];
        }
        var arr = new Array(res.data.pages);
        //console.log(arr)
        for (var i = 0; i < arr.length; i++) {
          //console.log(i)
          this.AllNum.push(i + 1 + "");
        };
        var len = res.data.pages;
        this.lengs = len;
        $(".page-move").css({ "left": "0", "width": len * 38 + "px" });
        if (len > 10) {
          $(".page-box").css({ "width": 10 * 38.5 + "px" });
          $(".page").css({ "width": (10 * 38.5 + 114) + "px" });
        } else {
          $(".page-box").css({ "width": len * 38.5 + "px" });
          $(".page").css({ "width": (len * 38.5 + 114) + "px" });
        };
      },
      err => {
        this.serdata = '无';
        this.useWrongtatus = true;
        this.resmsg = "数据异常,请稍后重试!";
        setTimeout(() => {
          this.useWrongtatus = false;
        }, 3000);
      });
  }
  // 单个点击审核细节获取数据
  checkClickdetail(e, value: Number, ind) {
    this.delingTem = [];
    var tempEle = $(e.target);
    //console.log(tempEle.prop("checked"))
    if (tempEle.prop("checked")) {
      // //console.log(this.checkAlldata,"选中");
      this.delingData.push(value);
      //console.log(this.delingData, "选中未");
    } else {
      for (var i = 0; i < this.delingData.length; i++) {
        if (this.delingData[i] != value) {
          // 数据存起来
          this.delingTem.push(this.delingData[i])
        }
      }
      this.delingData = this.delingTem;
      //console.log(this.delingData, "未选中未");
      this.delingTem = [];
    }
  }
  // 取消
  clickdN() {
    this.detailPastatus = false;
    this.detailRefusestatus = false;
    this.clickSinStatus = false;
    this.clickSinRefusestatus = false;
  }
  // 批量通过通过按钮
  clickPast() {
    if (this.delingData.length > 0) {
      this.detailPastatus = true;
      this.detailRefusestatus = false;
    } else {
      this.detailPastatus = false;
      this.detailRefusestatus = false;
      this.useWrongtatus = true;
      this.resmsg = "数据不能为空, 请选择想要操作的数据 !";
      setTimeout(() => {
        this.useWrongtatus = false;
      }, 3000)
    };
  }
  // 批量拒绝按钮
  clickRefu() {
    if (this.delingData.length > 0) {
      this.detailPastatus = false;
      this.detailRefusestatus = true;
    } else {
      this.detailPastatus = false;
      this.detailRefusestatus = false;
      this.useWrongtatus = true;
      this.resmsg = "数据不能为空, 请选择想要操作的数据 !";
      setTimeout(() => {
        this.useWrongtatus = false;
      }, 3000)
    };
  }
  clickSinPast(value) {
    //console.log(value)
    this.clickTong = [];
    this.clickTong.push(value);
    this.clickSinStatus = true;
    this.clickSinRefusestatus = false;
  }
  clickSinRefu(value) {
    //console.log(value)
    this.clickTong = [];
    this.clickTong.push(value);
    this.clickSinStatus = false;
    this.clickSinRefusestatus = true;
  }
  // 批量确认 通过
  clickPastsure() {
    if (this.delingData.length > 0) {
      // 发送请求
      this.http.post(this.NavService.domain_hh + '/rc/rcusers/updateUsers', {
        token: localStorage.getItem("token"),
        jsessionid: localStorage.getItem("jsessionid"),
        ids: this.delingData,
        pstatus: 1
      })
        .map(res => res.json())
        .subscribe(
          res => {
            //console.log(res);
            this.delingData = []; //清空数组
            this.clickDetail();
            this.detailPastatus = false;
            this.detailRefusestatus = false;
            if (res.code == 200) {
              // this.clickDetail();
              this.useWrongtatus = true;
              this.resmsg = res.message;
              setTimeout(() => {
                this.useWrongtatus = false;
              }, 3000)
            } else {
              this.useWrongtatus = true;
              this.resmsg = res.message;
              setTimeout(() => {
                this.useWrongtatus = false;
              }, 3000)
            }
          },
          err => {
            this.clickDetail();
            this.delingData = []; //清空数组
            this.detailPastatus = false;
            this.detailRefusestatus = false;
            this.useWrongtatus = true;
            this.resmsg = "请求异常,请稍后再试";
            setTimeout(() => {
              this.useWrongtatus = false;
            }, 3000)
          }
        );
    } else {
      this.delingData = []; //清空数组
      this.detailPastatus = false;
      this.detailRefusestatus = false;
      this.useWrongtatus = true;
      this.resmsg = "数据不能为空, 请选择想要操作的数据 !";
      setTimeout(() => {
        this.useWrongtatus = false;
      }, 3000);
    }
  }
  // 批量确认 拒绝
  clickRefusure() {
    if (this.delingData.length > 0) {
      // 发送请求
      this.http.post(this.NavService.domain_hh + '/rc/rcusers/updateUsers', {
        token: localStorage.getItem("token"),
        jsessionid: localStorage.getItem("jsessionid"),
        ids: this.delingData,
        pstatus: 2
      })
        .map(res => res.json())
        .subscribe(
          res => {
            this.clickDetail();
            this.delingData = []; //清空数组
            this.detailPastatus = false;
            this.detailRefusestatus = false;
            if (res.code == 200) {
              // this.clickDetail();
              this.useWrongtatus = true;
              this.resmsg = res.message;
              setTimeout(() => {
                this.useWrongtatus = false;
              }, 3000)
            } else {
              this.useWrongtatus = true;
              this.resmsg = res.message;
              setTimeout(() => {
                this.useWrongtatus = false;
              }, 3000)
            }
          },
          err => {
            this.clickDetail();
            this.delingData = []; //清空数组
            this.detailPastatus = false;
            this.detailRefusestatus = false;
            this.useWrongtatus = true;
            this.resmsg = "请求异常,请稍后再试";;
            setTimeout(() => {
              this.useWrongtatus = false;
            }, 3000)
          }
        );
    } else {
      this.delingData = []; //清空数组
      this.detailPastatus = false;
      this.detailRefusestatus = false;
      this.useWrongtatus = true;
      this.resmsg = "数据不能为空, 请选择想要操作的数据 !";
      setTimeout(() => {
        this.useWrongtatus = false;
      }, 3000)
    }
  }
  // 单个拒绝
  clickSinRefuSure() {
    // 发送请求
    this.delingData = []; //清空数组
    this.http.post(this.NavService.domain_hh + '/rc/rcusers/updateUsers', {
      token: localStorage.getItem("token"),
      jsessionid: localStorage.getItem("jsessionid"),
      ids: this.clickTong,
      pstatus: 2
    })
      .map(res => res.json())
      .subscribe(
        res => {
          //console.log(res);
          this.clickTong = [];
          this.clickDetail();
          this.clickSinStatus = false;
          this.clickSinRefusestatus = false;
          if (res.code == 200) {

            this.useWrongtatus = true;
            this.resmsg = res.message;
            setTimeout(() => {
              this.useWrongtatus = false;
            }, 3000)
          } else {
            this.useWrongtatus = true;
            this.resmsg = res.message;
            setTimeout(() => {
              this.useWrongtatus = false;
            }, 3000)
          }
        },
        err => {
          this.clickTong = [];
          this.clickDetail();
          this.clickSinStatus = false;
          this.clickSinRefusestatus = false;
          this.useWrongtatus = true;
          this.resmsg = "请求异常,请稍后再试";
          setTimeout(() => {
            this.useWrongtatus = false;
          }, 3000)
        }
      );
  }
  // 单个通过
  clickSinPastSure() {
    // 发送请求
    this.delingData = []; //清空数组
    this.http.post(this.NavService.domain_hh + '/rc/rcusers/updateUsers', {
      token: localStorage.getItem("token"),
      jsessionid: localStorage.getItem("jsessionid"),
      ids: this.clickTong,
      pstatus: 1
    })
      .map(res => res.json())
      .subscribe(
        res => {
          //console.log(res);
          this.clickTong = [];
          this.clickDetail();
          this.clickSinStatus = false;
          this.clickSinRefusestatus = false;
          if (res.code == 200) {
            // this.clickDetail();
            this.useWrongtatus = true;
            this.resmsg = res.message;
            setTimeout(() => {
              this.useWrongtatus = false;
            }, 3000)
          } else {
            this.useWrongtatus = true;
            this.resmsg = res.message;
            setTimeout(() => {
              this.useWrongtatus = false;
            }, 3000)
          }
        },
        err => {
          this.clickTong = [];
          this.clickDetail();
          this.clickSinStatus = false;
          this.clickSinRefusestatus = false;
          this.useWrongtatus = true;
          this.resmsg = "请求异常,请稍后再试";
          setTimeout(() => {
            this.useWrongtatus = false;
          }, 3000)
        }
      );
  }
  // 细节分页
  pageclicks(value: number, event, leng: number) {
    // 初始化关于选择的数据
    this.delingData = [];
    $(".checktop").prop("checked", false);
    /////////////////
    this.lengs = leng;
    this.currpage = value;
    $(".prepage").css({ "cursor": "pointer", "color": "#006ddc" });
    $(".nextpage").css({ "cursor": "pointer", "color": "#006ddc" });
    $(".page-move li").css({ "background-color": "#fff", "color": "#333" });
    $(event.target).css({ "background-color": "#2179FF", "color": "#fff" });
    if (value >= 5 && value < leng - 2) {
      $(".page-move").animate({ "left": "-" + (value - 1) * 35 + "px" }, 100);
      //$(selector).animate(styles,speed,easing,callback)
    }
    if (value < 5) {
      $(".page-move").animate({ "left": "0" }, 20);
    }
    if (leng - value < 7) {
      $(".page-move").animate({ "left": "-" + (leng - 7) * 33.25 + "px", "right": "0px" }, 20);
    }
    this.http.post(this.NavService.domain_hh + '/rc/rcusers/selectUserByKeywords', {
      token: localStorage.getItem("token"),
      jsessionid: localStorage.getItem("jsessionid"),
      size: 8,
      current: value,
      keywords: "",
      pstatus: this.cStatus
    })
      .map(res => res.json())
      .subscribe(
        res => {
          //console.log(res);
          this.checkDetail = res.data.records;

        },
        error => {
          this.useWrongtatus = true;
          this.resmsg = "数据异常,请稍后重试!";
          setTimeout(() => {
            this.useWrongtatus = false;
          }, 3000);
        }
      );
  }
  //上一页
  pagepres() {
    // 初始化关于选择的数据
    this.delingData = [];
    $(".checktop").prop("checked", false);
    /////////////////////
    //this.currpage   这里是记录上次的点击要是超过了总的长度就提示是最后一页了
    $(".nextpage").css({ "cursor": "pointer", "color": "#006ddc" });
    if (this.currpage == 1) {
      $(".page-move li").css({ "background-color": "#fff", "color": "#333" });
      $(".p" + this.currpage).css({ "background-color": "#2179FF", "color": "#fff" });
      $(".prepage").css({ "cursor": "not-allowed", "color": "#333" });
      //////console.log("0");
    } else {
      this.currpage = this.currpage - 1;
      $(".page-move li").css({ "background-color": "#fff", "color": "#333" });
      $(".p" + this.currpage).css({ "background-color": "#2179FF", "color": "#fff" });
      if (this.currpage >= 5 && this.currpage < this.lengs - 3) {
        $(".page-move").animate({ "left": "-" + (this.currpage - 1) * 35 + "px" }, 100);
        //$(selector).animate(styles,speed,easing,callback)
      };
      if (this.currpage < 5) {
        $(".page-move").animate({ "left": "0" }, 20);
      };
      if (this.lengs - this.currpage < 2) {
        $(".page-move").animate({ "left": "-" + (this.lengs - 7) * 35.25 + "px", "right": "0px" }, 20);
      };
      this.http.post(this.NavService.domain_hh + '/rc/rcusers/selectUserByKeywords', {
        token: localStorage.getItem("token"),
        jsessionid: localStorage.getItem("jsessionid"),
        size: 8,
        current: this.currpage,
        keywords: "",
        pstatus: this.cStatus
      })
        .map(res => res.json())
        .subscribe(
          res => {
            this.checkDetail = res.data.records;
          },
          error => {
            this.useWrongtatus = true;
            this.resmsg = "数据异常,请稍后重试!";
            setTimeout(() => {
              this.useWrongtatus = false;
            }, 3000);
          }
        );
    }
  }
  //下一页
  pagenexts() {
    // 初始化关于选择的数据
    this.delingData = [];
    $(".checktop").prop("checked", false);
    //////////////
    //this.currpage   这里是记录上次的点击要是超过了总的长度就提示是最后一页了
    $(".prepage").css({ "cursor": "pointer", "color": "#006ddc" });
    this.currpage = Number(this.currpage) + 1;
    $(".page-move li").css({ "background-color": "#fff", "color": "#333" });
    $(".p" + this.currpage).css({ "background-color": "#2179FF", "color": "#fff" });
    if (this.currpage - this.lengs >= 0) {
      this.currpage = this.lengs;
      $(".p" + this.currpage).css({ "background-color": "#2179FF", "color": "#fff" });
      $(".nextpage").css({ "cursor": "not-allowed", "color": "#333" })
    };
    if (this.currpage >= 5 && this.currpage < this.lengs - 5) {
      $(".page-move").animate({ "left": "-" + (this.currpage - 1) * 35 + "px" }, 100);
    };
    if (this.currpage < 5) {
      $(".page-move").animate({ "left": "0" }, 20);
    };
    //////console.log(typeof this.currpage);
    if (this.lengs - this.currpage < 7) {
      //////console.log("daotou")
      $(".page-move").animate({ "left": "-" + (this.lengs - 7) * 33.25 + "px", "right": "0px" }, 20);
    };
    this.http.post(this.NavService.domain_hh + '/rc/rcusers/selectUserByKeywords', {
      token: localStorage.getItem("token"),
      jsessionid: localStorage.getItem("jsessionid"),
      size: 8,
      current: this.currpage,
      keywords: "",
      pstatus: this.cStatus

    })
      .map(res => res.json())
      .subscribe(
        res => {
          this.checkDetail = res.data.records;

        },
        error => {
          this.useWrongtatus = true;
          this.resmsg = "数据异常,请稍后重试!";
          setTimeout(() => {
            this.useWrongtatus = false;
          }, 3000);
        }
      );
  }
}