/**
 * Created by YingJieCHAI on 2018/1/11.
 */
import { Injectable,OnInit } from '@angular/core';
import { Subject }    from 'rxjs/Subject';
import { Type } from '@angular/core';

@Injectable()
export class NavService {
  domain_xl = '/portal';
  domain_hh = '/portal';
  domain = '/portal';
  //配置跳转链接
  //skiplink_cart ="http://www.51ean.net/cart";
  //skiplink_sim ="http://www.51ean.net/personal-center";
  //skiplink_order ="http://www.51ean.net/personal-center#3";
  skip_center = "";
  skiplink_cart ="/cart";
  skiplink_sim ="/personal-center/mexam"; 
  skiplink_sims ="/personal-center/morder/simple/"; //单个订单的详情
  // skiplink_just ="/personal-center/morder/order/"; //单个订单的详情
  skiplink_order ="/personal-center/morder";
  skiplink_news = "/personal-center/mnews"
  // 在线考试地址
  examUrl = "https://exam.51ean.com/index.html?id=";
  // examUrl = "http://192.168.3.54:12002/index.html?id=";
  // examUrl = "http://www.51ean.net:12002/index.html?id=";
  // 调查问卷
  // surveyUrl = "http://192.168.3.54:9190/portal/questionnaire/toMobileList?id="; //wap端列表入口
  // surveyUrl = "http://192.168.3.54:9901/portal/questionnaire/toPCList?id=" // pc端列表入口
  // surveyUrl = "http://www.51ean.net:12004/portal/questionnaire/toMobileList?id=" // wap端预生产地址
  // surveyUrl = "http://www.51ean.net:12004/portal/questionnaire/toPCList?id="  //pc端预生产地址
 surveyUrl = "https://question.51ean.com/portal/questionnaire/toPCList?id="

//  单个调查问卷详情入口
surveyDetail = "http://192.168.3.54:9190/portal/toMobilePage?questionnaireId="
  //课程库的传值
  cousesSearch = "网络安全 hadoop 运维";
  personFlag = false; //控制个人中心的显示
  carted = false; //购物车的显示
  newsed = false;
  unlogin = true; //未登录时显示登录入口
  signFlag = true; //未登录时显示注册的按钮
  is = false;
  isFind = false;
  // Observable string sources
  private missionAnnouncedSource = new Subject<boolean>();
  private missionConfirmedSource = new Subject<boolean>();
  private isNav:boolean;

  // Observable string streams
  missionAnnounced$ = this.missionAnnouncedSource.asObservable();
  missionConfirmed$ = this.missionConfirmedSource.asObservable();

  ngOnInit(){
    this.is = false;
    this.personFlag = false; //控制个人中心的显示
    this.carted = false; //购物车的显示
    this.newsed = false;
    this.unlogin =true; //未登录时显示登录入口
    this.signFlag = true; //未登录时显示注册的按钮
    this.is = false;//隐藏登录窗口
  }
  // Service message commands
  //根组件的调用的方法
  //点击登录之后执行的方法 让登录对话框显示
  logining(){
    this.is = true;
    this.isFind = false;
  }
  //点击关闭登录对话框执行的方法  使对话框隐藏
  hiddened(){
    this.is = false;
    this.isFind = false;
  }
  //找回密码执行的方法 显示找回密码的对话框
  findpass(){
    this.is = false;
    this.isFind = true;
  }
  loadflagchange(){
    this.personFlag = true; //控制个人中心的显示
    this.carted = true; //购物车的显示
    this.newsed = true;
    this.unlogin =false; //未登录时显示登录入口
    this.signFlag = false; //未登录时显示注册的按钮
    this.is = false;//隐藏登录窗口
  }
  noshow(){}
}
