import { Pipe, PipeTransform } from '@angular/core';
declare var jQuery:any;  //定义jquery
declare var  $:any;  //定义jquery
@Pipe({name: 'haveDiv'})

export class HavePipe implements PipeTransform {
    transform(value: string) {
        $(".model-box").scrollBar();
  }
    constructor() {
    }
} 