/**
 * Created by YingJieCHAI on 2018/3/23.
 */
import {
  Component,
  Directive,
  ViewChild,
  AfterViewInit,
  ElementRef,
  Renderer,
} from "@angular/core";
import { NavService } from "./nav.service";
import {
  Router,
  NavigationEnd,
  ActivatedRoute,
  RouterState,
  Params,
  ParamMap,
} from "@angular/router";
import { Title } from "@angular/platform-browser";
import { HttpClient } from "@angular/common/http";
import { HttpHeaders, HttpRequest } from "@angular/common/http";
import { Http, RequestOptions, Headers } from "@angular/http";
import "rxjs/add/operator/map"; //调用映射的响应可观察到得到解析的人对象。
declare var $: any;
declare var CryptoJS: any;
@Component({
  selector: "nav",
  templateUrl: "./load.component.html",
  styleUrls: ["./common.component.css", "./load.component.css"],
  providers: [NavService],
})
export class loadingComponent {
  constructor(
    private http: Http,
    private NavService: NavService,
    private route: ActivatedRoute,
    private renderer: Renderer
  ) // route.data includes both `data` and `resolve`

  {}
  //初始化数据
  leng; //手机号的状态
  name = "";
  tele = "";
  teleword; //手机验证码
  verify = "";
  passWord = "";
  isPassword = "";
  tit = "";
  show;
  src;
  qq;
  verifycode; //获取验证码按钮存在的状态
  times = 60; //时间
  remcon; //提示信息
  justsign; //立即注册按钮显示的状态
  unsign; //不能注册
  //初始化数据
  remessage = ""; //提示信息
  remstatus = false;
  picuser = ""; //用户头像
  usernameshow = ""; //用户名
  telename = "";
  yuedata; //余额
  personFlag = false; //控制个人中心的显示
  carted = false; //购物车的显示
  newsed = false; //控制消息
  unlogin = true; //未登录时显示登录入口
  signFlag = true; //未登录时显示注册的按钮
  is = false;
  isFind = false;
  isremember = "0";
  userName;
  pass;
  //个人中心hover
  ppshow = false;
  MenuData: Object;
  // 控制回车登录的状态
  nameStatus = false;
  passStatus = false;
  // 消息的数量
  newsNum = 0;
  numberStatus = false;
  //zheshidenglu
  ngOnInit() {
    /*
     * 1.在渲染登录的时候,首先要发一个接口去判断token的是否已经失效
     * 2.如果已经失效则需要重新登录,这个时候记录的账号名和密码都不存在了
     * 3.如果未失效则直接登录就好,这时候如果是记住密码就会有账号密码
     *
     * */
    if (!window.localStorage.getItem("pic")) {
      this.picuser = "";
    } else {
      this.picuser = window.localStorage.getItem("pic"); //用户头像
    }
    if (!window.localStorage.getItem("nickName")) {
      this.usernameshow = "";
    } else {
      this.picuser = window.localStorage.getItem("pic"); //用户头像
    }
    if (!window.localStorage.getItem("tele")) {
      this.telename = "";
    } else {
      this.telename = window.localStorage.getItem("tele");
    }
    this.src = this.NavService.domain + "/pub/verifi/imgCode";
    this.checktoken();
    this.verifycode = true;
    //console.log(this.picuser);
    //获取左侧菜单导航
    this.http
      .post(this.NavService.domain + "/pub/portalMenu/getAllMenu", {})
      .map((res) => res.json())
      .subscribe((res) => {
        this.MenuData = res.data.parentData;
        // console.log(this.MenuData);
      });
    // 获取消息数量
    this.newsnum();
    setInterval(() => {
      this.newsnum(); //15分钟请求一次最新未读消息数量;模拟实时更新
    }, 900000);
    //回车登录
    $(".passLoad,.accountLoad").keyup((event) => {
      // console.log(1);
      if (event.keyCode == 13) {
        this.userName = $(".accountLoad input").val();
        this.pass = $(".passLoad input").val();
        this.loaded();
      }
    });
  }
  newsnum() {
    //获取消息的数量
    this.http
      .post(this.NavService.domain + "/noticeCenter/getUnreadMsgTotal", {
        token: window.localStorage.getItem("token"),
        jsessionid: window.localStorage.getItem("jsessionid"),
      })
      .map((res) => res.json())
      .subscribe((res) => {
        // console.log(res);
        this.newsNum = res.data.unreadMsgTotal;
        // console.log(this.MenuData);
      });
  }
  newsclick() {
    // 跳转至我的消息
    window.location.href = this.NavService.skiplink_news;
  }
  //检测登录的状态
  checktoken() {
    //检验token
    if (!window.localStorage.getItem("token")) {
      //console.log("token存在")
      //console.log(!window.localStorage.getItem("token"));
      this.personFlag = false; //控制个人中心的显示
      this.carted = false; //购物车的显示
      this.newsed = false;
      this.unlogin = true; //未登录时显示登录入口
      this.signFlag = true; //未登录时显示注册的按钮
      this.is = false; //隐藏登录窗口
      //显示个人中心的内容
    } else {
      //验证token是否失效
      //console.log(!window.localStorage.getItem("token"));
      this.http
        .post(this.NavService.domain + "/user/getUserInfo", {
          token: window.localStorage.getItem("token"),
          jsessionid: window.localStorage.getItem("jsessionid"),
        })
        .map((res) => res.json())
        .subscribe(
          (res) => {
            //console.log(res+"检验token是否失效");
            if (res.code == 0) {
              this.personFlag = true; //控制个人中心的显示
              this.carted = true; //购物车的显示
              this.newsed = true;
              this.unlogin = false; //未登录时显示登录入口
              this.signFlag = false; //未登录时显示注册的按钮
              this.is = false; //隐藏登录窗口
              this.picuser = window.localStorage.getItem("pic"); //用户头像
              this.usernameshow = window.localStorage.getItem("nickName"); //用户名
              this.telename = window.localStorage.getItem("tele");
              if (!window.localStorage.getItem("pic")) {
                this.picuser = "";
              } else {
                this.picuser = window.localStorage.getItem("pic"); //用户头像
              }
              if (!window.localStorage.getItem("nickName")) {
                this.usernameshow = "";
              } else {
                this.picuser = window.localStorage.getItem("pic"); //用户头像
              }
              if (!window.localStorage.getItem("tele")) {
                this.telename = "";
              } else {
                this.telename = window.localStorage.getItem("tele");
              }
            } else {
              //用户验证失效
              this.personFlag = false; //控制个人中心的显示
              this.carted = false; //购物车的显示
              this.newsed = false;
              this.unlogin = true; //未登录时显示登录入口
              this.signFlag = true; //未登录时显示注册的按钮
              this.is = false; //隐藏登录窗口
            }
          },
          (error) => {
            this.is = false;
          }
        );
    }
  }
  //判断未失效的登录状态
  logined() {
    this.personFlag = true; //控制个人中心的显示
    this.carted = true; //购物车的显示
    this.newsed = true;
    this.unlogin = false; //未登录时显示登录入口
    this.signFlag = false; //未登录时显示注册的按钮
    this.is = false;
    this.isFind = false;
  }
  //判断失效的未登录的状态
  unlogined(value) {
    //console.log(value);
    if (value == 2) {
      this.personFlag = false; //控制个人中心的显示
      this.carted = false; //购物车的显示
      this.newsed = false;
      this.unlogin = true; //未登录时显示登录入口
      this.signFlag = true; //未登录时显示注册的按钮
      this.is = false; //隐藏登录窗口
      this.personFlag = false; //控制个人中心的显示
      this.carted = false; //购物车的显示
      this.newsed = true;
      this.unlogin = true; //未登录时显示登录入口
      this.signFlag = true; //未登录时显示注册的按钮
      this.is = false; //隐藏登录窗口
    }
  }
  //ishwhw = false;
  //显示登录的对话框
  login(v) {
    //点击显示登录床的时候需要去看看localstorage里面有没存着上次登录的信息
    this.is = true;
    //this.ishwhw = false;
    //console.log(111);
  }
  //点击隐藏登录框
  hide() {
    this.is = false;
    this.isFind = false;
    window.location.reload();
  }
  //获取用户名
  userClick(value) {
    this.userName = value;
  }
  //获取密码
  password(value) {
    this.pass = value;
  }
  //记住我
  checkme(flag) {
    //点击记住我(这里面包括密码和用户名)
    //console.log(flag);
    this.isremember = "0";
    //console.log(flag.target.checked);
    if (flag.target.checked == true) {
      this.isremember = "1";
    }
  }
  //开始登录
  loaded() {
    let encoder = this.pass;
    var key = CryptoJS.enc.Utf8.parse("377902d57d26b67c");
    var srcs = CryptoJS.enc.Utf8.parse(encoder);
    //console.log(srcs);
    var encrypted = CryptoJS.AES.encrypt(srcs, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    encrypted = encrypted.toString();
    //alert(encrypted.toString());
    this.http
      .post(this.NavService.domain + "/user/login", {
        username: this.userName,
        password: encrypted,
        isremember: this.isremember,
      })
      .map((res) => res.json())
      .subscribe(
        (res) => {
          //console.log(res);
          if (res.code == 0) {
            //说明登录成功
            //如果点击了记住wo
            window.localStorage.setItem("token", res.data.token);
            window.localStorage.setItem("jsessionid", res.data.jsessionid);
            this.personFlag = true; //控制个人中心的显示
            this.carted = true; //购物车的显示
            this.newsed = true;
            this.unlogin = false; //未登录时显示登录入口
            this.signFlag = false; //未登录时显示注册的按钮
            this.is = false; //隐藏登录窗口nickName
            this.remstatus = false;
            this.picuser = res.data.userAvatar; //用户头像
            this.usernameshow = res.data.nickName; //用户名
            this.telename = res.data.userMobile.slice(0, 3);
            window.localStorage.setItem("pic", res.data.userAvatar);
            window.localStorage.setItem("nickName", res.data.nickName);
            window.localStorage.setItem("tele", this.telename);
            window.location.reload();
          } else {
            //登录失败
            this.personFlag = false; //控制个人中心的显示
            this.carted = false; //购物车的显示
            this.newsed = false;
            this.unlogin = true; //未登录时显示登录入口
            this.signFlag = true; //未登录时显示注册的按钮
            this.is = true; //隐藏登录窗口
            this.remstatus = true;
            this.remessage = res.msg;
          }
        },
        (error) => {
          //console.log(error);
        }
      );
  }
  //找回密码显示
  findPass() {
    this.is = false;
    this.isFind = true;
    //点击找回密码
  }
  //渲染图片二维码
  imgSrc() {
    this.src = this.NavService.domain + "/pub/verifi/imgCode";
    this.src += "?id=" + Math.random() * 10;
  }
  //获取电话
  saveTele(value: string) {
    this.tele = value;
    var reg = new RegExp(/^1[3|4|5|6|7|8|9][0-9]\d{8}$/);
    this.leng = !reg.test(this.tele);
    if (reg.test(this.tele)) {
      this.remcon = "";
    } else {
      this.remcon = "手机号码有误,请修改手机号码";
    }
  }
  //获取图片验证码
  saveVerify(value: string) {
    this.verify = value;
    //console.log(this.verify);
  }
  //获取动态的验证码
  safe() {
    this.times = 60;
    var reg = new RegExp(/^1[3|4|5|6|7|8|9][0-9]\d{8}$/);
    //console.log(this.verify);
    if (!reg.test(this.tele)) {
      this.remcon = "请输入正确的手机号---";
    } else if (!this.verify) {
      this.remcon = "请输入图片验证码";
    } else {
      this.remcon = "";
      this.unsign = false;
      this.justsign = true;
      // 这里加了一个图片验证码的参数,进行图片验证码的校验
      var info = {
        mobile: this.tele,
        imgValiCode: this.verify,
      };
      $.ajax({
        type: "POST",
        crossDomain: true,
        xhrFields: {
          withCredentials: true,
        },
        contentType: "application/json;charset=UTF-8",
        data: JSON.stringify(info),
        async: false,
        url: this.NavService.domain + "/pub/shortMsg/send",
        dataType: "json",
        success: (res) => {
          // console.log(res);
          if (res.code == 0) {
            this.remcon = res.data;
            this.verifycode = false;
            var timer = setInterval(() => {
              this.times--;
              if (this.times <= 0) {
                this.verifycode = true;
                clearInterval(timer);
                this.src = this.NavService.domain + "/pub/verifi/imgCode";
                this.src += "?id=" + Math.random() * 10;
              }
            }, 1000);
          } else {
            this.remcon = res.msg;
            this.src = this.NavService.domain + "/pub/verifi/imgCode";
            this.src += "?id=" + Math.random() * 10;
          }
        },
      });
    }
  }
  savePassword(value: string) {
    this.passWord = value;
  }
  //获取第二次密码  在这里判断两次密码是否输入一致
  saveisPassword(value: string) {
    this.isPassword = value;
    if (this.passWord == this.isPassword) {
      this.remcon = "";
    } else {
      this.remcon = "两次输入的密码不一致";
    }
    //console.log(this.isPassword);
  }
  //获取手机验证码
  saveTelev(value: string) {
    this.teleword = value;
    if (!value) {
      this.verifycode = true;
    }
  }
  //找回密码
  foundpass() {
    //console.log(1);
    //点击确认找回密码
    //开始获取数据
    var reg = new RegExp(/^1[3|4|5|6|7|8|9][0-9]\d{8}$/);
    if (!reg.test(this.tele)) {
      //验证手机号
      this.remcon = "手机号码有误,请修改手机号码";
    } else if (!this.teleword) {
      this.remcon = "请输入手机验证码";
    } else if (!this.passWord) {
      //密码
      this.remcon = "请输入密码";
    } else if (!this.isPassword) {
      this.remcon = "请再次输入密码";
    } else if (this.passWord !== this.isPassword) {
      this.remcon = "两次输入的密码不一致";
    } else {
      this.remcon = "";
      //let encoder = this.passWord.toString('base64');
      var key = CryptoJS.enc.Utf8.parse("377902d57d26b67c");
      var srcs = CryptoJS.enc.Utf8.parse(this.passWord);
      //console.log(srcs);
      var encrypted = CryptoJS.AES.encrypt(srcs, key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
      });
      encrypted = encrypted.toString();
      //let encoderpass = this.isPassword.toString('base64');
      var keys = CryptoJS.enc.Utf8.parse("377902d57d26b67c");
      var srcss = CryptoJS.enc.Utf8.parse(this.isPassword);
      //console.log(srcs);
      var encryptedpass = CryptoJS.AES.encrypt(srcss, keys, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
      });
      encryptedpass = encryptedpass.toString();
      //(encryptedpass.toString());
      let header = new Headers({ "Content-Type": "application/json" }); //null,其实不表明 json 也可以, ng 默认好像是 json
      let options = new RequestOptions({
        headers: header,
        withCredentials: true,
      });
      var userInfo = {
        mobile: this.tele,
        captcha: this.teleword,
        repassword: encryptedpass,
        password: encrypted,
      };
      this.http
        .post(
          this.NavService.domain + "/user/getPwd",
          JSON.stringify(userInfo),
          options
        )
        .map((res) => res.json())
        .subscribe(
          (res) => {
            if (res.code == 0) {
              this.remcon = "修改密码成功,正在为您切换弹出窗...";
              //window.location.href = this.NavService.skip_center;
              //找回密码成功以后
              setTimeout(() => {
                this.is = true;
                this.isFind = false;
              }, 2000);
            } else {
              this.remcon = res.msg;
            }
          },
          (error) => {
            this.remcon = error.msg;
          }
        );
    }
  }
  //退出登录
  quit() {
    this.http
      .post(this.NavService.domain + "/user/logout", {
        token: window.localStorage.getItem("token"),
        jsessionid: window.localStorage.getItem("jsessionid"),
      })
      .map((res) => res.json())
      .subscribe(
        (res) => {
          //清空token
          if (res.code == 0) {
            window.localStorage.setItem("token", "");
            window.localStorage.setItem("jsessionid", "");
            window.localStorage.setItem("pic", ""); //用户头像
            window.localStorage.setItem("nickName", ""); //用户名
            window.localStorage.setItem("tele", "");
            window.location.reload();
          } else {
            window.localStorage.setItem("token", "");
            window.localStorage.setItem("jsessionid", "");
            window.localStorage.setItem("pic", ""); //用户头像
            window.localStorage.setItem("nickName", ""); //用户名
            window.localStorage.setItem("tele", "");
            window.location.href = this.NavService.skip_center;
          }
        },
        (error) => {
          window.localStorage.setItem("token", "");
          window.localStorage.setItem("jsessionid", "");
          window.localStorage.setItem("pic", ""); //用户头像
          window.localStorage.setItem("nickName", ""); //用户名
          window.localStorage.setItem("tele", "");
          window.location.href = this.NavService.skip_center;
        }
      );
  }
  pphoverli() {
    this.ppshow = true;
  }
  ppleaveli() {
    this.ppshow = false;
  }
}
