import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NavService } from "../../nav.service";
import { HttpClient } from "@angular/common/http";
import { HttpHeaders } from "@angular/common/http";
import { Http } from "@angular/http";
import "rxjs/add/operator/map"; //调用映射的响应可观察到得到解析的人对象。
declare var jQuery: any; //定义jquery
declare var $: any; //定义jquery
// declare var niceScroll:any;
@Component({
  selector: "app-remohave",
  templateUrl: "./remohave.component.html",
  styleUrls: ["./remohave.component.css"],
})
export class RemohaveComponent implements OnInit {
  constructor(
    private router: Router,
    private http: Http,
    private NavService: NavService
  ) {}
  // 数据准备
  //课程包和课程之间的转换
  title = "";
  bagStatus = true;
  couStatus = false;
  bagId; //单个课程包id
  // tab切换
  tabStatus = -1; //全部 -1: 全部, 0:未排课, 1:已排课, 2:已上课
  // 已购课程包
  buyBagData = [];
  // 分类数据
  allData = []; //声明一个空数组
  // 分页数据
  currpage = 1; //当前的页数;
  lengs: number;
  AllNum = [];
  //  错误提示弹窗
  useWrongtatus = false;
  resmsg = "";
  ngOnInit() {
    this.currpage = 1; //当前的页数;
    this.AllNum = [];
    this.lengs = 0;
    // 渲染数据 已购课程包
    this.http
      .post(this.NavService.domain + "/rc/userCenter/getBoughtCourse", {
        token: localStorage.getItem("token"),
        jsessionid: localStorage.getItem("jsessionid"),
        pageNo: "1",
        pageSize: "8",
      })
      .map((res) => res.json())
      .subscribe(
        (res) => {
          //console.log(res)
          if (res.code == 200) {
            console.log(res.data.list);
            if (res.data.list.length == 0) {
              this.title = "暂无数据";
              this.buyBagData = [];
            } else {
              this.title = "";
              this.buyBagData = res.data.list;
            }
            var num = Math.ceil(res.data.rowCount / 10);
            this.AllNum = new Array();
            var arr = new Array(num);
            for (var i = 0; i < arr.length; i++) {
              this.AllNum.push(i + 1 + "");
            }
            var len = Math.ceil(res.data.rowCount / 10);
            this.lengs = len;
            $(".page-move").css({ left: "0", width: len * 34.5 + "px" });
            if (len > 10) {
              $(".page-box").css({ width: 10 * 34 + "px" });
              $(".page").css({ width: 10 * 35.5 + 140 + "px" });
            } else {
              $(".page-box").css({ width: len * 34 + "px" });
              $(".page").css({ width: len * 36.5 + 123 + "px" });
            }
          } else {
            this.useWrongtatus = true;
            this.resmsg = res.message;
            setTimeout(() => {
              this.useWrongtatus = false;
            }, 3000);
          }
        },
        (err) => {
          //console.log(err);
          this.useWrongtatus = true;
          this.resmsg = "请求异常,请稍后再试";
          setTimeout(() => {
            this.useWrongtatus = false;
          }, 3000);
        }
      );
  }
  clickClose() {
    this.bagStatus = true;
    this.couStatus = false;
    this.ngOnInit();
  }
  // 点击课程包 获取已购课程的数据
  clickBag(id) {
    this.bagStatus = false;
    this.couStatus = true;
    this.tabStatus = -1;
    //console.log(id)
    this.bagId = id;
    this.title = "";
    this.allData = [];
    // 请求数据
    this.http
      .post(this.NavService.domain + "/rc/rcourse/queryCourseListAndPlan", {
        token: localStorage.getItem("token"),
        jsessionid: localStorage.getItem("jsessionid"),
        cpId: this.bagId,
        status: this.tabStatus,
        pageNo: "1",
        pageSize: "8",
      })
      .map((res) => res.json())
      .subscribe(
        (res) => {
          //console.log(res);
          if (res.code == 200) {
            console.log(res.data.records);
            if (res.data.records.length == 0) {
              this.title = "暂无数据";
              this.allData = [];
            } else {
              this.title = "";

              this.allData = res.data.records;
              this.allData.forEach(function (val) {
                if (val.rcCoursePlanTimeList.length == 0) {
                  val.isHave = 1;
                } else {
                  val.isHave = 0;
                }
              });
              //console.log(this.allData)
              var num = res.data.pages;
              this.AllNum = new Array();
              var arr = new Array(num);
              for (var i = 0; i < arr.length; i++) {
                this.AllNum.push(i + 1 + "");
              }
              var len = res.data.pages;
              this.lengs = len;
              $(".page-move").css({ left: "0", width: len * 55 + "px" });
              if (len > 10) {
                $(".page-box").css({ width: 10 * 34 + "px" });
                $(".page").css({ width: 10 * 35.5 + 140 + "px" });
              } else {
                $(".page-box").css({ width: len * 38 + "px" });
                $(".page").css({ width: len * 36.5 + 120 + "px" });
              }
            }
          } else {
            this.useWrongtatus = true;
            this.resmsg = res.message;
            setTimeout(() => {
              this.useWrongtatus = false;
            }, 3000);
          }
        },
        (err) => {
          this.useWrongtatus = true;
          this.resmsg = "请求异常,请稍后再试";
          setTimeout(() => {
            this.useWrongtatus = false;
          }, 3000);
        }
      );
  }
  // tab切换函数
  tabClick(num) {
    this.currpage = 1; //当前的页数;
    this.AllNum = [];
    this.lengs = 0;
    this.tabStatus = num;
    this.title = "";
    this.allData = [];
    // 请求数据
    this.http
      .post(this.NavService.domain + "/rc/rcourse/queryCourseListAndPlan", {
        token: localStorage.getItem("token"),
        jsessionid: localStorage.getItem("jsessionid"),
        cpId: this.bagId,
        status: this.tabStatus,
        pageNo: "1",
        pageSize: "8",
      })
      .map((res) => res.json())
      .subscribe(
        (res) => {
          console.log(res);
          if (res.code == 200) {
            if (res.data.records.length == 0) {
              this.title = "暂无数据";
              this.allData = [];
            } else {
              this.title = "";
              this.allData = res.data.records;
              console.log("jin");
              this.allData.forEach((val) => {
                console.log(val.rcCoursePlanTimeList.length == 0);
                if (val.rcCoursePlanTimeList.length == 0) {
                  val.isHave = 1;
                } else {
                  val.isHave = 0;
                }
              });
              //console.log(this.allData)
              var num = res.data.pages;
              this.AllNum = new Array();
              var arr = new Array(num);
              for (var i = 0; i < arr.length; i++) {
                this.AllNum.push(i + 1 + "");
              }
              var len = res.data.pages;
              this.lengs = len;
              $(".page-move").css({ left: "0", width: len * 55 + "px" });
              if (len > 10) {
                $(".page-box").css({ width: 10 * 34 + "px" });
                $(".page").css({ width: 10 * 35.5 + 140 + "px" });
              } else {
                $(".page-box").css({ width: len * 38 + "px" });
                $(".page").css({ width: len * 36.5 + 120 + "px" });
              }
            }
          } else {
            this.useWrongtatus = true;
            this.resmsg = res.message;
            setTimeout(() => {
              this.useWrongtatus = false;
            }, 3000);
          }
        },
        (err) => {
          this.useWrongtatus = true;
          this.resmsg = "请求异常,请稍后再试";
          setTimeout(() => {
            this.useWrongtatus = false;
          }, 3000);
        }
      );
  }
  pageclick(value: number, event, leng: number) {
    /////////////////
    this.lengs = leng;
    this.currpage = value;
    $(".prepage").css({ cursor: "pointer", color: "#006ddc" });
    $(".nextpage").css({ cursor: "pointer", color: "#006ddc" });
    $(".page-move li").css({ "background-color": "#fff", color: "#333" });
    $(event.target).css({ "background-color": "#2179FF", color: "#fff" });
    if (value >= 5 && value < leng - 2) {
      $(".page-move").animate({ left: "-" + (value - 1) * 35 + "px" }, 100);
      //$(selector).animate(styles,speed,easing,callback)
    }
    if (value < 5) {
      $(".page-move").animate({ left: "0" }, 20);
    }
    if (leng - value < 7) {
      $(".page-move").animate(
        { left: "-" + (leng - 7) * 33.25 + "px", right: "0px" },
        20
      );
    }
    this.http
      .post(this.NavService.domain + "/rc/userCenter/getBoughtCourse", {
        token: localStorage.getItem("token"),
        jsessionid: localStorage.getItem("jsessionid"),
        pageNo: value,
        pageSize: "8",
      })
      .map((res) => res.json())
      .subscribe(
        (res) => {
          //console.log(res);
          this.buyBagData = res.data.list;
        },
        (error) => {}
      );
  }
  //上一页
  pagepre() {
    //this.currpage   这里是记录上次的点击要是超过了总的长度就提示是最后一页了
    $(".nextpage").css({ cursor: "pointer", color: "#006ddc" });
    if (this.currpage == 1) {
      $(".page-move li").css({ "background-color": "#fff", color: "#333" });
      $(".p" + this.currpage).css({
        "background-color": "#2179FF",
        color: "#fff",
      });
      $(".prepage").css({ cursor: "not-allowed", color: "#333" });
      //////console.log("0");
    } else {
      this.currpage = this.currpage - 1;
      $(".page-move li").css({ "background-color": "#fff", color: "#333" });
      $(".p" + this.currpage).css({
        "background-color": "#2179FF",
        color: "#fff",
      });
      if (this.currpage >= 5 && this.currpage < this.lengs - 3) {
        $(".page-move").animate(
          { left: "-" + (this.currpage - 1) * 35 + "px" },
          100
        );
        //$(selector).animate(styles,speed,easing,callback)
      }
      if (this.currpage < 5) {
        $(".page-move").animate({ left: "0" }, 20);
      }
      if (this.lengs - this.currpage < 2) {
        $(".page-move").animate(
          { left: "-" + (this.lengs - 7) * 35.25 + "px", right: "0px" },
          20
        );
      }
      this.http
        .post(this.NavService.domain + "/rc/userCenter/getBoughtCourse", {
          token: localStorage.getItem("token"),
          jsessionid: localStorage.getItem("jsessionid"),
          pageNo: this.currpage,
          pageSize: "8",
        })
        .map((res) => res.json())
        .subscribe(
          (res) => {
            //console.log(res)
            this.buyBagData = res.data.list;
          },
          (err) => {
            //console.log(err)
          }
        );
    }
  }
  //下一页
  pagenext() {
    //this.currpage   这里是记录上次的点击要是超过了总的长度就提示是最后一页了
    $(".prepage").css({ cursor: "pointer", color: "#006ddc" });
    this.currpage = Number(this.currpage) + 1;
    $(".page-move li").css({ "background-color": "#fff", color: "#333" });
    $(".p" + this.currpage).css({
      "background-color": "#2179FF",
      color: "#fff",
    });
    if (this.currpage - this.lengs >= 0) {
      this.currpage = this.lengs;
      $(".p" + this.currpage).css({
        "background-color": "#2179FF",
        color: "#fff",
      });
      $(".nextpage").css({ cursor: "not-allowed", color: "#333" });
    }
    if (this.currpage >= 5 && this.currpage < this.lengs - 5) {
      $(".page-move").animate(
        { left: "-" + (this.currpage - 1) * 35 + "px" },
        100
      );
    }
    if (this.currpage < 5) {
      $(".page-move").animate({ left: "0" }, 20);
    }
    //////console.log(typeof this.currpage);
    if (this.lengs - this.currpage < 7) {
      //////console.log("daotou")
      $(".page-move").animate(
        { left: "-" + (this.lengs - 7) * 33.25 + "px", right: "0px" },
        20
      );
    }
    this.http
      .post(this.NavService.domain + "/rc/userCenter/getBoughtCourse", {
        token: localStorage.getItem("token"),
        jsessionid: localStorage.getItem("jsessionid"),
        pageNo: this.currpage,
        pageSize: "8",
      })
      .map((res) => res.json())
      .subscribe(
        (res) => {
          //console.log(res)
          this.buyBagData = res.data.list;
        },
        (err) => {
          //console.log(err)
        }
      );
  }
  pageclickS(value: number, event, leng: number) {
    /////////////////
    this.lengs = leng;
    this.currpage = value;
    $(".prepage").css({ cursor: "pointer", color: "#006ddc" });
    $(".nextpage").css({ cursor: "pointer", color: "#006ddc" });
    $(".page-move li").css({ "background-color": "#fff", color: "#333" });
    $(event.target).css({ "background-color": "#2179FF", color: "#fff" });
    if (value >= 5 && value < leng - 2) {
      $(".page-move").animate({ left: "-" + (value - 1) * 35 + "px" }, 100);
      //$(selector).animate(styles,speed,easing,callback)
    }
    if (value < 5) {
      $(".page-move").animate({ left: "0" }, 20);
    }
    if (leng - value < 7) {
      $(".page-move").animate(
        { left: "-" + (leng - 7) * 33.25 + "px", right: "0px" },
        20
      );
    }
    this.http
      .post(this.NavService.domain + "/rc/rcourse/queryCourseListAndPlan", {
        token: localStorage.getItem("token"),
        jsessionid: localStorage.getItem("jsessionid"),
        cpId: this.bagId,
        status: this.tabStatus,
        pageNo: value,
        pageSize: "8",
      })
      .map((res) => res.json())
      .subscribe((res) => {
        //console.log(res);
        this.allData = res.data.records;
        this.allData.forEach((val) => {
          if (val.rcCoursePlanTimeList.length == 0) {
            val.isHave = 1;
          } else {
            val.isHave = 0;
          }
        });
      });
  }
  //上一页
  pagepreS() {
    //this.currpage   这里是记录上次的点击要是超过了总的长度就提示是最后一页了
    $(".nextpage").css({ cursor: "pointer", color: "#006ddc" });
    if (this.currpage == 1) {
      $(".page-move li").css({ "background-color": "#fff", color: "#333" });
      $(".p" + this.currpage).css({
        "background-color": "#2179FF",
        color: "#fff",
      });
      $(".prepage").css({ cursor: "not-allowed", color: "#333" });
      //////console.log("0");
    } else {
      this.currpage = this.currpage - 1;
      $(".page-move li").css({ "background-color": "#fff", color: "#333" });
      $(".p" + this.currpage).css({
        "background-color": "#2179FF",
        color: "#fff",
      });
      if (this.currpage >= 5 && this.currpage < this.lengs - 3) {
        $(".page-move").animate(
          { left: "-" + (this.currpage - 1) * 35 + "px" },
          100
        );
        //$(selector).animate(styles,speed,easing,callback)
      }
      if (this.currpage < 5) {
        $(".page-move").animate({ left: "0" }, 20);
      }
      if (this.lengs - this.currpage < 2) {
        $(".page-move").animate(
          { left: "-" + (this.lengs - 7) * 35.25 + "px", right: "0px" },
          20
        );
      }
    }
    this.http
      .post(this.NavService.domain + "/rc/rcourse/queryCourseListAndPlan", {
        token: localStorage.getItem("token"),
        jsessionid: localStorage.getItem("jsessionid"),
        cpId: this.bagId,
        status: this.tabStatus,
        pageNo: this.currpage,
        pageSize: "8",
      })
      .map((res) => res.json())
      .subscribe((res) => {
        //console.log(res);
        this.allData = res.data.records;
        this.allData.forEach((val) => {
          if (val.rcCoursePlanTimeList.length == 0) {
            val.isHave = 1;
          } else {
            val.isHave = 0;
          }
        });
      });
  }
  //下一页
  pagenextS() {
    //this.currpage   这里是记录上次的点击要是超过了总的长度就提示是最后一页了
    $(".prepage").css({ cursor: "pointer", color: "#006ddc" });
    this.currpage = Number(this.currpage) + 1;
    $(".page-move li").css({ "background-color": "#fff", color: "#333" });
    $(".p" + this.currpage).css({
      "background-color": "#2179FF",
      color: "#fff",
    });
    if (this.currpage - this.lengs >= 0) {
      this.currpage = this.lengs;
      $(".p" + this.currpage).css({
        "background-color": "#2179FF",
        color: "#fff",
      });
      $(".nextpage").css({ cursor: "not-allowed", color: "#333" });
    }
    if (this.currpage >= 5 && this.currpage < this.lengs - 5) {
      $(".page-move").animate(
        { left: "-" + (this.currpage - 1) * 35 + "px" },
        100
      );
    }
    if (this.currpage < 5) {
      $(".page-move").animate({ left: "0" }, 20);
    }
    //////console.log(typeof this.currpage);
    if (this.lengs - this.currpage < 7) {
      //////console.log("daotou")
      $(".page-move").animate(
        { left: "-" + (this.lengs - 7) * 33.25 + "px", right: "0px" },
        20
      );
    }
    this.http
      .post(this.NavService.domain + "/rc/rcourse/queryCourseListAndPlan", {
        token: localStorage.getItem("token"),
        jsessionid: localStorage.getItem("jsessionid"),
        cpId: this.bagId,
        status: this.tabStatus,
        pageNo: this.currpage,
        pageSize: "8",
      })
      .map((res) => res.json())
      .subscribe((res) => {
        //console.log(res);
        this.allData = res.data.records;
        this.allData.forEach((val) => {
          if (val.rcCoursePlanTimeList.length == 0) {
            val.isHave = 1;
          } else {
            val.isHave = 0;
          }
        });
      });
  }
}
