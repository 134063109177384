/**
 * Created by YingJieCHAI on 2017/11/20.
 */
import { Component, OnInit, Output } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { HttpHeaders, HttpRequest } from "@angular/common/http";
import { NavService } from "./nav.service";
import { Http, RequestOptions, Headers } from "@angular/http";
import { Type } from "@angular/core";
import "rxjs/add/operator/map"; //调用映射的响应可观察到得到解析的人对象。
import { ActivatedRoute, ParamMap, Params } from "@angular/router";
declare var $: any;
declare var CryptoJS: any;
//import 'rxjs/add/operator/toPromise';
@Component({
  templateUrl: "./sign.component.html",
  styleUrls: ["./sign.component.css"]
})
export class SignComponent {
  constructor(private http: Http, private NavService: NavService) { }
  //初始化数据
  lengs; //手机号的状态
  userNames;
  passs;
  teles = "";
  telewords; //手机验证码
  verifys = "";
  passWords = "";
  isPasswords = "";
  srcs;
  verifycodes = true; //获取验证码按钮存在的状态
  //初始化数据
  remessage = ""; //提示信息
  remstatus = false;
  picuser; //用户头像
  usernameshow; //用户名
  telename;
  yuedata; //余额
  personFlag = false; //控制个人中心的显示
  carted = false; //购物车的显示
  newsed = false; //控制消息
  unlogin = false; //未登录时显示登录入口
  signFlag = false; //未登录时显示注册的按钮
  is = false;
  isFind = false;
  isremember = "0";
  userName;
  pass;
  //初始化数据
  leng;//手机号的状态
  name = '';
  tele = '';
  teleword;//手机验证码
  verify = '';
  passWord = '';
  isPassword = '';
  tit = "";
  show;
  src;
  qq;
  verifycode; //获取验证码按钮存在的状态
  wrongrem; //错误提示
  isRight;//密码不一致的状态
  times = 60;//时间
  remcon = "";//提示信息
  chec = false;//是否选中
  justsign//立即注册按钮显示的状态
  unsign//不能注册
  //@Output({
  //  value:string
  //})
  // 远程教室注册逻辑
  common_user_status = true; //默认显示普通用户的注册
  remote_user_status = false; //远程教室注册入口
  userRo = 0; //默认是普通用户注册
  remoCode = ""; //中心机编码
  remoCom = ""; //企业名称
  remoName = ""; //真实姓名
  remoCard = ""; //身份证号
  remoEmail = ""; //邮箱
  remoPart = ""; //部门
  remoStation = ""; //岗位
  rescon = false;//返回提示语状态
  resshow = false;
  ngOnInit() {
    console.log("会加载吗")
    this.verifycode = true;
    this.unsign = true;
    this.justsign = false;
    this.leng = false;
    this.src = this.NavService.domain + "/pub/verifi/imgCode";
    this.srcs = this.NavService.domain + "/pub/verifi/imgCode";
    this.wrongrem = false;
    this.isRight = false;//一致的时候是false
  }
  // 远程教室和普通用户切换
  userToggle(num) {

    // 初始化数据
    this.verifycode = true;
    this.unsign = true;
    this.justsign = false;
    this.leng = false;
    this.wrongrem = false;
    this.isRight = false;//一致的时候是false
    this.src = this.NavService.domain + "/pub/verifi/imgCode";
    this.src += "?id=" + Math.random() * 10;
    this.srcs = this.NavService.domain + "/pub/verifi/imgCode";
    this.srcs += "?id=" + Math.random() * 10;
    this.tele = "";
    this.teleword = "";
    this.verify = "";
    this.passWord = "";
    this.isPassword = "";
    this.remoCode = ""; //中心机编码
    this.remoCom = ""; //企业名称
    this.remoName = ""; //真实姓名
    this.remoCard = ""; //身份证号
    this.remoEmail = ""; //邮箱
    this.remoPart = ""; //部门
    this.remoStation = ""; //岗位
    if (num == 0) {
      this.common_user_status = true; //普通用户的注册
      this.remote_user_status = false; //远程教室注册入口
      this.userRo = 0;
    } else {
      this.common_user_status = false; //普通用户的注册
      this.remote_user_status = true; //远程教室注册入口
      this.userRo = 1;
    }
  }
  //所有的数据获取完毕
  // Inject HttpClient into your component or service.
  //获取名字
  // saveName(value:string){
  //   this.name = value;////console.log(this.name);
  // }
  //获取电话
  saveTele(value: string) {
    this.tele = value;
    console.log(value);
    ////console.log(this.tele);
    var reg = new RegExp(/^1[3|4|5|6|7|8|9][0-9]\d{8}$/);
    ////console.log(reg.test(this.tele));
    if (reg.test(this.tele)) {
      this.show = false;
      this.remcon = "";
    } else {
      this.show = true;
      this.remcon = "手机号码有误,请修改手机号码";
      setTimeout(() => {
        this.show = false;
        this.remcon = "";
      }, 4000);
    }
  }
  // saveqq(value:string){
  //   this.qq = value;
  // }
  //获取输入的图片验证码,
  saveVerify(value: string) {
    this.verify = value;
    console.log(this.verify);
  }
  //获取第一次密码
  savePassword(value: string) {
    this.passWord = value;
    console.log(this.passWord);
  }
  //获取第二次密码  在这里判断两次密码是否输入一致
  saveisPassword(value: string) {
    this.isPassword = value;
    if (this.passWord == this.isPassword) {
      this.isRight = false;
    } else {
      this.isRight = true;
    }
    console.log(this.isPassword);
  }
  //渲染图片二维码
  imgSrc() {
    this.src = this.NavService.domain + "/pub/verifi/imgCode";
    this.src += "?id=" + Math.random() * 10;
    // this.srcs = this.NavService.domain+"/pub/verifi/imgCode";
    // this.srcs+= "?id="+Math.random()*10;
  }
  //获取动态的验证码
  safe() {
    this.times = 60;
    var reg = new RegExp(/^1[3|4|5|6|7|8|9][0-9]\d{8}$/);
    ////console.log(this.verify);
    if (!reg.test(this.tele)) {
      this.show = true;
      this.remcon = "请输入正确的手机号码";
      setTimeout(() => {
        this.show = false;
        this.remcon = "";
      }, 2000);
    } else if (!this.verify) {
      this.show = true;
      this.remcon = "请输入图片验证码";
      setTimeout(() => {
        this.show = false;
        this.remcon = "";
      }, 2000);
    } else {
      this.unsign = false;
      this.justsign = true;
      // 这里加了一个图片验证码的参数,进行图片验证码的校验
      var info = {
        mobile: this.tele,
        imgValiCode: this.verify
      };
      $.ajax({
        type: "POST",
        crossDomain: true,
        xhrFields: {
          withCredentials: true
        },
        contentType: "application/json;charset=UTF-8",
        data: JSON.stringify(info),
        url: this.NavService.domain + "/pub/shortMsg/send",
        dataType: "json",
        success: res => {
          ////console.log(res);
          if (res.code == 0) {
            this.verifycode = false;
            var timer = setInterval(() => {
              this.times--;
              if (this.times <= 0) {
                this.verifycode = true;
                clearInterval(timer);
                this.src = this.NavService.domain + "/pub/verifi/imgCode";
                this.src += "?id=" + Math.random() * 10;
              }
            }, 1000);
          } else {
            this.show = true;
            this.remcon = res.msg;
            setTimeout(() => {
              this.show = false;
              this.remcon = "";
            }, 2000);
            this.src = this.NavService.domain + "/pub/verifi/imgCode";
            this.src += "?id=" + Math.random() * 10;
          }
        }
      });
    }
  }
  //获取手机验证码  远程教室和普通用户通用
  saveTelev(value: string) {
    this.teleword = value;
    if (!value) {
      this.verifycode = true;
    }
  }
  //获取远程教室中心机编码
  saveRemoten(value: string) {
    this.remoCode = value.replace(/\s+/g, "");
    console.log(value);
    if (!!this.remoCode) {
      this.show = false;
      this.remcon = "";
    } else {
      this.show = true;
      this.remcon = "中心机编码不能为空";
      setTimeout(() => {
        this.show = false;
        this.remcon = "";
      }, 4000);
    };
  }
  //获取企业名称
  saveRemoteC(value: string) {
    console.log(value);
    if (value.replace(/\s+/g, "").length == 0) {
      this.remoName = "";
      this.show = true;
      this.remcon = "内容不能为空";
      setTimeout(() => {
        this.show = false;
      }, 2000);
      return false;
    };
    this.remoCom = value.replace(/\s+/g, "");
  }
  // 获取真实姓名
  saveremoteUr(value: string) {
    console.log(value);
    if (value.replace(/\s+/g, "").length == 0) {
      this.remoName = "";
      this.show = true;
      this.remcon = "内容不能为空";
      setTimeout(() => {
        this.show = false;
      }, 2000);
      return false;
    }
    this.remoName = value.replace(/\s+/g, "");
  }
  // 获取身份证号
  saveremoteCr(value: string) {
    // this.remoCard = value.replace(/\s+/g,"");
    console.log(value.length, !value, !this.remoCard);
    if (value.replace(/\s+/g, "").length == 0) {
      this.remoCard = "";
      this.show = true;
      this.remcon = "内容不能为空";
      setTimeout(() => {
        this.show = false;
      }, 2000);
      return false;
    };
    this.remoCard = value.replace(/\s+/g, "");
  }
  // 获取邮箱
  saveremoteE(value: string) {
    console.log(value);
    if (value.replace(/\s+/g, "").length == 0) {
      this.remoEmail = "";
      this.show = true;
      this.remcon = "内容不能为空";
      setTimeout(() => {
        this.show = false;
      }, 2000);
      return false;
    };
    this.remoEmail = value.replace(/\s+/g, "");
  }
  // 部门名称
  saveremoteP(value: string) {
    console.log(value);
    if (value.replace(/\s+/g, "").length == 0) {
      this.remoPart = "";
      this.show = true;
      this.remcon = "内容不能为空";
      setTimeout(() => {
        this.show = false;
      }, 2000);
      return false;
    };
    this.remoPart = value.replace(/\s+/g, "");
  }
  // 岗位名称
  saveremoteSt(value: string) {
    console.log(value);
    if (value.replace(/\s+/g, "").length == 0) {
      this.remoStation = "";
      this.show = true;
      this.remcon = "内容不能为空";
      setTimeout(() => {
        this.show = false;
      }, 2000);
      return false;
    };
    this.remoStation = value.replace(/\s+/g, "");
  }
  //checkbox的选中与否
  cose(value) {
    this.chec = value;
    console.log(this.chec);
  }
  //获取所有的信息 开始注册普通
  subInfo() {
    //开始获取数据
    var reg = new RegExp(/^1[3|4|5|6|7|8|9][0-9]\d{8}$/);
    ////console.log(reg.test(this.tele));
    if (!reg.test(this.tele)) {
      //验证手机号
      this.show = true;
      this.remcon = "手机号码有误,请修改手机号码";
      setTimeout(() => {
        this.show = false;
      }, 2000);
    } else if (!this.teleword) {
      this.show = true;
      this.remcon = "请输入手机验证码";
      setTimeout(() => {
        this.show = false;
      }, 2000);
    } else if (!this.passWord) {
      //密码
      this.show = true;
      this.remcon = "请输入密码";
      setTimeout(() => {
        this.show = false;
      }, 2000);
    } else if (!this.isPassword) {
      this.show = true;
      this.remcon = "请再次输入密码";
      setTimeout(() => {
        this.show = false;
      }, 2000);
    } else if (this.passWord !== this.isPassword) {
      this.show = true;
      this.remcon = "两次输入的密码不一致";
      setTimeout(() => {
        this.show = false;
      }, 2000);
    } else if (!this.chec) {
      this.show = true;
      this.remcon = "请同意《注册协议》和 《隐私保护协议》";
      setTimeout(() => {
        this.show = false;
      }, 2000);
    } else {
      //let encoder = this.passWord.toString('base64');
      var key = CryptoJS.enc.Utf8.parse("377902d57d26b67c");
      var srcs = CryptoJS.enc.Utf8.parse(this.passWord);
      ////console.log(srcs);
      var encrypted = CryptoJS.AES.encrypt(srcs, key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      });
      encrypted = encrypted.toString();
      //alert(encrypted.toString())
      let header = new Headers({ "Content-Type": "application/json" }); //null,其实不表明 json 也可以, ng 默认好像是 json
      let options = new RequestOptions({
        headers: header,
        withCredentials: true
      });
      var userInfo = {
        phone: this.tele,
        captcha: this.teleword,
        imgValidateCode: this.verify,
        password: encrypted,
        userRole: this.userRo
      };
      console.log(userInfo);
      //  return false;
      this.http
        .post(
          this.NavService.domain + "/user/register",
          JSON.stringify(userInfo),
          options
        )
        .map(res => res.json())
        .subscribe(
          res => {
            if (res.code == 0) {
              window.localStorage.setItem("token", res.data.token);
              window.localStorage.setItem("jsessionid", res.data.jsessionid);
              window.localStorage.setItem("pic", res.data.userAvatar);
              window.localStorage.setItem("nickName", res.data.nickName);
              window.localStorage.setItem(
                "tele",
                res.data.userMobile.slice(0, 3)
              );
              this.show = true;
              this.remcon = res.msg;
              setTimeout(() => {
                this.show = false;
                this.remcon = "";
                this.is = true;
                this.isFind = false;
              }, 2000);
            } else {
              this.show = true;
              this.remcon = res.msg;
              setTimeout(() => {
                this.show = false;
                this.remcon = "";
              }, 2000);
            }
          },
          error => {
            this.show = true;
            this.remcon = error.msg;
            setTimeout(() => {
              this.show = false;
            }, 2000);
          }
        );
    }
  }
  //获取所有的信息 开始注册企业
  subInfoRe() {
    //开始获取数据
    var reg = new RegExp(/^1[3|4|5|6|7|8|9][0-9]\d{8}$/);
    ////console.log(reg.test(this.tele));
    if (!reg.test(this.tele)) {
      //验证手机号
      this.show = true;
      this.remcon = "手机号码有误,请修改手机号码";
      setTimeout(() => {
        this.show = false;
      }, 2000);
    } else if (!this.teleword) {
      this.show = true;
      this.remcon = "请输入手机验证码";
      setTimeout(() => {
        this.show = false;
      }, 2000);
    } else if (!this.passWord) {
      //密码
      this.show = true;
      this.remcon = "请输入密码";
      setTimeout(() => {
        this.show = false;
      }, 2000);
    } else if (!this.isPassword) {
      this.show = true;
      this.remcon = "请再次输入密码";
      setTimeout(() => {
        this.show = false;
      }, 2000);
    } else if (this.passWord !== this.isPassword) {
      this.show = true;
      this.remcon = "两次输入的密码不一致";
      setTimeout(() => {
        this.show = false;
      }, 2000);
    } else if (!this.chec) {
      this.show = true;
      this.remcon = "请同意《注册协议》和 《隐私保护协议》";
      setTimeout(() => {
        this.show = false;
      }, 2000);
    } else if (!this.remoCode) {
      this.show = true;
      this.remcon = "中心机编码不能为空";
      setTimeout(() => {
        this.show = false;
      }, 2000);
    } else if (!this.remoCom) {
      this.show = true;
      this.remcon = "内容不能为空";
      setTimeout(() => {
        this.show = false;
      }, 2000);
    } else if (!this.remoPart) {
      this.show = true;
      this.remcon = "内容不能为空";
      setTimeout(() => {
        this.show = false;
      }, 2000);
    } else if (!this.remoStation) {
      this.show = true;
      this.remcon = "内容不能为空";
      setTimeout(() => {
        this.show = false;
      }, 2000);
    } else if (!this.remoName) {
      this.show = true;
      this.remcon = "内容不能为空";
      setTimeout(() => {
        this.show = false;
      }, 2000);
    } else if (!this.remoCard) {
      this.show = true;
      this.remcon = "内容不能为空";
      setTimeout(() => {
        this.show = false;
      }, 2000);
    } else if (!this.remoEmail) {
      this.show = true;
      this.remcon = "内容不能为空";
      setTimeout(() => {
        this.show = false;
      }, 2000);
    }
    else {
      //let encoder = this.passWord.toString('base64');
      var key = CryptoJS.enc.Utf8.parse("377902d57d26b67c");
      var srcs = CryptoJS.enc.Utf8.parse(this.passWord);
      ////console.log(srcs);
      var encrypted = CryptoJS.AES.encrypt(srcs, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
      encrypted = encrypted.toString();
      //alert(encrypted.toString())
      let header = new Headers({ 'Content-Type': 'application/json' }); //null,其实不表明 json 也可以, ng 默认好像是 json
      let options = new RequestOptions({ headers: header, withCredentials: true });
      var userInfore = {
        "phone": this.tele,
        "captcha": this.teleword,
        "imgValidateCode": this.verify,
        "password": encrypted,
        "userRole": this.userRo,
        "centralMachineCode": this.remoCode,
        "company": this.remoCom,
        "department": this.remoPart,
        "position": this.remoStation,
        "name": this.remoName,
        "id_card": this.remoCard,
        "email": this.remoEmail
      };
      console.log(userInfore)
      //  return false;
      this.http.post(this.NavService.domain + '/user/register', JSON.stringify(userInfore), options)
        .map(res => res.json())
        .subscribe(
          res => {
            if (res.code == 0) {
              window.localStorage.setItem("token", res.data.token);
              window.localStorage.setItem("jsessionid", res.data.jsessionid);
              window.localStorage.setItem("pic", res.data.userAvatar);
              window.localStorage.setItem("nickName", res.data.nickName);
              window.localStorage.setItem("tele", res.data.userMobile.slice(0, 3));
              this.resshow = true;
              this.rescon = false;
              setTimeout(() => {
                this.resshow = false;
                this.rescon = true;
                this.is = true;
                this.isFind = false;
              }, 2000);

            } else {
              this.show = true;
              this.remcon = res.msg;
              setTimeout(() => {
                this.show = false;
              }, 3000);
            }
          },
          error => {
            this.show = true;
            this.remcon = error.msg;
            setTimeout(() => {
              this.show = false;
            }, 2000);
          }
        );
    }
  }
  //以下是登陆的部分
  //点击隐藏登录框
  hides() {
    this.is = false;
    this.isFind = false;
    window.location.reload();
  }
  //获取用户名
  userClicks(value) {
    ////console.log(value);
    this.userNames = value;
  }
  //获取密码
  passwords(value) {
    ////console.log(value);
    this.passs = value;
  }
  //记住我
  checkmes(flag) {
    //点击记住我(这里面包括密码和用户名)
    ////console.log(flag);
    this.isremember = "0";
    ////console.log(flag.target.checked);
    if (flag.target.checked == true) {
      this.isremember = "1";
    }
  }
  //开始登录
  loadeds() {
    let encoder = this.passs.toString("base64");
    var key = CryptoJS.enc.Utf8.parse("377902d57d26b67c");
    var srcs = CryptoJS.enc.Utf8.parse(encoder);
    ////console.log(srcs);
    var encrypted = CryptoJS.AES.encrypt(srcs, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    encrypted = encrypted.toString();
    //alert(encrypted.toString())
    this.http
      .post(this.NavService.domain + "/user/login", {
        username: this.userNames,
        password: encrypted,
        isremember: this.isremember
      })
      .map(res => res.json())
      .subscribe(
        res => {
          ////console.log(res);
          if (res.code == 0) {
            //说明登录成功
            //如果点击了记住wo
            window.localStorage.setItem("token", res.data.token);
            window.localStorage.setItem("jsessionid", res.data.jsessionid);
            this.personFlag = true; //控制个人中心的显示
            this.carted = true; //购物车的显示
            this.newsed = true;
            this.unlogin = false; //未登录时显示登录入口
            this.signFlag = false; //未登录时显示注册的按钮
            this.is = false; //隐藏登录窗口nickName
            this.remstatus = false;
            this.picuser = res.data.userAvatar; //用户头像
            this.usernameshow = res.data.nickName; //用户名
            this.telename = res.data.userMobile.slice(0, 3);
            window.localStorage.setItem("pic", res.data.userAvatar);
            window.localStorage.setItem("nickName", res.data.nickName);
            window.localStorage.setItem("tele", this.telename);
            window.location.href = "";
          } else {
            //登录失败
            this.personFlag = false; //控制个人中心的显示
            this.carted = false; //购物车的显示
            this.newsed = false;
            this.unlogin = true; //未登录时显示登录入口
            this.signFlag = true; //未登录时显示注册的按钮
            this.is = true; //隐藏登录窗口
            this.remstatus = true;
            this.remessage = res.msg;
          }
        },
        error => {
          ////console.log(error);
        }
      );
  }
  //找回密码
  findPasss() {
    this.is = false;
    this.isFind = true;
    //点击找回密码
  }
  //渲染图片二维码
  imgSrcs() {
    this.srcs = this.NavService.domain + "/pub/verifi/imgCode";
    this.srcs += "?id=" + Math.random() * 10;
  }
  //获取电话
  saveTeles(value: string) {
    this.teles = value;
    console.log(this.teles);
    var reg = new RegExp(/^1[3|4|5|6|7|8|9][0-9]\d{8}$/);
    ////console.log(reg.test(this.tele));
    this.lengs = !reg.test(this.tele);
    if (reg.test(this.teles)) {
      this.remcon = "";
    } else {
      this.remcon = "手机号码有误,请修改手机号码";
    }
  }
  //获取图片验证码
  saveVerifys(value: string) {
    this.verifys = value;
    ////console.log(this.verify);
  }
  //获取动态的验证码
  safes() {
    this.times = 60;
    var reg = new RegExp(/^1[3|4|5|6|7|8|9][0-9]\d{8}$/);
    ////console.log(this.verify);
    if (!reg.test(this.teles)) {
      this.remcon = "请输入正确的手机号---";
    } else if (!this.verifys) {
      this.remcon = "请输入图片验证码";
    } else {
      this.unsign = false;
      this.justsign = true;
      // 这里加了一个图片验证码的参数,进行图片验证码的校验
      var info = {
        mobile: this.teles,
        imgValiCode: this.verifys
      };
      ////console.log(JSON.stringify(info));
      $.ajax({
        type: "POST",
        crossDomain: true,
        xhrFields: {
          withCredentials: true
        },
        contentType: "application/json;charset=UTF-8",
        data: JSON.stringify(info),
        url: this.NavService.domain + "/pub/shortMsg/send",
        dataType: "json",
        success: res => {
          ////console.log(res);
          if (res.code == 0) {
            this.verifycodes = false;
            var timer = setInterval(() => {
              this.times--;
              if (this.times <= 0) {
                this.verifycodes = true;
                clearInterval(timer);
                this.srcs = this.NavService.domain + "/pub/verifi/imgCode";
                this.srcs += "?id=" + Math.random() * 10;
              }
            }, 1000);
          } else {
            this.remcon = res.msg;
            this.srcs = this.NavService.domain + "/pub/verifi/imgCode";
            this.srcs += "?id=" + Math.random() * 10;
          }
        }
      });
    }
  }
  savePasswords(value: string) {
    this.passWords = value;
  }
  //获取第二次密码  在这里判断两次密码是否输入一致
  saveisPasswords(value: string) {
    this.isPasswords = value;
    if (this.passWords == this.isPasswords) {
      this.remcon = "";
    } else {
      this.remcon = "两次输入的密码不一致";
    }
    ////console.log(this.isPassword);
  }
  //获取手机验证码
  saveTelevs(value: string) {
    this.telewords = value;
    if (!value) {
      this.verifycodes = true;
    }
  }
  //找回密码
  foundpasss() {
    //点击确认找回密码
    //开始获取数据
    var reg = new RegExp(/^1[3|4|5|6|7|8|9][0-9]\d{8}$/);
    if (!reg.test(this.teles)) {
      //验证手机号
      this.remcon = "手机号码有误,请修改手机号码";
    } else if (!this.telewords) {
      this.remcon = "请输入手机验证码";
    } else if (!this.passWords) {
      //密码
      this.remcon = "请输入密码";
    } else if (!this.isPasswords) {
      this.remcon = "请再次输入密码";
    } else if (this.passWords !== this.isPasswords) {
      this.remcon = "两次输入的密码不一致";
    } else {
      this.remcon = "";
      //let encoder = this.passWords.toString('base64');
      var key = CryptoJS.enc.Utf8.parse("377902d57d26b67c");
      var srcs = CryptoJS.enc.Utf8.parse(this.passWords);
      ////console.log(srcs);
      var encrypted = CryptoJS.AES.encrypt(srcs, key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      });
      encrypted = encrypted.toString();
      //let encoderpass = this.isPasswords.toString('base64');
      var keys = CryptoJS.enc.Utf8.parse("377902d57d26b67c");
      var srcss = CryptoJS.enc.Utf8.parse(this.isPasswords);
      ////console.log(srcss);
      var encryptedpass = CryptoJS.AES.encrypt(srcss, keys, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      });
      encryptedpass = encryptedpass.toString();
      //alert(encryptedpass.toString());
      let header = new Headers({ "Content-Type": "application/json" }); //null,其实不表明 json 也可以, ng 默认好像是 json
      let options = new RequestOptions({
        headers: header,
        withCredentials: true
      });
      var userInfo = {
        mobile: this.teles,
        captcha: this.telewords,
        repassword: encryptedpass,
        password: encrypted
      };
      this.http
        .post(
          this.NavService.domain + "/user/getPwd",
          JSON.stringify(userInfo),
          options
        )
        .map(res => res.json())
        .subscribe(
          res => {
            if (res.code == 0) {
              this.show = true;
              this.remcon = res.msg;
              setTimeout(() => {
                this.show = false;
                this.remcon = "";
                this.is = true;
                this.isFind = false;
              }, 2000);
            } else {
              this.show = true;
              this.remcon = res.msg;
            }
          },
          error => {
            this.show = true;
            this.remcon = error.msg;
          }
        );
    }
  }
  loading() {
    this.is = true;
  }
  tishiclick() {
    this.show = true;
    this.remcon = "请认真填写注册信息";
    setTimeout(() => {
      this.show = false;
      this.remcon = "";
    }, 1000);
  }
}
