import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
@Component({
  selector: "app-remoabout",
  templateUrl: "./remoabout.component.html",
  styleUrls: ["./remoabout.component.css"],
})
export class RemoaboutComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {
    // 路由跳转到顶部
    console.log($(document).scrollTop(),"你好");
    $(document).scrollTop(0, 0);
    window.scrollTo(0, 0);
    this.router.events.subscribe(evt => {
      window.scrollTo(0, 0);
    });
  }
}
