import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ActivatedRoute, ParamMap, Params } from "@angular/router";
import { NavService } from "../../nav.service";
import { HttpClient } from "@angular/common/http";
import { HttpHeaders, HttpRequest } from "@angular/common/http";
import { Http, RequestOptions, Headers } from "@angular/http";
import "rxjs/add/operator/map"; //调用映射的响应可观察到得到解析的人对象。
declare var jQuery: any; //定义jquery
declare var $: any; //定义jquery
@Component({
  selector: "app-classdetail",
  templateUrl: "./classdetail.component.html",
  styleUrls: ["./classdetail.component.css"],
})
export class ClassdetailComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: Http,
    private NavService: NavService
  ) {}

  cpId; //传递过来的id
  className = ""; //课程名称
  classImg; //封面图片
  classDescription = ""; //课程大纲
  categoryName = ""; //课程分类名称
  classTag = "";
  listTime = []; //开课时间
  classIntro = ""; //课程介绍
  ngOnInit() {
    // console.log($(".time-box dl"));
    window.scrollTo(0, 0);
    $(".time-box dl").scrollBar();
    // 路由跳转到顶部
    // this.router.events.subscribe((evt) => {
    //   window.scrollTo(0, 0);
    // });
    $("html").scrollTop(0);
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.cpId = params.get("id");
      // console.log(this.cpId);
      // 课程详情数据
      this.http
        .post(this.NavService.domain_hh + "/rc/rcourse/getCourseDetail", {
          courseId: this.cpId,
        })
        .map((res) => res.json())
        .subscribe((res) => {
          this.className = res.data.courseName;
          this.classImg = res.data.courseImg;
          this.classDescription = res.data.description;
          this.classTag = res.data.courseSuit;
          this.listTime = res.data.listPlan;
          this.categoryName = res.data.categoryName;
          this.classIntro = res.data.introduct;
          // console.log(this.courseTag);
          // console.log(this.arr);
          // console.log(res);
        });
    });
  }
}
