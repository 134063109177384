import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ActivatedRoute, ParamMap, Params } from "@angular/router";
import { NavService } from "../../nav.service";
import { HttpClient } from "@angular/common/http";
import { HttpHeaders, HttpRequest } from "@angular/common/http";
import { Http, RequestOptions, Headers } from "@angular/http";
import "rxjs/add/operator/map"; //调用映射的响应可观察到得到解析的人对象。
declare var jQuery: any; //定义jquery
declare var $: any; //定义jquery
@Component({
  selector: "app-remotedetail",
  templateUrl: "./remotedetail.component.html",
  styleUrls: ["./remotedetail.component.css"],
})
export class RemotedetailComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: Http,
    private NavService: NavService
  ) {}
  remotedeClass = false; //未创建远程教室弹窗
  Jurisdiction = false; //是否有权限
  noUser = false; //是否是远程教室用户
  limit = false; //数量上限
  alertrem = false; //生成订单失败
  wrong = false; //服务器报错提示
  total; //总的课程
  nubm; //总的课程暂存使用
  img; //封面
  descriCenter = ""; //课程包描述
  money; //钱数
  Surplus; //剩余可选课程数量
  input; //必选课程数量
  inputOne; //必选课程数量暂存使用
  isTrue = false; //立即购买颜色
  isFalse = true; //按钮是否可以点击
  isReplace = -1; //tab切换改变颜色
  list = []; //全部课程目录
  cpId; //传递过来的id
  nub = []; //选中的课程id
  nubData = []; //临时保存id
  courseList = []; //分类数据
  remoteName = "";
  necessary; //必选或者全部传值
  ccid; //必选或者全部传值
  nubId = []; //必选课程id
  //登录的相关数据
  //初始化数据
  leng; //手机号的状态
  name = "";
  tele = "";
  teleword; //手机验证码
  verify = "";
  passWord = "";
  isPassword = "";
  tit = "";
  show;
  src;
  qq;
  verifycode; //获取验证码按钮存在的状态
  times = 60; //时间
  remcon = ""; //提示信息
  justsign; //立即注册按钮显示的状态
  unsign; //不能注册
  remessage = ""; //提示信息
  remstatus = false;
  picuser; //用户头像
  usernameshow; //用户名
  telename;
  personFlag; //控制个人中心的显示
  carted; //购物车的显示
  newsed; //控制消息
  unlogin; //未登录时显示登录入口
  signFlag; //未登录时显示注册的按钮
  is;
  isFind;
  isremember = "0";
  userName;
  pass;

  ngOnInit() {
    // 路由跳转到顶部
    // this.router.events.subscribe((evt) => {
    //   window.scrollTo(0, 0);
    // });
    this.verifycode = true;
    this.src = this.NavService.domain + "/pub/verifi/imgCode";
    $("html").scrollTop(0);
    window.scrollTo(0, 0);
    //滚动条滚动
    $(document).ready(function () {
      // 吸顶效果
      $(window).scroll(function () {
        // if ($(window).scrollTop() > 30) {
        //   $(".remote-detail").parent().parent().prev().prev(".remote-nav").css({
        //     position: "fixed",
        //     top: 0,
        //     background: "#fff",
        //     "z-index": 100000,
        //   });
        // } else {
        //   $(".remote-nav").css({ position: "inherit", "z-index": 0 });
        // }
        if ($(window).scrollTop() > 430) {
          $(".remote-middle").css({
            position: "fixed",
            top: 0,
            "z-index": 100,
            "box-shadow": "0 8px 8px 0 #F7F7F7",
          });
        } else {
          $(".remote-middle").css({
            position: "inherit",
            "z-index": 0,
            "box-shadow": "0 0 0 0 #Fff",
          });
        }
      });
    });

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.cpId = params.get("id");
      // 课程包详情数据
      this.http
        .post(
          this.NavService.domain_hh +
            "/rc/coursebackage/queryCoursePackageInfo/" +
            this.cpId,
          {}
        )
        .map((res) => res.json())
        .subscribe(
          (res) => {
            // console.log(res, "基础包");
            if (res.code == 200) {
              this.img = res.data.coursePackageInfo.cover;
              this.descriCenter = res.data.coursePackageInfo.description;
              this.total = res.data.coursePackageInfo.courseNum;
              this.nubm = res.data.coursePackageInfo.courseNum;
              this.money = res.data.coursePackageInfo.price;
              this.remoteName = res.data.coursePackageInfo.name;
              this.input = res.data.coursePackageInfo.necessaryCourseNum;
              this.courseList = res.data.courseCategoryList;
              this.Surplus = this.total - this.input;
              if (this.Surplus < 0) {
                this.Surplus = 0;
              } else if (this.total == 0) {
                this.Surplus = 0;
                this.input = 0;
              }
              if (this.total == this.input) {
                this.isTrue = true; //立即购买颜色
                this.isFalse = false; //按钮是否可以点击
              }
            } else {
              this.wrong = true;
            }
          },
          (err) => {
            // console.log(err);
            this.wrong = true;
          }
        );
      // 课程列表数据
      this.http
        .post(
          this.NavService.domain_hh +
            "/rc/coursebackage/selectCourseList4CoursePackage",
          {
            cpId: this.cpId,
            ccId: "",
            stat: "all",
          }
        )
        .map((res) => res.json())
        .subscribe(
          (res) => {
            // console.log(res, "基础包");
            if (res.code == 200) {
              this.list = res.data;
              var that = this;
              this.list.forEach(function (val, index, arr) {
                // 判断是否是必选
                if (val.isNecessary == 1) {
                  // 如果是必选的就把id添加到数组中
                  that.nub.push(val.id);
                  // 添加属性和值，判断是否选中状态
                  val.choseStatus = 1;
                } else {
                  // 添加属性和值，判断是否选中状态
                  val.choseStatus = 0;
                }
                //  判断是否有课程安排
                if (val.coursePlanTimeList.length == 0) {
                  //没有排课，展示文字提示
                  val.isHave = 1;
                } else {
                  // 有排课展示排课时间
                  val.isHave = 0;
                }
              });
            } else {
              this.wrong = true;
            }
          },
          (err) => {
            this.wrong = true;
          }
        );
    });
    //回车登录
    $(".coursePassword,.courseAccount").keyup((event) => {
      if (event.keyCode == 13) {
        this.userName = $(".courseAccount input").val();
        this.pass = $(".coursePassword input").val();
        this.loaded();
      }
    });
  }
  // 点击小×关闭弹窗
  close() {
    this.Jurisdiction = false; //是否有权限
    this.noUser = false; //是否远程教室用户
    this.limit = false; //已经达到上限
    this.alertrem = false; //创建订单失败
    this.wrong = false; //服务器报错提示
  }
  // 选择课程的数量 以及获取课程id数组
  courseNumber(e, inputid) {
    if (
      window.localStorage.getItem("token") == null ||
      window.localStorage.getItem("token") == undefined ||
      window.localStorage.getItem("token") == ""
    ) {
      this.is = true;
    } else {
      // console.log(inputid);
      if (this.Surplus == 0) {
        // console.log(this.input);
        // console.log(this.total);
        if (!$(e.target).prop("checked")) {
          this.input -= 1;
          this.Surplus += 1;
          var that = this;
          this.nub.forEach(function (val, index, arr) {
            if (val != inputid) {
              that.nubData.push(val);
            }
          });
          this.nub = [];
          this.nub = this.nubData;
          this.nubData = [];
        } else {
          this.limit = true;
          this.input = this.nubm;
          this.Surplus = 0;
          $(e.target).prop("checked", false);
        }
      } else {
        if ($(e.target).prop("checked")) {
          this.input += 1;
          this.Surplus -= 1;
          this.nub.push(inputid);
          if (this.Surplus < 0) {
            this.Surplus = 0;
          }
        } else {
          this.input -= 1;
          this.Surplus += 1;
          var that = this;
          this.nub.forEach(function (val, index, arr) {
            if (val != inputid) {
              // console.log(val, "不同的");
              // console.log(inputid, "相同的");
              that.nubData.push(val);
              // console.log(that.nubData);
            }
          });
          this.nub = [];
          // console.log(this.nub, "清空");
          this.nub = this.nubData;
          this.nubData = [];
          // console.log(this.nub, "全部选中");
        }
      }
      // 添加类名改变按钮颜色,按钮不可点击
      if (this.input > this.nubm - 1) {
        this.isTrue = true;
        this.isFalse = false;
        // console.log("isFalse", this.isFalse);
      } else {
        this.isTrue = false;
        this.isFalse = true;
      }
    }
  }
  // 支付
  shopping() {
    // console.log("支付");
    // console.log(this.cpId);
    // console.log(this.nub);
    // console.log(localStorage.getItem("token"));
    // console.log(localStorage.getItem("jsessionid"));
    this.http
      .post(this.NavService.domain_hh + "/rc/order/generateod", {
        coursePkgId: this.cpId,
        ids: this.nub,
        token: localStorage.getItem("token"),
        jsessionid: localStorage.getItem("jsessionid"),
      })
      .map((res) => res.json())
      .subscribe((res) => {
        // console.log(res);
        // 是否登录
        if (res.code == 401) {
          this.is = true;
          this.isFind = false;
        } else {
          // 是否有购买权限
          if (res.code == 423) {
            this.Jurisdiction = true;
          } else if (res.code == 424) {
            //是否有购买权限
            this.Jurisdiction = true;
          } else if (res.code == 421) {
            //  数量达到上限
            this.limit = true;
          } else if (res.code == 200) {
            window.localStorage.setItem("order", res.data.orderNo);
            window.location.href =
              this.NavService.skiplink_sims + res.data.orderNo;
            // window.open(this.NavService.skiplink_sims + res.data.orderNo);
            // this.NavService.skiplink_sim + "?" + res.data.orderNo + "#10";
          } else {
            // console.log(res.code);
            this.alertrem = true;
          }
        }
      });
  }
  //tab栏切换
  /**
   *
   * @param replaceid 选项的id值
   * @param index  选项在数组中的index值
   */
  change(replaceid, i) {
    if (replaceid == -1) {
      // 全部
      this.necessary = "all";
      this.ccid = "";
      this.isReplace = replaceid;
    } else {
      // 分类
      this.necessary = "";
      this.ccid = replaceid;
      this.isReplace = i;
    }
    // console.log($(window).scrollTop());
    if ($(window).scrollTop() > 466) {
      $(window).scrollTop(404);
    }
    //  点击选项显示课程
    this.http
      .post(
        this.NavService.domain_hh +
          "/rc/coursebackage/selectCourseList4CoursePackage",
        {
          cpId: this.cpId,
          ccId: this.ccid,
          stat: this.necessary,
        }
      )
      .map((res) => res.json())
      .subscribe(
        (res) => {
          this.list = [];
          if (res.code == 200) {
            if (res.data.length > 0) {
              this.list = res.data;
              for (var i = 0; i < this.list.length; i++) {
                // 判断上课时间的长度是否为0，如果为零则显示文字提示
                if (this.list[i].coursePlanTimeList.length == 0) {
                  //没有排课，展示文字提示
                  this.list[i].isHave = 1;
                } else {
                  // 有排课展示排课时间
                  this.list[i].isHave = 0;
                }
                // 循环所有已选的课程包括必选课程
                for (var j = 0; j < this.nub.length; j++) {
                  // 判断所有课程的id是否是已选和必选课程的id
                  if (this.list[i].id == this.nub[j]) {
                    this.list[i].choseStatus = 1; // 说明此数据 已选
                    continue;
                  }
                }
              }
            } else {
              this.list = [];
            }
          } else {
            this.list = [];
          }
        },
        (err) => {
          this.wrong = true;
          // console.log(err);
        }
      );
    // console.log(this.nub, "选中的id");
  }
  /* 关于登录部分代码 */
  //点击隐藏登录框
  hide() {
    this.is = false;
    this.isFind = false;
    window.location.reload();
  }
  //获取用户名
  userClick(value) {
    ////console.log(value);
    this.userName = value;
  }
  //获取密码
  password(value) {
    ////console.log(value);
    this.pass = value;
  }
  //记住我
  checkme(flag) {
    //点击记住我(这里面包括密码和用户名)
    ////console.log(flag);
    this.isremember = "0";
    ////console.log(flag.target.checked);
    if (flag.target.checked == true) {
      this.isremember = "1";
    }
  }
  //开始登录
  loaded() {
    let encoder = this.pass.toString("base64");
    var key = CryptoJS.enc.Utf8.parse("377902d57d26b67c");
    var srcs = CryptoJS.enc.Utf8.parse(encoder);
    //console.log(srcs);
    var encrypted = CryptoJS.AES.encrypt(srcs, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    encrypted = encrypted.toString();
    //alert(encrypted.toString());
    this.http
      .post(this.NavService.domain + "/user/login", {
        username: this.userName,
        password: encrypted,
        isremember: this.isremember,
      })
      .map((res) => res.json())
      .subscribe(
        (res) => {
          //console.log(res);
          if (res.code == 0) {
            //说明登录成功
            //如果点击了记住wo
            window.localStorage.setItem("token", res.data.token);
            window.localStorage.setItem("jsessionid", res.data.jsessionid);
            this.personFlag = true; //控制个人中心的显示
            this.carted = true; //购物车的显示
            this.newsed = true;
            this.unlogin = false; //未登录时显示登录入口
            this.signFlag = false; //未登录时显示注册的按钮
            this.is = false; //隐藏登录窗口nickName
            this.remstatus = false;
            this.picuser = res.data.userAvatar; //用户头像
            this.usernameshow = res.data.nickName; //用户名
            this.telename = res.data.userMobile.slice(0, 3);
            window.localStorage.setItem("pic", res.data.userAvatar);
            window.localStorage.setItem("nickName", res.data.nickName);
            window.localStorage.setItem("tele", this.telename);
            window.location.reload();
          } else {
            //登录失败
            this.personFlag = false; //控制个人中心的显示
            this.carted = false; //购物车的显示
            this.newsed = false;
            this.unlogin = true; //未登录时显示登录入口
            this.signFlag = true; //未登录时显示注册的按钮
            this.is = true; //隐藏登录窗口
            this.remstatus = true;
            this.remessage = res.msg;
          }
        },
        (error) => {
          ////console.log(error);
        }
      );
  }
  //找回密码显示
  findPass() {
    this.is = false;
    this.isFind = true;
    //点击找回密码
  }
  //渲染图片二维码
  imgSrc() {
    this.src = this.NavService.domain + "/pub/verifi/imgCode";
    this.src += "?id=" + Math.random() * 10;
  }
  //获取电话
  saveTele(value: string) {
    this.tele = value;
    ////console.log(this.tele);
    var reg = new RegExp(/^1[3|4|5|6|7|8|9][0-9]\d{8}$/);
    ////console.log(reg.test(this.tele));
    if (reg.test(this.tele)) {
      this.remcon = "";
    } else {
      this.remcon = "手机号码有误,请修改手机号码";
    }
  }
  //获取图片验证码
  saveVerify(value: string) {
    this.verify = value;
    ////console.log(this.verify);
  }
  //获取动态的验证码
  safe() {
    this.times = 60;
    var reg = new RegExp(/^1[3|4|5|6|7|8|9][0-9]\d{8}$/);
    ////console.log(this.verify);
    if (!reg.test(this.tele)) {
      this.remcon = "请输入正确的手机号---";
    } else if (!this.verify) {
      this.remcon = "请输入图片验证码";
    } else {
      this.remcon = "";
      this.unsign = false;
      this.justsign = true;
      // 这里加了一个图片验证码的参数,进行图片验证码的校验
      var info = {
        mobile: this.tele,
        imgValiCode: this.verify,
      };
      $.ajax({
        type: "POST",
        crossDomain: true,
        xhrFields: {
          withCredentials: true,
        },
        data: JSON.stringify(info),
        contentType: "application/json;charset=UTF-8",
        url: this.NavService.domain + "/pub/shortMsg/send",
        dataType: "json",
        success: (res) => {
          //console.log(res);
          if (res.code == 0) {
            // console.log(this.times);
            this.verifycode = false;
            var timer = setInterval(() => {
              this.times--;
              if (this.times <= 0) {
                this.verifycode = true;
                clearInterval(timer);
                this.src = this.NavService.domain + "/pub/verifi/imgCode";
                this.src += "?id=" + Math.random() * 10;
              }
            }, 1000);
          } else {
            this.remcon = res.msg;
            this.src = this.NavService.domain + "/pub/verifi/imgCode";
            this.src += "?id=" + Math.random() * 10;
          }
        },
      });
    }
  }
  savePassword(value: string) {
    this.passWord = value;
  }
  //获取第二次密码  在这里判断两次密码是否输入一致
  saveisPassword(value: string) {
    this.isPassword = value;
    if (this.passWord == this.isPassword) {
      this.remcon = "";
    } else {
      this.remcon = "两次输入的密码不一致";
    }
    ////console.log(this.isPassword);
  }
  //获取手机验证码
  saveTelev(value: string) {
    this.teleword = value;
    if (!value) {
      this.verifycode = true;
    }
  }
  //找回密码
  foundpass() {
    //console.log(1);
    //点击确认找回密码
    //开始获取数据
    var reg = new RegExp(/^1[3|4|5|6|7|8|9][0-9]\d{8}$/);
    if (!reg.test(this.tele)) {
      //验证手机号
      this.remcon = "手机号码有误,请修改手机号码";
    } else if (!this.verify) {
      this.remcon = "请输入图片验证码";
    } else if (!this.passWord) {
      //密码
      this.remcon = "请输入密码";
    } else if (!this.isPassword) {
      this.remcon = "请再次输入密码";
    } else if (this.passWord !== this.isPassword) {
      this.remcon = "两次输入的密码不一致";
    } else if (!this.teleword) {
      this.remcon = "请输入手机验证码";
    } else {
      this.remcon = "";
      //let encoder = this.passWord.toString('base64');
      var key = CryptoJS.enc.Utf8.parse("377902d57d26b67c");
      var srcs = CryptoJS.enc.Utf8.parse(this.passWord);
      ////console.log(srcs);
      var encrypted = CryptoJS.AES.encrypt(srcs, key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
      });
      encrypted = encrypted.toString();
      //let encoderpass = this.isPassword.toString('base64');
      var keys = CryptoJS.enc.Utf8.parse("377902d57d26b67c");
      var srcss = CryptoJS.enc.Utf8.parse(this.isPassword);
      ////console.log(srcs);
      var encryptedpass = CryptoJS.AES.encrypt(srcss, keys, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
      });
      encryptedpass = encryptedpass.toString();
      //alert(encryptedpass.toString());
      let header = new Headers({ "Content-Type": "application/json" }); //null,其实不表明 json 也可以, ng 默认好像是 json
      let options = new RequestOptions({
        headers: header,
        withCredentials: true,
      });
      var userInfo = {
        mobile: this.tele,
        captcha: this.teleword,
        repassword: encryptedpass,
        password: encrypted,
      };
      this.http
        .post(
          this.NavService.domain + "/user/getPwd",
          JSON.stringify(userInfo),
          options
        )
        .map((res) => res.json())
        .subscribe(
          (res) => {
            // console.log(res);
            if (res.code == 0) {
              this.remcon = res.msg + "正在为您切换登录窗......";
              this.is = true;
              this.isFind = false;
            } else {
              this.remcon = res.msg;
            }
          },
          (error) => {
            this.remcon = error.msg;
          }
        );
    }
  }
  /* 登录部分结束 */
}
