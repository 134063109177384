import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RemoindexComponent } from './remoindex.componet';
import { RemohaveComponent } from '../remo-have/remohave.component';
import { RemosheduleComponent } from '../remo-schedule/remoshedule.component';
import { RemouserComponent } from '../remo-user/remouser.component';
import {ReindexComponent } from  '../remo-index/remoindex.component'
const remoindexRoutes:Routes = [
  {
    path:'remocenter',
    component:RemoindexComponent,
    children:[
      // {
      //   path:'',
      //   component:ReindexComponent
      // },
      {
        path:'remohave',
        component:RemohaveComponent
      },
      {
        path:'remoshe',
        component:RemosheduleComponent
      },
      {
        path:'remouser',
        component:RemouserComponent
      }
    ]
  }
];
@NgModule({
  imports: [
    RouterModule.forChild(remoindexRoutes) 
  ],
  exports: [
    RouterModule
  ],
  declarations:[]
})
export class RemoindexRoutingModule { }