import { BrowserModule } from '@angular/platform-browser';

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
// import { RemoindexComponent } from './remoindex.componet';
import { RemohaveComponent } from '../remo-have/remohave.component';
import { RemosheduleComponent } from '../remo-schedule/remoshedule.component';
import { RemouserComponent } from '../remo-user/remouser.component';
import {ReindexComponent } from  '../remo-index/remoindex.component';
import {RemoindexRoutingModule } from './remoindex-routing.module'
// import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ScrollPipe } from "../remo-schedule/scroll.pipe";
import { HavePipe } from "../remo-have/have.pipe";
@NgModule({
    declarations: [
      // RemoindexComponent,
      RemohaveComponent,
      RemosheduleComponent,
      RemouserComponent,
      ReindexComponent,
      ScrollPipe,
      HavePipe
    ],
    imports: [
      BrowserModule,
      RouterModule,
      FormsModule,
      RemoindexRoutingModule
    ],
    providers: [],
    bootstrap: []
  })
  export class RemoindexModule { }