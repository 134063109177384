import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
@Component({
  selector: "app-remoteapply",
  templateUrl: "./remoteapply.component.html",
  styleUrls: ["./remoteapply.component.css"]
})
export class RemoteapplyComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {
    // 路由跳转到顶部
    this.router.events.subscribe(evt => {
      window.scrollTo(0, 0);
    });
  }
}
