import { Pipe, PipeTransform } from "@angular/core";
declare var jQuery: any; //定义jquery
declare var $: any; //定义jquery
@Pipe({ name: "scrollDiv" })
export class ScrollPipe implements PipeTransform {
    transform(value: string) {
        $(".scroll-diving").scrollBar();
        $(".scroll-divs").scrollBar(); 
        $(".chose-ul").scrollBar(); 
  }
  constructor() {}
}
