import { Pipe, PipeTransform } from "@angular/core";
declare var jQuery: any; //定义jquery
declare var $: any; //定义jquery
@Pipe({ name: "remwidth" })
export class remPipe implements PipeTransform {
  transform(value: string) {
    $(".remote-scroll").scrollBar();
  }
  constructor() {}
}
