import { Pipe, PipeTransform } from "@angular/core";
declare var jQuery: any; //定义jquery
declare var $: any; //定义jquery
@Pipe({ name: "listwidth" })
export class listPipe implements PipeTransform {
  transform(value: string) {
    // console.log($(".remote-scroll"));
    $(".remote-scroll").scrollBar();
  }
  constructor() {}
}
