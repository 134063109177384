import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavService } from '../../nav.service';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Http } from '@angular/http';
import 'rxjs/add/operator/map';//调用映射的响应可观察到得到解析的人对象。
// import {Calendar} from '../../../assets/lib/calendar';
declare var jQuery: any;  //定义jquery
declare var $: any;  //定义jquery
// declare var calendar: any;  //定义jquery
@Component({
	selector: 'app-remotedschedule',
	templateUrl: './remotedschedule.component.html',
	styleUrls: ['./remotedschedule.component.css']
})
export class RemotedscheduleComponent implements OnInit {

	constructor(
		private router: Router,
		private http: Http,
		private NavService: NavService
	) { }

	ngOnInit() {
		//console.log(date) date.getFullYear()
		var date = new Date();
			var seperator1 = "-";
			var year = date.getFullYear();
			var month = date.getMonth() + 1;
			var strDate = date.getDate();
			var currentdate = year + seperator1 + month + seperator1 + strDate;
			var dates =currentdate;
			console.log(dates)
		var data= [];
		this.http.get(this.NavService.domain_hh +"/rc/coursePlanCenter/queryCoursePlanTimeAll")
		.map(res => res.json())
		.subscribe(
			res=>{
				//console.log(res)
				data=res.data;
				$("#calendar").calendar({
					data: data,
					mode: "month",
					//maxEvent: 1,
					//showModeBtn: false,
					newDate:dates+'-1-1',
				//    cellClick: function (res) {
				// 	   alert(JSON.stringify(res))
				// 		//viewCell的事件列表
				// 	},
				})
			},
			err=>{
				//console.log(err)
			}
		)
		// //console.log($("#calendar").Calendar());
		
			
		
		

	}

}
