import { Component,OnInit } from '@angular/core';

@Component({
  selector: 'app-swipers',
  templateUrl: './zje.component.html',
  styleUrls: ['./zje.component.css']
})
export class SwipersComponent {

  ngOnInit(): void {  
     
    var swiper = new Swiper('.swiper-container',{  
      pagination : '.swiper-pagination',
      direction : 'horizontal', 
       loop: true,
        autoplay:2000,
        paginationClickable :true,
        autoplayDisableOnInteraction : false,
        observer:true,//修改swiper自己或子元素时，自动初始化swiper  
        observeParents:true,//修改swiper的父元素时，自动初始化swiper  
        prevButton:".swiper-button-prev",
        nextButton:".swiper-button-next"
      });  
  }   
}